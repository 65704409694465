/**
 * RadioSelect solicits a single selection via a group of radio buttons.
 */
import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'

const useStyles = makeStyles(
  theme => ({
    root: {
      margin: theme.spacing(2.125, 0, 3.125),
      rowGap: `${theme.spacing(2.25)}px` // Replaces the vertical space removed from radio, see below.
    },

    label: {
      alignItems: 'flex-start'
    },

    radio: {
      margin: theme.spacing(-1.125, 0) // Remove vertical space to foster top alignment.
    }
  }),
  {
    name: 'RadioSelect'
  }
)

const RadioSelect = props => {
  const { className, all, column, name, value, setValue } = props

  const handleChange = event => setValue(event.target.value)

  const handleClick = event => {
    // Allow toggling to clear the current answer.
    if (value === event.target.value) {
      setValue('')
    }
  }

  const classes = useStyles(props)
  return (
    <RadioGroup
      className={clsx(classes.root, className)}
      row={!column}
      aria-label="select"
      name={name}
      value={value}
      onChange={setValue ? handleChange : undefined}
      onClick={setValue ? handleClick : undefined}
    >
      {Object.keys(all).map(key => (
        <FormControlLabel
          key={key}
          className={classes.label}
          control={<Radio className={classes.radio} value={key} />}
          label={all[key]}
        />
      ))}
    </RadioGroup>
  )
}

RadioSelect.propTypes = {
  className: PropTypes.string,
  all: PropTypes.object.isRequired, // The overall choices as key-label pairs.
  column: PropTypes.bool, // Whether to display the radio buttons as a column.
  name: PropTypes.string, // The form-level name attribute for this selection.
  value: PropTypes.string, // The current selection.
  setValue: PropTypes.func // The function for updating the current selection.
}

export default RadioSelect

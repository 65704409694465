/**
 * This module defines the hooks that are available to any component within the web app.
 */
import { useState, useEffect, useRef, useCallback } from 'react'
import preval from 'preval.macro'

import { getMe, ROLE_SUPERUSER } from '../api'

export const useVersion = () => ({
  app: process.env.REACT_APP_VERSION,
  build: preval`module.exports = Date.now().toString(16)`
})

export const useAppUser = () => {
  const [appUser, setAppUser] = useState()

  useEffect(() => {
    let active = true

    const retrieveAppUser = async () => {
      try {
        const appUser = await getMe()
        if (!active) {
          return
        }

        setAppUser(appUser)
      } catch (error) {
        setAppUser(null)
      }
    }

    retrieveAppUser()
    return () => {
      active = false
    }
  }, [])

  return appUser
}

export const useSuperuserCheck = () => {
  const [isSuper, setIsSuper] = useState()

  const retrieveMe = async () => {
    try {
      const me = await getMe()
      setIsSuper(me.roles && me.roles.includes(ROLE_SUPERUSER))
    } catch (error) {
      setIsSuper(false)
    }
  }

  if (isSuper === undefined) {
    retrieveMe()
  }

  return isSuper
}

// The paging state is for UI updates. We use a local variable to avoid page-loading duplication.
// It’s outside hook scope to avoid dependency issues…now, will it be a problem if there are multiple
// paging hooks at play? We’ll have to see…
let pageLock = false

/**
 * The useLoadMore hook captures the logic needed for following a cursor and loading items from a paginated endpoint
 * on demand. All API specifics are captured in the pageLoader function that is given to the hook.
 *
 * @param {function} pageLoader accepts an optional cursor argment then returns an object with:
 *                   - itemPage, an array holding the next page of items
 *                   - cursorNext, the value of the cursor for the next page
 *
 *                   If pageLoader throws an error upon execution, it will be passed to `setError` if given
 *
 * @param {function} setError (optional) is a useState setter in the event of an error
 */
export const useLoadMore = (pageLoader, setError) => {
  const [items, setItems] = useState(null) // Null until first load.
  const [cursor, setCursor] = useState(null)
  const [complete, setComplete] = useState(false)
  const [paging, setPaging] = useState(false)

  const retrievePage = useCallback(
    async cursor => {
      try {
        return await pageLoader(cursor)
      } catch (error) {
        if (setError) {
          setError(error)
        }

        return null
      }
    },
    [pageLoader, setError]
  )

  const nextPage = useCallback(() => {
    // Once the collection is complete, then don’t even try to make a request.
    if (complete) {
      return
    }

    pageLock = true
    setPaging(true)

    const loadNextPage = async () => {
      const page = await retrievePage(cursor)
      if (!page) {
        return
      }

      const { itemsPage, cursorNext } = page

      if (!complete) {
        setItems(items => [...(items || []), ...itemsPage])
        setCursor(cursorNext)
      }

      if (!cursorNext) {
        setComplete(true)
      }

      setPaging(false)
      pageLock = false
    }

    loadNextPage()
  }, [retrievePage, cursor, complete])

  // Initialize things.
  useEffect(() => {
    let active = true

    const initializeItems = async () => {
      const page = await retrievePage()
      if (!active || !page) {
        return
      }

      const { itemsPage, cursorNext } = page
      setItems(itemsPage)
      setCursor(cursorNext)
      setComplete(!cursorNext)
    }

    initializeItems()

    return () => {
      active = false
    }
  }, [retrievePage])

  const reset = () => {
    setItems([])
    setCursor(null)
    setComplete(false)
  }

  return { items, complete, paging, reset, nextPage }
}

const SCROLL_EVENT_KEY = 'scroll'

/**
 * The useInfiniteScroll hook “automates” the useLoadMore hook by invoking next-page behavior when the user scrolls
 * past a particular element, which should be assigned to the `lastItem` ref that is returned by the hook.
 *
 * @param {function} pageLoader is the same function that useLoadMore expects
 * @param {function} setError (optional) is the same function that useLoadMore expects
 */
export const useInfiniteScroll = (pageLoader, setError) => {
  const lastItem = useRef(null)

  const loadMore = useLoadMore(pageLoader, setError)
  const { nextPage } = loadMore

  useEffect(() => {
    let ticking = false

    const infiniteScrollListener = event => {
      if (!ticking) {
        ticking = true

        window.requestAnimationFrame(() => {
          const lastItemElement = lastItem.current
          const { pageTop, height } = window.visualViewport
          if (!pageLock && lastItemElement && pageTop + height > lastItemElement.offsetTop) {
            nextPage()
          }

          ticking = false
        })
      }
    }

    window.addEventListener(SCROLL_EVENT_KEY, infiniteScrollListener)

    return () => {
      window.removeEventListener(SCROLL_EVENT_KEY, infiniteScrollListener)
    }
  }, [nextPage])

  return { ...loadMore, lastItem }
}

import React, { useState, useEffect, useCallback } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Zoom from '@material-ui/core/Zoom'

import { useLoadMore } from './app/hooks'
import { unexpectedErrorContainer } from './theme'
import { scriptIsDeleted, STATUS_DELETED } from './utility'

import ErrorResponse from './ErrorResponse'
import ScriptAuthorItem from './ScriptAuthorItem'
import ScriptItem from './ScriptItem'

const useStyles = makeStyles(
  theme => ({
    root: {
      overflowX: 'scroll'
    },

    empty: {
      marginLeft: theme.spacing(3.5),
      marginRight: theme.spacing(3.5)
    },

    error: unexpectedErrorContainer(theme),

    thead: {
      '& th': {
        fontWeight: 'bold',
        fontSize: theme.spacing(2),
        padding: theme.spacing(0.5, 2, 1)
      }
    },

    authorHead: {
      width: theme.spacing(23)
    },

    scriptCell: {
      verticalAlign: 'top'
    },

    scriptFooterCell: {
      padding: theme.spacing(1),
      textAlign: 'end'
    },

    progress: {
      margin: theme.spacing(0, 2, 2)
    },

    progressLoadMore: {
      marginLeft: theme.spacing(1.25)
    },

    deleted: {
      backgroundColor: theme.palette.error.light
    },

    replaced: {
      opacity: 0.25,
      textDecoration: 'line-through'
    }
  }),
  {
    name: 'ScriptList'
  }
)

const DEFAULT_LIMIT = 20
const PAGE_KEY = 'cursor'
const LIMIT_KEY = 'limit'

const processReplacementScripts = scripts => {
  // Mark replaced scripts.
  const replacedScriptIds = {}
  const harvestReplacedScriptIds = script => {
    if (script.replacement_of) {
      replacedScriptIds[script.replacement_of] = true // Idempotent for repeats.
    }
  }

  const markReplacedScripts = script => {
    if (replacedScriptIds[script.id]) {
      script.replaced = true
    } else {
      delete script.replaced
    }
  }

  scripts.forEach(harvestReplacedScriptIds)
  scripts.forEach(markReplacedScripts)
  return scripts
}

const loadScriptPage = async (getter, scriptsCursor) => {
  const scriptsParams = new URLSearchParams()
  scriptsParams.set(LIMIT_KEY, DEFAULT_LIMIT)

  if (scriptsCursor) {
    scriptsParams.set(PAGE_KEY, scriptsCursor)
  }

  const scripts = await getter(scriptsParams)
  return {
    itemsPage: processReplacementScripts(scripts.scripts),
    cursorNext: scripts.cursor_next
  }
}

const ScriptList = props => {
  const { getter, reload } = props

  const [scripts, setScripts] = useState(null)
  const [error, setError] = useState(null)

  // Update trickery: a change in reload changes the callback, which resets the pager hook.
  // This is how parent components can tell a child ScriptList to reload itself.
  const scriptLoader = useCallback(async cursor => await loadScriptPage(getter, cursor, reload), [getter, reload])
  const { items, complete, paging, reset, nextPage } = useLoadMore(scriptLoader, setError)

  const handleScriptChange = changedScript => {
    setScripts(
      scripts.map(script =>
        script.id === changedScript.id
          ? {
              ...script,
              ...changedScript
            }
          : script
      )
    )
  }

  const handleScriptDelete = deletedScript => {
    setScripts(
      scripts.map(script =>
        script.id === deletedScript.id
          ? {
              ...script,
              status: STATUS_DELETED
            }
          : script
      )
    )
  }

  useEffect(() => {
    setScripts(items)
  }, [items])

  const visibleScripts = (scripts || []).filter(script => !script.replaced)

  const classes = useStyles(props)
  if (error) {
    return (
      <div className={classes.error}>
        <div>Yikes! Something went wrong with this bucket of scripts.</div>
        <ErrorResponse error={error} />
      </div>
    )
  }

  return scripts ? (
    <div className={classes.root}>
      {scripts.length === 0 ? (
        <p className={classes.empty}>No scripts in this list.</p>
      ) : (
        <Table>
          <TableHead className={classes.thead}>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell className={classes.authorHead}>Author</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleScripts.map(script => (
              <TableRow
                key={script.id}
                className={clsx(
                  classes[script.replaced ? 'replaced' : 'current'],
                  scriptIsDeleted(script) && classes.deleted
                )}
              >
                <TableCell className={classes.scriptCell}>
                  <ScriptItem
                    script={script}
                    onScriptChange={handleScriptChange}
                    onScriptReplace={reset}
                    onScriptDelete={handleScriptDelete}
                  />
                </TableCell>
                <TableCell className={classes.scriptCell}>
                  <ScriptAuthorItem script={script} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          {!complete && (
            <TableFooter>
              <TableRow>
                <TableCell className={classes.scriptFooterCell} colSpan={2}>
                  <Button onClick={nextPage} disabled={paging}>
                    <span>load more</span>

                    <Zoom in={paging} mountOnEnter unmountOnExit>
                      <CircularProgress className={classes.progressLoadMore} size={16} />
                    </Zoom>
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      )}
    </div>
  ) : (
    <div className={classes.progress}>
      <CircularProgress />
    </div>
  )
}

ScriptList.propTypes = {
  reload: PropTypes.bool,
  getter: PropTypes.func.isRequired // Signature: () => page of scripts promise
}

export default ScriptList

/**
 * ConfirmAbandonDialog is a component that verifies whether a reader really wants to abandon a script.
 */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import { postAbandonClick } from '../api'

const useStyles = makeStyles(
  theme => ({
    root: {},
    paper: {},
    content: {},

    text: {
      color: theme.palette.text.primary
    },

    actions: {
      justifyContent: 'space-between',
      padding: theme.spacing(3)
    },

    actionButton: {
      padding: theme.spacing(0.75, 2),
      transition: theme.transitions.create('opacity')
    },

    actionButtonLabel: {
      columnGap: `${theme.spacing(0.75)}px`
    }
  }),
  {
    name: 'ConfirmAbandonDialog'
  }
)

const ConfirmAbandonDialog = props => {
  const { open, onClose, onConfirm, pageNumber } = props

  const { scriptId } = useParams()

  useEffect(() => {
    // We fire-and-forget an abandon-consideration report on open, if we have a script ID.
    if (open && scriptId) {
      postAbandonClick({ id: scriptId }, { page: pageNumber - 1 })
    }
  }, [open, pageNumber, scriptId])

  const classes = useStyles(props)
  return (
    <Dialog classes={{ root: classes.root, paper: classes.paper }} open={Boolean(open)}>
      <DialogTitle className={classes.title}>
        <span role="img" aria-label="are-you-sure">
          😮
        </span>{' '}
        Are you sure you want to Abandon this script?
      </DialogTitle>

      <DialogContent className={classes.content}>
        <DialogContentText className={classes.text}>
          <em>
            <strong>Please note:</strong> If you Abandon before reading at least <sup>2</sup>&frasl;<sub>3</sub> of the
            script, you will forfeit payment.
          </em>{' '}
          <span role="img" aria-label="forfeit">
            💸
          </span>
        </DialogContentText>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          classes={{ root: classes.actionButton, label: classes.actionButtonLabel }}
          color="primary"
          variant="contained"
          onClick={onClose}
        >
          <span role="img" aria-label="continue">
            📄
          </span>
          Continue Reading
        </Button>

        <Button classes={{ root: classes.actionButton, label: classes.actionButtonLabel }} onClick={onConfirm}>
          <span role="img" aria-label="confirm-abandon">
            🚫
          </span>
          Abandon Script
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmAbandonDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func, // Function to call if the user confirms abandonment.
  pageNumber: PropTypes.number // The one-based page at which the abandon was invoked.
}

export default ConfirmAbandonDialog

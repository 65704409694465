import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'

import CloseIcon from '@material-ui/icons/Close'

import { useSuperuserCheck } from './app/hooks'
import { findUsers, editScript } from './api'
import MEDIA from './models/media'
import { unexpectedErrorContainer } from './theme'

import ErrorResponse from './ErrorResponse'
import ScriptProperties from './ScriptProperties'

const useStyles = makeStyles(
  theme => ({
    editTitle: {
      fontSize: theme.typography.pxToRem(48),
      fontWeight: theme.typography.fontWeightRegular,
      paddingBottom: 0
    },

    editContent: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 0
    },

    editActions: {
      margin: theme.spacing(0, 2, 1, 2),
      '& .MuiButton-label': {
        // Material UI class names are stable as long as we use the same components.
        fontSize: theme.typography.pxToRem(20),
        padding: theme.spacing(0, 1.5)
      }
    },

    editProgress: {
      marginLeft: theme.spacing(1)
    },

    closeButton: {
      color: theme.palette.grey[500],
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1)
    },

    titleField: {
      marginBottom: theme.spacing(2.25),
      marginTop: theme.spacing(2.25)
    },

    unexpectedErrorContent: unexpectedErrorContainer(theme)
  }),
  {
    name: 'ScriptDialog'
  }
)

const ScriptDialog = props => {
  const [title, setTitle] = useState('')
  const [logline, setLogline] = useState('')
  const [genres, setGenres] = useState([])
  const [medium, setMedium] = useState('')
  const [warnings, setWarnings] = useState([])
  const [group, setGroup] = useState([])
  const [groups, setGroups] = useState([]) // TODO Better name = readerGroups? (preserving for now to match API)

  const [saveScriptInProgress, setSaveScriptInProgress] = useState(false)
  const [unexpectedError, setUnexpectedError] = useState(null)

  const { script, open, onClose, onSuccess } = props

  const canSave = () => script && title
  const handleTitleChange = event => setTitle(event.target.value)
  const showGroups = useSuperuserCheck()

  const saveScriptProperties = async event => {
    setSaveScriptInProgress(true)
    try {
      const changedScript = {
        id: script.id,
        title,
        logline,
        medium,
        genres,
        warnings,
        group: group.map(groupMember => groupMember.username)
      }

      if (groups.length > 0) {
        // Should only happen if the user is a superuser.
        changedScript.groups = [...groups]
      }

      await editScript(changedScript)
      setSaveScriptInProgress(false)

      if (onSuccess) {
        onSuccess(changedScript)
      }
    } catch (error) {
      setUnexpectedError(error)
      setSaveScriptInProgress(false)
    }
  }

  useEffect(() => {
    // No need to populate the dialog if it isn’t open anyway.
    if (!open) {
      return
    }

    if (!script) {
      setTitle('')
      setLogline('')
      setGenres([])
      setMedium('')
      setWarnings([])
      setGroup([])
      setGroups([])
      return
    }

    const initWithScript = async () => {
      setTitle(script.title)
      setLogline(script.logline)
      setMedium(Object.keys(MEDIA).includes(script.medium) ? script.medium : '')

      if (script.warnings) {
        setWarnings([...script.warnings])
      }

      if (script.genres) {
        setGenres([...script.genres])
      }

      if (script.group) {
        const group = await Promise.all(
          script.group.map(async groupMember => {
            const response = await findUsers(groupMember, [])
            return response.find(user => user.username === groupMember)
          })
        )

        setGroup(group.filter(user => Boolean(user)))
      }

      if (script.groups) {
        setGroups([...script.groups])
      }
    }

    initWithScript()
  }, [script, open])

  const classes = useStyles(props)
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" area-labelledby="info-dialog-title">
      <form>
        <DialogTitle id="info-dialog-title" className={classes.editTitle} disableTypography={true}>
          Edit Script Properties
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {unexpectedError && (
          <DialogContent className={classes.unexpectedErrorContent}>
            <div>
              A problem was encountered with the attempted edit. This might be a transient issue; if so, you may try
              again a little later.
            </div>

            <ErrorResponse error={unexpectedError} />
          </DialogContent>
        )}

        <DialogContent className={classes.editContent}>
          <DialogContentText>
            Script properties may be edited, but the script itself cannot be changed. In order to do that, the script
            must be re-uploaded.
          </DialogContentText>

          <TextField
            className={classes.titleField}
            required
            fullWidth
            variant="outlined"
            label="Title"
            value={title}
            onChange={handleTitleChange}
          />

          <ScriptProperties
            logline={logline}
            setLogline={setLogline}
            genres={genres}
            setGenres={setGenres}
            medium={medium}
            setMedium={setMedium}
            warnings={warnings}
            setWarnings={setWarnings}
            group={group}
            setGroup={setGroup}
            groups={groups}
            setGroups={setGroups}
            showGroups={showGroups}
          />
        </DialogContent>

        <DialogActions className={classes.editActions}>
          <Button disabled={saveScriptInProgress} onClick={onClose}>
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            disabled={!canSave() || saveScriptInProgress}
            onClick={saveScriptProperties}
          >
            Save
            {saveScriptInProgress && <CircularProgress className={classes.editProgress} color="inherit" size={18} />}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

ScriptDialog.propTypes = {
  script: PropTypes.object.isRequired,

  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
}

export default ScriptDialog

import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import { submitButton } from './theme'

import ContactUs from './ContactUs'
import Submit from './Submit'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',

      '& a': {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(16),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      }
    },

    chevronAdjust: {
      marginLeft: theme.spacing(-1) // To compensate for chevron icon’s intrinsic margin.
    },

    submit: submitButton(theme)
  }),
  {
    name: 'Shortcuts'
  }
)

const Shortcuts = props => {
  const [submitOpen, setSubmitOpen] = useState(false)

  const classes = useStyles(props)

  const handleUploadClose = event => setSubmitOpen(false)

  return (
    <div className={classes.root}>
      <Button color="primary" component={Link} to="/">
        <ChevronLeftIcon className={classes.chevronAdjust} />
        Dashboard
      </Button>

      {submitOpen && <Submit open={submitOpen} onClose={handleUploadClose} onSuccess={handleUploadClose} />}

      <ContactUs />
    </div>
  )
}

export default Shortcuts

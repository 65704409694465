import React, { useState, useEffect, useCallback } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import CircularProgress from '@material-ui/core/CircularProgress'

import { getScript, getResponseStatuses, user, getScriptResponseAverages } from './api'
import { useInfiniteScroll } from './app/hooks'

import RESPONSE_CATEGORIES from './models/responseCategories'
import { scriptIdFromProps } from './utility'

import ResponseCard from './ResponseCard'

const useStyles = makeStyles(
  theme => ({
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        width: '100%'
      }
    },

    responseTitle: {
      color: '#0a0a0a',
      fontSize: theme.typography.pxToRem(31),
      fontWeight: theme.typography.fontWeightRegular,
      margin: theme.spacing(2, 0),
      textAlign: 'center'
    },

    aggregateContainer: {
      marginBottom: theme.spacing(2)
    },

    reviewerContainer: {
      display: 'grid',
      gridGap: theme.spacing(2),

      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(2, 1fr)'
      },

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    },

    conclusion: {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'Courier, "Courier New", monospace',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
      gridColumnStart: 1,
      gridColumnEnd: 3,
      justifyContent: 'flex-end',
      textTransform: 'uppercase',

      '& > *': {
        marginLeft: theme.spacing(1)
      }
    }
  }),
  {
    name: 'Response'
  }
)

const DEFAULT_LIMIT = 100
const PAGE_KEY = 'cursor'
const LIMIT_KEY = 'limit'

const loadScriptResponsePage = async (script, responseStatusesCursor) => {
  const responseStatusesParams = new URLSearchParams()
  responseStatusesParams.set(LIMIT_KEY, DEFAULT_LIMIT)

  if (responseStatusesCursor) {
    responseStatusesParams.set(PAGE_KEY, responseStatusesCursor)
  }

  const responseStatuses = await getResponseStatuses(script, responseStatusesParams)
  return {
    itemsPage: responseStatuses.statuses,
    cursorNext: responseStatuses.cursor_next
  }
}

const Response = props => {
  const [script, setScript] = useState(null)

  const [reviewerResponses, setReviewerResponses] = useState(null)
  const [aggregate, setAggregate] = useState(null)

  const scriptId = scriptIdFromProps(props)

  const scriptResponseLoader = useCallback(
    async cursor => {
      if (!script) {
        return
      }

      return await loadScriptResponsePage(script, cursor)
    },
    [script]
  )

  const {
    items: responseStatuses,
    complete: responseStatusesComplete,
    paging,
    reset,
    lastItem: lastResponse
  } = useInfiniteScroll(scriptResponseLoader)

  useEffect(() => {
    let active = true

    const loadScript = async () => {
      const script = await getScript(scriptId)
      if (!active) {
        return
      }

      setScript(script)
    }

    loadScript()
    return () => {
      active = false
    }
  }, [scriptId])

  useEffect(() => {
    if (!script) {
      return
    }

    let active = true

    const loadScriptResponseAverages = async () => {
      const averages = await getScriptResponseAverages(script)
      if (!active) {
        return
      }

      setAggregate({
        title: 'Aggregate Responses',

        overall: {
          rating: averages.Overall.toFixed(1),
          evaluation: Object.fromEntries(
            RESPONSE_CATEGORIES.keys.map(category => [category, averages[category].toFixed(1)])
          )
        },

        completed: true
      })
    }

    loadScriptResponseAverages()
    return () => {
      active = false
    }
  }, [script])

  // TODO This is currently a brute-force update—change this to become more granular.
  const scriptResponseEdited = async () => {
    if (reset) {
      reset()
    }

    setScript({ ...script })
  }

  useEffect(() => {
    if (!responseStatuses) {
      return
    }

    const createReviewerResponses = () =>
      responseStatuses.map(responseStatus => {
        const reviewerResponse = {
          reviewer: responseStatus.reviewer,
          completed: responseStatus.status === 'completed',
          abandoned: responseStatus.status === 'abandoned',
          page_response_count: responseStatus.page_response_count
        }

        const scriptResponse = responseStatus.script_response
        reviewerResponse.overall = scriptResponse
          ? {
              rating: scriptResponse.rating,
              evaluation: scriptResponse.evaluation,
              comment: scriptResponse.comment
            }
          : null

        return reviewerResponse
      })

    setReviewerResponses(createReviewerResponses())
  }, [responseStatuses])

  const classes = useStyles(props)
  return (
    <section className={classes.root}>
      {script ? (
        <>
          <h3 className={classes.responseTitle}>
            Responses to <em>{script.title}</em>
          </h3>

          {aggregate ? (
            <article className={classes.aggregateContainer}>
              <ResponseCard response={aggregate} script={script} aggregate />
            </article>
          ) : (
            <CircularProgress />
          )}

          {reviewerResponses &&
            (reviewerResponses.length ? (
              <section className={classes.reviewerContainer}>
                {reviewerResponses.map((reviewerResponse, index) => (
                  <article key={index} ref={lastResponse}>
                    <ResponseCard
                      response={reviewerResponse}
                      script={script}
                      editResponseCallback={scriptResponseEdited}
                    />
                  </article>
                ))}

                <article className={classes.conclusion}>
                  {responseStatusesComplete ? (
                    <em>Fade out.</em>
                  ) : (
                    <>
                      <span>Cont’d</span>
                      {paging && <CircularProgress />}
                    </>
                  )}
                </article>
              </section>
            ) : (
              <>
                <article>
                  <em>
                    No available responses
                    {script.author === user() && ' (yet)'}
                  </em>
                </article>

                <article>
                  If others have finished reading this script, you will see those responses after you submit yours!
                </article>
              </>
            ))}
        </>
      ) : (
        <CircularProgress />
      )}
    </section>
  )
}

export default Response

import React, { useState, useCallback, useMemo } from 'react'

import { ThemeProvider } from '@material-ui/styles'

import { UpdateContext } from './app/contexts'
import theme from './theme'

const Scaffold = props => {
  const { children } = props

  const [scriptToggle, setScriptToggle] = useState(false)
  const updateScripts = useCallback(() => setScriptToggle(scriptToggle => !scriptToggle), [])
  const updateContext = useMemo(
    () => ({
      scripts: scriptToggle,
      updateScripts
    }),
    [scriptToggle, updateScripts]
  )

  return (
    <ThemeProvider theme={theme}>
      <UpdateContext.Provider value={updateContext}>{children}</UpdateContext.Provider>
    </ThemeProvider>
  )
}

export default Scaffold

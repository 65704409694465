import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(
  theme => ({
    root: {},

    summary: {
      justifyContent: 'space-between'
    },

    subtitle: {
      marginTop: theme.spacing(1)
    },

    action: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },

    details: {
      flexDirection: 'column',
      padding: 0
    }
  }),
  {
    name: 'Disclosure'
  }
)

const Disclosure = props => {
  const { children, className, action, initiallyClosed, subtitle, title, titleVariant } = props

  const classes = useStyles(props)
  return (
    <div className={clsx(classes.root, className)}>
      <Accordion defaultExpanded={!initiallyClosed}>
        <AccordionSummary classes={{ content: classes.summary }} expandIcon={<ExpandMoreIcon />}>
          <div>
            <Typography variant={titleVariant || 'h4'}>{title}</Typography>
            {subtitle && <em className={classes.subtitle}>{subtitle}</em>}
          </div>

          {action && <div className={classes.action}>{action}</div>}
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.details }}>{children}</AccordionDetails>
      </Accordion>
    </div>
  )
}

Disclosure.propTypes = {
  className: PropTypes.string,
  action: PropTypes.node, // Echoing action prop of CardHeader component.
  initiallyClosed: PropTypes.bool, // Whether the disclosure item should be closed at first.
  subtitle: PropTypes.node,
  title: PropTypes.node,
  titleVariant: PropTypes.oneOf([
    // Should correspond to Typography.variant prop type.
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'caption',
    'button',
    'overline',
    'srOnly',
    'inherit'
  ])
}

export default Disclosure

import React, { useCallback } from 'react'

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'

import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import SkipNextIcon from '@material-ui/icons/SkipNext'

import { getPageResponses, user } from '../api'
import { useInfiniteScroll } from '../app/hooks'

import RATINGS from '../models/ratings'

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100%',
      '& > div': {
        display: 'flex'
      }
    },

    controlsContainer: {
      alignItems: 'flex-start'
    },

    controlsLabel: {
      whiteSpace: 'nowrap'
    },

    skipPreviousAdjust: {
      marginLeft: theme.spacing(-1) // To compensate for skip previous icon’s intrinsic margin.
    },

    skipNextAdjust: {
      marginRight: theme.spacing(-1) // To compensate for skip next icon’s intrinsic margin.
    },

    pageSelectContainer: {
      marginLeft: theme.spacing(1),
      '& > select': {
        paddingBottom: 9, // Meant to match height of Previous Page button exactly.
        paddingTop: 9
      }
    },

    progressContainer: {
      alignItems: 'center',
      display: 'flex',
      flexGrow: 1,
      minHeight: 70, // To minimize height-shifting while loading.
      justifyContent: 'center'
    },

    commentTable: {
      flexGrow: 1,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 'auto',
      '& tbody td': {
        verticalAlign: 'top'
      }
    },

    commentBodyColumn: {
      maxWidth: theme.spacing(57)
    },

    comment: {
      whiteSpace: 'pre-wrap'
    },

    commentField: {
      marginBottom: theme.spacing(1)
    },

    ratingLabel: {
      fontSize: theme.typography.pxToRem(14)
    },

    ratingRadioContainer: {
      margin: theme.spacing(0.75, 0)
    },

    ratingRadio: {
      marginBottom: theme.spacing(-0.25),
      marginRight: theme.spacing(0.25),
      padding: theme.spacing(0.5)
    },

    ratingRadioLabel: {
      whiteSpace: 'nowrap'
    },

    textCenter: {
      textAlign: 'center'
    },

    unabandon: {
      backgroundColor: theme.palette.success.main,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      '&:hover': {
        backgroundColor: theme.palette.success.dark
      }
    }
  }),
  {
    name: 'FinishedReview'
  }
)

const DEFAULT_LIMIT = 20
const PAGE_KEY = 'cursor'
const LIMIT_KEY = 'limit'

const loadPageResponsePage = async (script, pageNumber, pageResponsesCursor) => {
  const pageResponsesParams = new URLSearchParams()
  pageResponsesParams.set(LIMIT_KEY, DEFAULT_LIMIT)

  if (pageResponsesCursor) {
    pageResponsesParams.set(PAGE_KEY, pageResponsesCursor)
  }

  const pageResponses = await getPageResponses(script, pageNumber, pageResponsesParams)
  return {
    itemsPage: pageResponses.responses,
    cursorNext: pageResponses.cursor_next
  }
}

const FinishedReview = props => {
  const {
    script,
    pageNumber,
    setPageNumber,
    loading,
    onLastPage,
    availablePages,
    comment,
    updateComment,
    updateRating,
    unabandon
  } = props

  const reviewerIsCurrentUser = responseStatus => responseStatus.reviewer === user()
  const getPageResponse = reviewer => pageResponses.find(pageResponse => pageResponse.reviewer === reviewer)

  const getComment = pageResponse => {
    if (
      !reviewerIsCurrentUser(pageResponse) &&
      (pageResponse.status === 'in progress' || pageResponse.status === 'new')
    ) {
      return '(not yet done with review)'
    } else {
      const reviewerPageResponse = getPageResponse(pageResponse.reviewer)
      return reviewerPageResponse && reviewerPageResponse.comment
    }
  }

  const getAddendum = pageResponse => {
    if (pageResponse.status === 'abandoned') {
      return `(abandoned from page ${pageResponse.pages_reviewed + 1} onward)`
    } else {
      // complete: nothing more to say
      return ''
    }
  }

  const getRating = pageResponse => {
    if (pageResponse.status === 'in progress' || pageResponse.status === 'new') {
      return ''
    } else {
      const reviewerPageResponse = getPageResponse(pageResponse.reviewer)
      return reviewerPageResponse ? RATINGS.keys[reviewerPageResponse.rating] : ''
    }
  }

  const pageResponseLoader = useCallback(
    async cursor => {
      if (!script) {
        return
      }

      return await loadPageResponsePage(script, pageNumber, cursor)
    },
    [script, pageNumber]
  )

  const { items: pageResponses, lastItem: lastResponse } = useInfiniteScroll(pageResponseLoader)

  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      <div className={classes.controlsContainer}>
        <Button
          classes={{ label: classes.controlsLabel }}
          variant="contained"
          color="primary"
          disabled={loading || pageNumber <= 1}
          onClick={() => setPageNumber(pageNumber - 1)}
        >
          <SkipPreviousIcon className={classes.skipPreviousAdjust} />
          Previous Page
        </Button>

        <FormControl variant="outlined">
          <Select
            native
            className={classes.pageSelectContainer}
            disabled={loading}
            value={pageNumber}
            onChange={event => setPageNumber(+event.target.value)}
          >
            {availablePages().map(pageNumber => (
              <option key={pageNumber} value={pageNumber}>
                {pageNumber}
              </option>
            ))}
          </Select>
        </FormControl>

        {loading ? (
          <div className={classes.progressContainer}>
            <CircularProgress size={24} />
          </div>
        ) : (
          <TableContainer className={classes.commentTable} component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Reviewer</TableCell>
                  <TableCell className={classes.commentBodyColumn}>Comment</TableCell>
                  <TableCell>Rating</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!pageResponses || pageResponses.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan="3" className={classes.textCenter}>
                      {pageResponses && '(no reviews yet)'}
                    </TableCell>
                  </TableRow>
                ) : (
                  pageResponses.map((pageResponse, index) => (
                    <TableRow key={`pageResponse${index}`} ref={lastResponse}>
                      <TableCell>{pageResponse.reviewer}</TableCell>
                      {reviewerIsCurrentUser(pageResponse) && (
                        <>
                          <TableCell className={classes.commentBodyColumn}>
                            <div>
                              {pageNumber <= pageResponse.pages_reviewed && (
                                <TextField
                                  className={classes.commentField}
                                  fullWidth
                                  multiline
                                  variant="outlined"
                                  minRows={5}
                                  placeholder="No comment"
                                  value={
                                    /* The comment passed by the caller is the current user’s comment. */
                                    comment
                                  }
                                  onChange={event => updateComment(event.target.value)}
                                />
                              )}
                              <div>{getAddendum(pageResponse)}</div>
                            </div>
                          </TableCell>
                          <TableCell>
                            {pageNumber <= pageResponse.pages_reviewed && (
                              <FormControl className={classes.ratingRadioContainer} component="fieldset">
                                <RadioGroup
                                  aria-label="rating"
                                  name="rating"
                                  value={getRating(pageResponse) || ''}
                                  onChange={event => updateRating(event.target.value)}
                                >
                                  {RATINGS.keys.map(rating => (
                                    <FormControlLabel
                                      key={rating}
                                      value={rating}
                                      className={classes.ratingRadioLabel}
                                      control={
                                        <Radio
                                          color="primary"
                                          disabled={loading}
                                          classes={{ colorPrimary: classes.ratingRadio }}
                                        />
                                      }
                                      label={<span className={classes.ratingLabel}>{RATINGS[rating]}</span>}
                                    />
                                  ))}
                                </RadioGroup>
                              </FormControl>
                            )}

                            {pageResponse.pages_reviewed < pageNumber && (
                              <Button className={classes.unabandon} disabled={loading} onClick={unabandon}>
                                Unabandon
                              </Button>
                            )}
                          </TableCell>
                        </>
                      )}

                      {!reviewerIsCurrentUser(pageResponse) && (
                        <>
                          <TableCell className={classes.commentBodyColumn}>
                            <div className={classes.comment}>{getComment(pageResponse)}</div>
                            <div>{getAddendum(pageResponse)}</div>
                          </TableCell>
                          <TableCell>{RATINGS[getRating(pageResponse)]}</TableCell>
                        </>
                      )}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Button
          classes={{ label: classes.controlsLabel }}
          variant="contained"
          color="primary"
          disabled={loading || onLastPage()}
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          Next Page
          <SkipNextIcon className={classes.skipNextAdjust} />
        </Button>
      </div>
    </div>
  )
}

FinishedReview.propTypes = {
  script: PropTypes.object.isRequired,
  pageNumber: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onLastPage: PropTypes.func.isRequired,
  availablePages: PropTypes.func.isRequired,
  comment: PropTypes.string.isRequired,
  updateComment: PropTypes.func.isRequired,
  updateRating: PropTypes.func.isRequired,
  unabandon: PropTypes.func.isRequired
}

export default FinishedReview

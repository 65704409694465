import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'

import { resetPassword } from './api'

import ErrorResponse, { VARIANT_CONCISE } from './ErrorResponse'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(1, 8),

      '& > *:not(:first-child)': {
        marginTop: theme.spacing(4)
      }
    },

    row: {
      alignItems: 'center',
      columnGap: theme.spacing(2),
      display: 'flex',
      width: '75%'
    }
  }),
  {
    name: 'ResetPassword'
  }
)

const ResetPassword = props => {
  const [email, setEmail] = useState('')

  const [error, setError] = useState()
  const [resetting, setResetting] = useState(false)
  const [resetPasswordResult, setResetPasswordResult] = useState(null)

  const handleEmailChange = event => setEmail(event.target.value)

  const submitResetPasswordRequest = async event => {
    event.preventDefault()
    setResetting(true)

    try {
      const { link_sent_to: resetPasswordResult } = await resetPassword(email)
      setResetPasswordResult(resetPasswordResult)
      setEmail('')
    } catch (error) {
      setError(error)
    } finally {
      setResetting(false)
    }
  }

  const classes = useStyles(props)
  return (
    <form className={classes.root} onSubmit={submitResetPasswordRequest}>
      <h1>Reset your Password</h1>

      <div>Please provide your account’s email address so that we can Zoodǐker you right back.</div>

      <div className={classes.row}>
        <TextField
          label="Email"
          type="email"
          name="email"
          disabled={resetting}
          placeholder="your-email-here@example.com"
          required
          helperText="Please provide your email address."
          fullWidth
          value={email}
          onChange={handleEmailChange}
        />
      </div>

      <div className={classes.row}>
        <Button type="submit" color="primary" variant="contained" disabled={!email || resetting}>
          Send a Password Reset Link
          {email && ` to ${email}`}
        </Button>

        {resetting && <CircularProgress size={24} color="primary" />}
      </div>

      {resetPasswordResult && <div>The password reset email should be on its way!</div>}

      <ErrorResponse variant={VARIANT_CONCISE} error={error} />
    </form>
  )
}

export default ResetPassword

import constProperty from '../constProperty'

const RATING_LAGGING = 'lagging'
const RATING_NEUTRAL = 'neutral'
const RATING_GOOD = 'good'
const RATING_GREAT = 'great'

const RATINGS = {}

// key = internal value; value = display value
constProperty(RATINGS, RATING_LAGGING, 'Lagging', { enumerable: true })
constProperty(RATINGS, RATING_NEUTRAL, 'Neutral', { enumerable: true })
constProperty(RATINGS, RATING_GOOD, 'Interested', { enumerable: true })
constProperty(RATINGS, RATING_GREAT, 'Eager', { enumerable: true })

constProperty(RATINGS, 'keys', Object.keys(RATINGS))

// To minimize refactoring (though possibly in exchange for tech debt),
// we list colors separately. This disrupts existing code the least.
// Eventually, `RATINGS` might best map to objects rather than just
// the string labels.
const RATING_COLORS = {}

constProperty(
  RATING_COLORS,
  RATING_LAGGING,
  { color: 'black', backgroundColor: 'yellow', highlightBackgroundColor: 'gold' },
  { enumerable: true }
)

constProperty(
  RATING_COLORS,
  RATING_NEUTRAL,
  { color: 'black', backgroundColor: '#eee', highlightBackgroundColor: 'lightgray' },
  { enumerable: true }
)

constProperty(
  RATING_COLORS,
  RATING_GOOD,
  { color: 'black', backgroundColor: 'lightgreen', highlightBackgroundColor: 'springgreen' },
  { enumerable: true }
)

constProperty(
  RATING_COLORS,
  RATING_GREAT,
  { color: 'white', backgroundColor: 'green', highlightBackgroundColor: 'limegreen' },
  { enumerable: true }
)

export { RATING_LAGGING, RATING_NEUTRAL, RATING_GOOD, RATING_GREAT, RATING_COLORS }
export default RATINGS

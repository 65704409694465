import React, { useState, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import LinearProgress from '@material-ui/core/LinearProgress'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'

import CheckIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'

import { lookupUsername, confirmSignup, login } from './api'
import debounce from './debounce'

import TitledBorder from './TitledBorder'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(1, 4)
    },

    row: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
      width: '100%',

      '& > *:not(:first-child)': {
        marginLeft: theme.spacing(4)
      }
    },

    credentials: {
      borderRadius: theme.spacing(1),
      boxShadow: '0 0 8px #1779ba90',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(4),
      paddingBottom: theme.spacing(6),
      paddingTop: theme.spacing(3),
      width: '100%',

      '& > *': {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '66.6%' // Baphometian Easter egg.
      },

      '& > *:not(:first-child)': {
        marginTop: theme.spacing(2)
      }
    },

    fieldValidity: {
      alignItems: 'center',
      display: 'inline-flex',
      fontSize: theme.typography.pxToRem(14),
      padding: theme.spacing(0.25, 1.25),

      '& svg': {
        marginRight: theme.spacing(0.75)
      }
    },

    invalidField: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white
    },

    validField: {
      backgroundColor: theme.palette.success.main
    },

    interstitial: {
      alignItems: 'center',
      display: 'flex',
      '& > *:not(:first-child)': {
        marginLeft: theme.spacing(1)
      }
    },

    signupProgress: {
      flexGrow: 1,
      marginLeft: theme.spacing(1)
    },

    signupErrorMessage: {
      fontSize: theme.typography.h5.fontSize,
      textAlign: 'center'
    }
  }),
  {
    name: 'Join'
  }
)

const USERNAME_CONTRABAND_PATTERN = /[^A-Za-z0-9_]/
const PASSWORD_PATTERN = '.{12,}'
const LOOKUP_USERNAME_DEBOUNCE_TIME = 500

const convertToValidUsername = username => username.replace(USERNAME_CONTRABAND_PATTERN, '').toLowerCase()
const lookupUsernameDebounced = debounce(lookupUsername, LOOKUP_USERNAME_DEBOUNCE_TIME)

const Join = props => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [eulaChecked, setEulaChecked] = useState(false)

  const [usernameLookupInProgress, setUsernameLookupInProgress] = useState(false)
  const [usernameAvailability, setUsernameAvailability] = useState(null)

  const [signupInProgress, setSignupInProgress] = useState(false)
  const [signupErrorMessage, setSignupErrorMessage] = useState('')

  const history = useHistory()
  const match = useRouteMatch()
  const signupId = match && match.params.signupId
  const passwordIsLongEnough = password.length >= 12
  const passwordIsConfirmed = password === passwordConfirmation
  const passwordError = Boolean(password && !passwordIsLongEnough)
  const passwordConfirmationError = Boolean(password && passwordConfirmation && !passwordIsConfirmed)
  const canConfirm =
    username &&
    eulaChecked &&
    usernameAvailability &&
    usernameAvailability.available &&
    passwordIsLongEnough &&
    passwordIsConfirmed

  const handleUsernameChange = event => setUsername(convertToValidUsername(event.target.value))
  const handlePasswordChange = event => setPassword(event.target.value)
  const handlePasswordConfirmationChange = event => setPasswordConfirmation(event.target.value)
  const handleEulaChange = event => setEulaChecked(event.target.checked)
  const handleSignupErrorDialogClose = event => setSignupErrorMessage('')

  const createAccount = async event => {
    event.preventDefault()

    const payload = {
      username,
      password
    }

    setSignupInProgress(true)
    try {
      const signupResult = await confirmSignup(signupId, payload)
      setSignupInProgress(false)
      if (signupResult.ok) {
        await login({ username, password })
        history.push('/')
      } else {
        setSignupErrorMessage(signupResult.message)
      }
    } catch (error) {
      setSignupInProgress(false)
      setSignupErrorMessage(error.message)
    }
  }

  useEffect(() => {
    // Don’t check if there’s no username.
    if (!username) {
      return
    }

    const checkUsernameAvailability = async () => {
      setUsernameLookupInProgress(true)

      const usernameLookup = await lookupUsernameDebounced(username)
      setUsernameLookupInProgress(false)
      setUsernameAvailability(usernameLookup)
    }

    checkUsernameAvailability()
  }, [username])

  const classes = useStyles(props)

  const confirmElement = (
    <span>
      <Button type="submit" variant="contained" color="primary" disabled={!canConfirm}>
        Zoodǐker Me!
        {signupInProgress && <CircularProgress className={classes.signupProgress} color="inherit" size={14} />}
      </Button>
    </span>
  )

  return (
    <form className={classes.root} onSubmit={createAccount}>
      <div className={classes.row}>
        <strong>Let’s get writing! Required fields glow with a halo of latent creativity.</strong>
        {signupInProgress && <LinearProgress className={classes.signupProgress} />}
      </div>

      <Dialog
        open={Boolean(signupErrorMessage)}
        onClose={handleSignupErrorDialogClose}
        aria-labelledby="signup-error-dialog-title"
        area-describedby="signup-error-dialog-description"
      >
        <DialogTitle id="signup-error-dialog-title">Signup Confirmation Problem</DialogTitle>

        <DialogContent>
          <DialogContentText id="signup-error-dialog-description">
            Sorry, but a problem was encountered upon signup:
          </DialogContentText>

          <DialogContentText className={classes.signupErrorMessage}>
            <em>{signupErrorMessage}</em>
          </DialogContentText>

          <DialogContentText>
            Please double-check your signup URL and the values that you entered, then try again if you have worked out
            what went wrong.
          </DialogContentText>

          <DialogActions>
            <Button variant="contained" color="primary" autoFocus onClick={handleSignupErrorDialogClose}>
              Acknowledge
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <TitledBorder className={classes.credentials}>
        <TextField
          label="Zoodǐker ID"
          type="text"
          name="username"
          required
          autoComplete="username"
          helperText={[
            'What username would you like? We’ll tell you if it’s taken.',
            'Oh, make it all lowercase a–z, numbers, or underscores only.'
          ].join(' ')}
          InputProps={{
            endAdornment: username && (usernameAvailability || usernameLookupInProgress) && (
              <InputAdornment position="end">
                {usernameLookupInProgress ? (
                  <CircularProgress size={16} />
                ) : (
                  usernameAvailability &&
                  (usernameAvailability.available ? (
                    <span className={clsx(classes.fieldValidity, classes.validField)}>
                      <CheckIcon fontSize="inherit" />
                      <span>
                        <strong>{username}</strong> is available!
                      </span>
                    </span>
                  ) : (
                    <span className={clsx(classes.fieldValidity, classes.invalidField)}>
                      <WarningIcon fontSize="inherit" />
                      <span>
                        Sorry, but <strong>{username}</strong> {usernameAvailability.reason || 'is off-limits'}.
                      </span>
                    </span>
                  ))
                )}
              </InputAdornment>
            )
          }}
          value={username}
          onChange={handleUsernameChange}
        />

        <TextField
          label="Password"
          type="password"
          name="password"
          required
          inputProps={{
            pattern: PASSWORD_PATTERN
          }}
          autoComplete="new-password"
          error={passwordError}
          helperText="Please supply a password (12-character minimum)."
          InputProps={{
            endAdornment: passwordError && (
              <InputAdornment position="end">
                <span className={clsx(classes.fieldValidity, classes.invalidField)}>
                  <WarningIcon fontSize="inherit" />
                  <span>You must supply a password of twelve (12) or more characters.</span>
                </span>
              </InputAdornment>
            )
          }}
          value={password}
          onChange={handlePasswordChange}
        />

        <TextField
          label="Password Confirmation"
          type="password"
          name="password-confirmation"
          required
          inputProps={{
            pattern: PASSWORD_PATTERN
          }}
          autoComplete="new-password"
          error={passwordConfirmationError}
          helperText="Please confirm your password."
          InputProps={{
            endAdornment: passwordConfirmationError && (
              <InputAdornment position="end">
                <span className={clsx(classes.fieldValidity, classes.invalidField)}>
                  <WarningIcon fontSize="inherit" />
                  <span>Passwords must match.</span>
                </span>
              </InputAdornment>
            )
          }}
          value={passwordConfirmation}
          onChange={handlePasswordConfirmationChange}
        />
      </TitledBorder>

      <div className={classes.row}>
        <FormControlLabel
          control={<Checkbox name="eula" checked={eulaChecked} onChange={handleEulaChange} />}
          label={
            <span>
              I agree with the{' '}
              <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">
                Zoodǐker terms and conditions
              </a>{' '}
              (opens in new tab).
            </span>
          }
        />
      </div>

      <div className={classes.row}>
        {!eulaChecked ? (
          <Tooltip arrow placement="right" title="Remember to agree to the Zoodǐker terms and conditions!">
            {confirmElement}
          </Tooltip>
        ) : (
          confirmElement
        )}
      </div>
    </form>
  )
}

export default Join

import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'

import PropTypes from 'prop-types'

import { AVERAGE_KEY, minimumPagesPerMinute, maximumPagesPerMinute } from './visualizationHelpers'

const LINE_THICKNESS = 2
const RADIUS = 4
const RING_THICKNESS = RADIUS * 0.75

const PagesPerMinute = props => {
  const { script, allResponses, reviewerKeys, selectedReviewers } = props
  const graphContainerRef = useRef(null)

  useEffect(() => {
    if (!allResponses) {
      return
    }

    const graphContainer = graphContainerRef.current
    if (!graphContainer) {
      return
    }

    const width = graphContainer.offsetWidth
    const height = (width * 9) / 16 // 16:9
    const margin = { top: 20, right: 0, bottom: 30, left: 40 }

    const x = d3
      .scaleLinear()
      .domain([0, script.page_count])
      .nice()
      .range([margin.left, width - margin.right])

    const y = d3
      .scaleLinear()
      .domain([minimumPagesPerMinute(allResponses), maximumPagesPerMinute(allResponses)])
      .nice()
      .range([height - margin.bottom, margin.top])

    const xAxis = g =>
      g.attr('transform', `translate(0,${height - margin.bottom})`).call(d3.axisBottom(x).tickSizeOuter(0))

    const yAxis = g =>
      g
        .attr('transform', `translate(${margin.left},0)`)
        .call(d3.axisLeft(y))
        .call(g => g.select('.domain').remove())

    const line = d3
      .line()
      .x(d => x(d.page))
      .y(d => y(d.pagesPerMinute))

    graphContainer.innerHTML = ''
    const svg = d3.select(graphContainer).append('svg').attr('width', width).attr('height', height)

    svg.append('g').call(xAxis)
    svg.append('g').call(yAxis)

    selectedReviewers
      .filter(reviewer => reviewer !== AVERAGE_KEY) // This graph doesn’t show an average.
      .forEach(reviewer => {
        const data = allResponses[reviewer]
        const colorIndex = reviewerKeys.indexOf(reviewer) % d3.schemeCategory10.length

        svg
          .append('path')
          .datum(data)
          .attr('fill', 'none')
          .attr('stroke', d3.schemeCategory10[colorIndex])
          .attr('stroke-width', LINE_THICKNESS)
          .attr('stroke-linejoin', 'round')
          .attr('stroke-linecap', 'round')
          .attr('d', line)

        data.forEach(
          response =>
            svg
              .append('circle')
              .datum(response)
              .attr('class', 'dot')
              .attr('fill', d3.schemeCategory10[colorIndex])
              .attr('stroke', d3.schemeCategory10[colorIndex])
              .attr('stroke-width', RING_THICKNESS)
              .attr('cx', line.x())
              .attr('cy', line.y())
              .attr('r', d => (d.rating + 1) * RADIUS) // Ratings start at zero, so bump for a minimum circle.
        )
      })
  }, [script, allResponses, graphContainerRef, reviewerKeys, selectedReviewers])

  return <div ref={graphContainerRef}></div>
}

PagesPerMinute.propTypes = {
  script: PropTypes.object.isRequired,
  allResponses: PropTypes.object.isRequired,
  reviewerKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedReviewers: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default PagesPerMinute

import { createTheme } from '@material-ui/core/styles'

const THEME_PRIMARY_MAIN = '#1779ba'

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          color: THEME_PRIMARY_MAIN,
          textDecoration: 'none',

          '&:visited': {
            color: THEME_PRIMARY_MAIN
          },

          '&:hover': {
            textDecoration: 'underline'
          },

          '&:active': {
            textDecoration: 'underline'
          }
        }
      }
    },

    MuiButton: {
      root: {
        borderRadius: 0
      },

      contained: {
        boxShadow: 'none',

        '&:hover': {
          boxShadow: 'none'
        },

        '&$disabled': {
          backgroundColor: '',
          color: 'black',
          cursor: 'not-allowed',
          opacity: 0.25
        }
      },

      containedPrimary: {
        color: 'white',

        // a pseudoclasses are needed here for buttons that are actually links.
        '&:hover': {
          color: 'white'
        },

        '&:visited': {
          color: 'white'
        },

        '&$disabled': {
          color: 'white'
        }
      },

      containedSecondary: {
        // a pseudoclasses are needed here for buttons that are actually links.
        '&:hover': {
          color: 'inherit'
        },

        '&:visited': {
          color: 'inherit'
        },

        '&$disabled': {
          color: '#442300' // Eyeballed to go well with the faded secondary color.
        }
      }
    }
  },

  palette: {
    primary: {
      main: THEME_PRIMARY_MAIN
    },

    secondary: {
      main: '#ffae00'
    },

    success: {
      main: '#3adb76',
      dark: '#22bb5b'
    },

    error: {
      light: '#f7e4e1',
      main: '#cc4b37',
      dark: '#66251d'
    }
  },

  typography: {
    fontFamily: '"Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',

    button: {
      fontWeight: 400,
      fontSize: '0.9rem',
      letterSpacing: 'normal',
      textTransform: 'none'
    }
  }
})

const unexpectedErrorContainer = theme => ({
  backgroundColor: theme.palette.error.light,
  border: '1px solid rgba(10, 10, 10, 0.25)',
  margin: theme.spacing(2),
  padding: theme.spacing(2),

  '& > div:first-child': {
    marginBottom: theme.spacing(2)
  }
})

const submitButton = theme => ({
  backgroundColor: theme.palette.success.main,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),

  '&:hover': {
    backgroundColor: theme.palette.success.dark
  }
})

export { submitButton, unexpectedErrorContainer }

export default theme

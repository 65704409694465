/**
 * This module contains the various context objects that provide app-wide state. Components are expected to
 * reference these contexts as needed.
 */
import { createContext } from 'react'

/**
 * The UpdateContext is a set of boolean states that toggle whenever some component changes information about a
 * persisted collection. This will allow other otherwise unrelated components to refresh or update any views that
 * rely on that persisted object (or collection).
 */
export const UpdateContext = createContext({
  scripts: false,
  updateScripts: () => {}
})

import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { login } from './api'

import ErrorResponse, { VARIANT_CONCISE } from './ErrorResponse'

const useStyles = makeStyles(
  theme => ({
    root: {
      marginTop: theme.spacing(10),
      '& > form': {
        border: 'solid 1px rgba(10, 10, 10, 0.25)',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 770,
        padding: theme.spacing(6)
      }
    },

    entryField: {
      marginBottom: theme.spacing(2)
    },

    button: {
      width: '100%',

      [theme.breakpoints.up('sm')]: {
        width: 'auto'
      }
    },

    buttonContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column-reverse',
      gap: `${theme.spacing(2)}px`,
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
      width: '100%',

      [theme.breakpoints.up('sm')]: {
        alignItems: 'baseline',
        flexDirection: 'row',
        width: 'auto'
      }
    },

    buttonSubcontainer: {
      justifyContent: 'flex-start'
    },

    chevronAdjust: {
      marginRight: theme.spacing(-1) // To compensate for chevron icon’s intrinsic margin.
    },

    progress: {
      color: theme.palette.common.white,
      marginLeft: theme.spacing(1)
    }
  }),
  {
    name: 'Login'
  }
)

const Login = props => {
  const { from } = (props.location && props.location.state) || { from: { pathname: '/' } }

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [error, setError] = useState()
  const [loggingIn, setLoggingIn] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    setLoggingIn(true)

    const performLogin = async () => {
      try {
        await login({ username, password })

        // Successful login means no throw.
        setLoggingIn(false)
        props.history.push(from.pathname)
      } catch (error) {
        setError(error)
        setLoggingIn(false)
      }
    }

    performLogin()
  }

  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <ErrorResponse variant={VARIANT_CONCISE} error={error} />

        <TextField
          className={classes.entryField}
          margin="normal"
          variant="outlined"
          label="Username or Email"
          name="username"
          disabled={loggingIn}
          autoComplete="username"
          type="text"
          value={username}
          onChange={event => setUsername(event.target.value)}
        />

        <TextField
          className={classes.entryField}
          margin="normal"
          variant="outlined"
          label="Password"
          name="password"
          disabled={loggingIn}
          autoComplete="password"
          type="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
        />

        <div className={classes.buttonContainer}>
          <span className={clsx(classes.buttonContainer, classes.buttonSubcontainer)}>
            <Button className={classes.button} color="primary" component={Link} to="/reset-password">
              Reset Password
            </Button>

            <Button className={classes.button} color="primary" component={Link} to="/signup-without-frills">
              Sign Up
            </Button>
          </span>

          <Button className={classes.button} type="submit" variant="contained" color="primary" disabled={loggingIn}>
            Login
            {loggingIn ? (
              <CircularProgress className={classes.progress} size={16} />
            ) : (
              <ChevronRightIcon className={classes.chevronAdjust} />
            )}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Login

import React from 'react'

import PropTypes from 'prop-types'

import Chip from '@material-ui/core/Chip'

import MovieIcon from '@material-ui/icons/Videocam'
import TvIcon from '@material-ui/icons/Tv'
import ShortIcon from '@material-ui/icons/YouTube'

export const MediaChip = props => {
  const { medium, ...rest } = props
  const { icon, label } = medium

  return <Chip variant="outlined" icon={icon} label={label} {...rest} />
}

MediaChip.propTypes = {
  medium: PropTypes.shape({
    icon: PropTypes.node,
    label: PropTypes.string
  }).isRequired
}

const MEDIA = {
  // Relative to the other icons, the TV icon is a little larger, so we tweak its font size.
  tv: { icon: <TvIcon fontSize="small" />, label: 'TV' },
  film: { icon: <MovieIcon />, label: 'Feature Film' },
  short: { icon: <ShortIcon />, label: 'Short' }
}

export default MEDIA

/**
 * BooleanInput captures information for the boolean question type.
 */
import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'

import RadioSelect from './RadioSelect'

const useStyles = makeStyles(
  theme => ({
    root: {},

    select: {
      margin: theme.spacing(1.625, 0, 1.125)
    }
  }),
  {
    name: 'BooleanInput'
  }
)

const YES = 'yes'
const NO = 'no'

const ALL = { [YES]: 'Yes', [NO]: 'No' }

// We specifically check for `true` and `false` because falsiness.
const valueToState = value => (value === true ? YES : value === false ? NO : '')
const stateToValue = state => (state === YES ? true : state === NO ? false : undefined)

const BooleanInput = props => {
  const { column, value: valueProp, setValue: setValueProp } = props

  const [value, setValue] = useState(valueToState(valueProp))

  useEffect(() => {
    setValue(valueToState(valueProp))
  }, [valueProp])

  useEffect(() => {
    if (setValueProp) {
      setValueProp(stateToValue(value))
    }
  }, [setValueProp, value])

  const classes = useStyles(props)
  return (
    <span className={classes.root}>
      <RadioSelect className={classes.select} all={ALL} column={column} value={value} setValue={setValue} />
    </span>
  )
}

BooleanInput.propTypes = {
  column: PropTypes.bool, // Whether to display the checkboxes as a column.
  value: PropTypes.bool, // The answer to display.
  setValue: PropTypes.func // Function to call if an answer changes.
}

export default BooleanInput

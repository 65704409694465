import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'

import CloseIcon from '@material-ui/icons/Close'

import { deleteScript } from './api'
import { unexpectedErrorContainer } from './theme'

import ErrorResponse from './ErrorResponse'

const useStyles = makeStyles(
  theme => ({
    deleteTitle: {
      fontSize: theme.typography.pxToRem(48),
      fontWeight: theme.typography.fontWeightRegular,
      paddingBottom: 0
    },

    deleteContent: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 0
    },

    deleteActions: {
      margin: theme.spacing(0, 2, 1, 2),
      '& .MuiButton-label': {
        // Material UI class names are stable as long as we use the same components.
        fontSize: theme.typography.pxToRem(20),
        padding: theme.spacing(0, 1.5)
      }
    },

    deleteButton: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: theme.palette.error.main
        }
      }
    },

    deleteProgress: {
      marginLeft: theme.spacing(1)
    },

    closeButton: {
      color: theme.palette.grey[500],
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1)
    },

    titleField: {
      marginBottom: theme.spacing(2.25),
      marginTop: theme.spacing(2.25)
    },

    titleFieldLabel: {
      '&.Mui-focused': {
        color: theme.palette.error.main
      }
    },

    titleFieldOutline: {
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main
      }
    },

    unexpectedErrorContent: unexpectedErrorContainer(theme)
  }),
  {
    name: 'Delete'
  }
)

const Delete = props => {
  const [title, setTitle] = useState('')

  const [deleteScriptInProgress, setDeleteScriptInProgress] = useState(false)
  const [unexpectedError, setUnexpectedError] = useState(null)

  const { script, open, onClose, onDelete } = props

  const canDelete = () => script && script.title === title
  const disableSubmit = !canDelete() || deleteScriptInProgress
  const handleTitleChange = event => setTitle(event.target.value)

  const handleDeleteScript = async event => {
    event.preventDefault()
    if (disableSubmit) {
      return
    }

    setDeleteScriptInProgress(true)
    try {
      await deleteScript(script)
      setDeleteScriptInProgress(false)

      if (onDelete) {
        onDelete(script)
      }
    } catch (error) {
      setUnexpectedError(error)
      setDeleteScriptInProgress(false)
    }
  }

  useEffect(() => {
    if (open) {
      setTitle('')
    }
  }, [script, open])

  const classes = useStyles(props)
  return (
    <Dialog open={open} onClose={onClose} area-labelledby="delete-dialog-title">
      <form onSubmit={handleDeleteScript}>
        <DialogTitle id="delete-dialog-title" className={classes.deleteTitle} disableTypography={true}>
          Delete Your Writing
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {unexpectedError && (
          <DialogContent className={classes.unexpectedErrorContent}>
            <div>
              A problem was encountered with the attempted deletion. This might be a transient issue; if so, you may try
              again a little later.
            </div>

            <ErrorResponse error={unexpectedError} />
          </DialogContent>
        )}

        <DialogContent className={classes.deleteContent}>
          <DialogContentText>
            Deleting your script will also render all reviews and comments in accessible.
          </DialogContentText>

          <DialogContentText>
            If you are certain of this, please type the script’s title exactly as it appears in your submission below
            then press the <em>Delete Submission</em> button.
          </DialogContentText>

          <TextField
            className={classes.titleField}
            required
            fullWidth
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.titleFieldLabel
              }
            }}
            InputProps={{
              classes: {
                root: classes.titleFieldOutline
              }
            }}
            label="Title exactly as it appears in your submission"
            helperText="Please provide the script’s title exactly as it appears in your submission."
            value={title}
            onChange={handleTitleChange}
          />
        </DialogContent>

        <DialogActions className={classes.deleteActions}>
          <Button disabled={deleteScriptInProgress} onClick={onClose}>
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            classes={{
              containedPrimary: classes.deleteButton
            }}
            disabled={disableSubmit}
            type="submit"
          >
            Delete Submission
            {deleteScriptInProgress && (
              <CircularProgress className={classes.deleteProgress} color="inherit" size={18} />
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

Delete.propTypes = {
  script: PropTypes.object.isRequired,

  open: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func
}

export default Delete

/**
 * The Survey component displays a post-reading survey to solicit the reader’s feedback for
 * the given script.
 */
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import { getScript, getScriptSurveyAnswers, getScriptSurveyQuestions, putScriptSurveyAnswers } from './api'
import { emptyArray, emptyBool } from './dirty'
import { TYPE_BOOLEAN, TYPE_MULTISELECT } from './form/constants'
import { submitButton } from './theme'
import { scriptIdFromProps } from './utility'

import avatar from './assets/avatar.png'

import ErrorResponse from './ErrorResponse'
import Question from './form/Question'

const useStyles = makeStyles(
  theme => ({
    root: {
      margin: theme.spacing(2, 0),

      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(2, 3)
      }
    },

    progressRoot: {
      textAlign: 'center'
    },

    progress: {},

    title: {
      color: '#0a0a0a',
      fontSize: theme.typography.pxToRem(31),
      fontWeight: theme.typography.fontWeightRegular,
      margin: theme.spacing(2, 0),
      textAlign: 'center',

      '& $message': {
        fontSize: theme.typography.pxToRem(16),
        margin: theme.spacing(0.5, 0, 4)
      }
    },

    list: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      marginBottom: theme.spacing(4),
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 0,
      maxWidth: theme.spacing(64),
      padding: 0,

      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(20),
        maxWidth: theme.spacing(96)
      }
    },

    item: {
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(2),
      display: 'block', // Lets us move the marker deeper within bounds without losing outer positioning.
      margin: theme.spacing(4, 0),
      padding: theme.spacing(3, 2, 1.25, 5),

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(4, 5, 1.75, 8)
      }
    },

    question: {
      display: 'list-item'
    },

    buttonBar: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.spacing(1)}px`,
      justifyContent: 'center',
      margin: theme.spacing(0, 2),

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row'
      }
    },

    button: {
      width: '100%',

      [theme.breakpoints.up('sm')]: {
        width: 'auto'
      }
    },

    avatar: {
      height: theme.spacing(3),
      width: theme.spacing(3)
    },

    save: {},
    submit: submitButton(theme),

    message: {
      display: 'flex',
      justifyContent: 'center',
      margin: theme.spacing(2, 0),
      opacity: 1.0,
      transition: theme.transitions.create('opacity')
    },

    hidden: {
      opacity: 0.0
    }
  }),
  {
    name: 'Survey'
  }
)

const Survey = props => {
  const scriptId = scriptIdFromProps(props)
  const history = useHistory()

  const [answers, setAnswers] = useState({})
  const [questions, setQuestions] = useState([])
  const [qaLoading, setQaLoading] = useState(false)

  const [final, setFinal] = useState(false)
  const [script, setScript] = useState()
  const [scriptLoading, setScriptLoading] = useState(false)

  const loading = qaLoading || scriptLoading

  const [error, setError] = useState()

  // We memoize because having the answer handlers to change with every render will cause an
  // infinite effect loop.
  const answerHandlers = useMemo(
    () =>
      Object.fromEntries(
        questions.map(({ code }) => [code, answer => setAnswers(answers => ({ ...answers, [code]: answer }))])
      ),
    [questions]
  )

  const incomplete = questions.reduce((cumulative, question) => {
    // Once incomplete, always incomplete.
    if (cumulative) {
      return cumulative
    }

    const { code, type } = question
    const answer = answers[code]
    return type === TYPE_MULTISELECT ? emptyArray(answer) : type === TYPE_BOOLEAN ? emptyBool(answer) : !answer
  }, false)

  const handleSave = async event => {
    try {
      await putScriptSurveyAnswers(scriptId, { answers })
      history.push('/')
    } catch (error) {
      setError(error)
    }
  }

  const handleSubmit = async event => {
    // Provisional confirmation dialog pending in-app version.
    const confirmSubmit = window.confirm(
      'After submitting your feedback as final, you will no longer be able to edit it. Shall we go ahead?'
    )

    if (!confirmSubmit) {
      return
    }

    try {
      await putScriptSurveyAnswers(scriptId, { final: true, answers })
      history.push('/')
    } catch (error) {
      setError(error)
    }
  }

  useEffect(() => {
    let active = true

    const retrieveScript = async () => {
      setScriptLoading(true)

      try {
        const script = await getScript(scriptId)
        if (!active) {
          return
        }

        setScript(script)
      } catch (error) {
        setError(error)
      }

      setScriptLoading(false)
    }

    retrieveScript()
    return () => {
      active = false
    }
  }, [scriptId])

  useEffect(() => {
    let active = true

    const retrieveQuestionsAndAnswers = async () => {
      setQaLoading(true)

      try {
        const answersContainer = await getScriptSurveyAnswers(scriptId)
        if (!active) {
          return
        }

        const { answers, final } = answersContainer
        setFinal(final)

        if (final) {
          // If the survey is final, there is nothing further to do.
          setQuestions([])
          setQaLoading(false)
          return
        } else {
          setAnswers(answers ?? {})
        }
      } catch (error) {
        // We can ignore 404s safely, but nothing else.
        if (error.response?.status !== 404) {
          setError(error)
        }
      }

      try {
        const questions = await getScriptSurveyQuestions(scriptId)
        if (!active) {
          return
        }

        setQuestions(questions ?? [])
      } catch (error) {
        setError(error)
      }

      setQaLoading(false)
    }

    retrieveQuestionsAndAnswers()
    return () => {
      active = false
    }
  }, [scriptId])

  const classes = useStyles(props)

  if (loading) {
    return (
      <section className={clsx(classes.root, classes.progressRoot)}>
        <CircularProgress className={classes.progress} />
      </section>
    )
  }

  // For a finalized survey, we simulate an error to fake out enumeration attacks.
  // Folks who are paying attention will notice the difference but this isn’t for them.
  if (final) {
    return (
      <section className={classes.root}>
        <ErrorResponse error={{ response: { status: 404, json: () => ({ message: 'Not found: No such script' }) } }} />
      </section>
    )
  }

  return (
    <section className={classes.root}>
      {script && (
        <h3 className={classes.title}>
          Survey for <em>{script.title}</em>
          <small className={classes.message}>All survey questions are required.</small>
        </h3>
      )}

      {error && <ErrorResponse error={error} />}

      <ol className={classes.list}>
        {questions.map(({ code, prompt, type, options }) => (
          <li key={code} className={classes.item}>
            <Question
              className={classes.question}
              code={code}
              prompt={prompt}
              type={type}
              options={options}
              value={answers[code]}
              setValue={answerHandlers[code]}
            />
          </li>
        ))}
      </ol>

      {questions.length > 1 && (
        <section className={classes.buttonBar}>
          <Button
            className={clsx(classes.button, classes.save)}
            color="primary"
            variant="contained"
            onClick={handleSave}
          >
            Save & Finish Later
          </Button>

          <Button
            className={clsx(classes.button, classes.submit)}
            endIcon={<Avatar className={classes.avatar} alt="zoovatar" src={avatar} />}
            variant="contained"
            disabled={incomplete}
            onClick={handleSubmit}
          >
            Submit Final Feedback
          </Button>
        </section>
      )}

      <section className={clsx(classes.message, !incomplete && classes.hidden)}>
        All questions must be answered in order to submit the final version.
      </section>
    </section>
  )
}

export default Survey

import React, { useState, useEffect, useContext } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'

import CloseIcon from '@material-ui/icons/Close'

import {
  getMe,
  patchMe,
  getAuthoredScripts,
  getFinishedScripts,
  resetPassword,
  user,
  getDemographics,
  putDemographics
} from './api'

import { UpdateContext } from './app/contexts'
import { dirtyArray, dirtyBool, dirtyValue, emptyArray, emptyBool } from './dirty'

import Disclosure from './Disclosure'
import ErrorResponse from './ErrorResponse'
import Profile from './Profile'
import ScriptList from './ScriptList'
import TitledBorder from './TitledBorder'

const SCRIPT_LIST_GUTTER = 1

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column'
    },

    title: {
      color: '#0a0a0a',
      fontSize: theme.typography.pxToRem(31),
      fontWeight: theme.typography.fontWeightRegular,
      margin: theme.spacing(2, 0),
      textAlign: 'center'
    },

    // The styles here should be coordinated with the same class in Profile.
    incomplete: {
      color: theme.palette.error.main,
      fontStyle: 'italic',
      fontWeight: theme.typography.fontWeightBold
    },

    scriptListContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: `${theme.spacing(SCRIPT_LIST_GUTTER * 2)}px`,
      margin: theme.spacing(4, 0, 1),

      [theme.breakpoints.up('sm')]: {
        flexWrap: 'nowrap'
      }
    },

    list: {
      flexBasis: '100%',

      [theme.breakpoints.up('sm')]: {
        flexBasis: 0,
        flexGrow: 1
      }
    },

    demographicsContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: `${theme.spacing(1)}px`
    },

    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },

    messageContainer: {
      display: 'flex',
      margin: theme.spacing(1, 0, 2),
      opacity: 1.0,
      transition: theme.transitions.create('opacity')
    },

    ineligible: {
      justifyContent: 'flex-end',
      margin: theme.spacing(2, 0, 1)
    },

    hidden: {
      opacity: 0.0
    },

    inlineProgress: {
      color: 'currentColor',
      marginLeft: theme.spacing(1)
    }
  }),
  {
    name: 'User'
  }
)

const ResetPasswordSnackbar = props => {
  const { open, handleClose } = props
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      message="Password reset email sent"
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  )
}

ResetPasswordSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}

const User = props => {
  const currentUsername = user()
  const [currentUser, setCurrentUser] = useState()

  const {
    demographics_complete: demographicsComplete,
    birth_year: currentBirthYear,
    postal_code: currentPostalCode,
    country: currentCountry,
    urbanicity: currentUrbanicity,
    gender: currentGender,
    race: currentRace,
    education: currentEducation,
    sexuality: currentSexuality,
    relationship_status: currentRelationshipStatus,
    occupation: currentOccupation,
    film_career_aspiration: currentFilmCareerAspiration,
    film_career_current: currentFilmCareerCurrent,
    parental_status: currentParentalStatus,
    household_income_range: currentHouseholdIncomeRange,
    first_to_see: currentFirstToSee,
    relies_on_reviews: currentReliesOnReviews,
    genre_preferences: currentGenrePreferences,
    outside_script_reading: currentOutsideScriptReading,
    venue_preference: currentVenuePreference,
    media_type_ranking: currentMediaTypeRanking,
    streaming_preference: currentStreamingPreference,
    tv_or_movie_weekly_hours: currentTvOrMovieWeeklylHours,
    streaming_services: currentStreamingServices,
    pre_covid_theater_attendance: currentNonPandemicTheaterAttendance,
    sms_phone_number: currentSmsPhoneNumber
  } = currentUser ?? {}

  const [birthYear, setBirthYear] = useState()
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState('')
  const [urbanicity, setUrbanicity] = useState('')
  const [gender, setGender] = useState('')
  const [race, setRace] = useState([])
  const [education, setEducation] = useState('')
  const [sexuality, setSexuality] = useState('')
  const [relationshipStatus, setRelationshipStatus] = useState('')
  const [occupation, setOccupation] = useState('')
  const [filmCareerAspiration, setFilmCareerAspiration] = useState()
  const [filmCareerCurrent, setFilmCareerCurrent] = useState()
  const [parentalStatus, setParentalStatus] = useState('')
  const [householdIncomeRange, setHouseholdIncomeRange] = useState('')
  const [firstToSee, setFirstToSee] = useState()
  const [reliesOnReviews, setReliesOnReviews] = useState()
  const [genrePreferences, setGenrePreferences] = useState([])
  const [outsideScriptReading, setOutsideScriptReading] = useState('')
  const [venuePreference, setVenuePreference] = useState('')
  const [mediaTypeRanking, setMediaTypeRanking] = useState('')
  const [streamingPreference, setStreamingPreference] = useState('')
  const [tvOrMovieWeeklyHours, setTvOrMovieWeeklyHours] = useState('')
  const [streamingServices, setStreamingServices] = useState([])
  const [nonPandemicTheaterAttendance, setNonPandemicTheaterAttendance] = useState('')
  const [smsPhoneNumber, setCurrentSmsPhoneNumber] = useState('')

  const [error, setError] = useState(null)
  const [loadingMe, setLoadingMe] = useState(true)
  const [savingMe, setSavingMe] = useState(false)
  const [resettingPassword, setResettingPassword] = useState(false)
  const [resetPasswordSnackbarOpen, setResetPasswordSnackbarOpen] = useState(false)

  const { scripts: reloadScripts } = useContext(UpdateContext)

  const handleSaveMe = event => {
    event.preventDefault()
    const saveMe = async () => {
      try {
        const [, newDemographics] = await Promise.all([
          patchMe({ sms_phone_number: smsPhoneNumber }),

          putDemographics({
            birth_year: birthYear,
            postal_code: postalCode,
            country,
            urbanicity,
            gender,
            race,
            education,
            sexuality,
            relationship_status: relationshipStatus,
            occupation,
            film_career_aspiration: filmCareerAspiration,
            film_career_current: filmCareerCurrent,
            parental_status: parentalStatus,
            household_income_range: householdIncomeRange,
            first_to_see: firstToSee,
            relies_on_reviews: reliesOnReviews,
            genre_preferences: genrePreferences,
            outside_script_reading: outsideScriptReading,
            venue_preference: venuePreference,
            media_type_ranking: mediaTypeRanking,
            streaming_preference: streamingPreference,
            tv_or_movie_weekly_hours: tvOrMovieWeeklyHours,
            streaming_services: streamingServices,
            pre_covid_theater_attendance: nonPandemicTheaterAttendance
          })
        ])

        setCurrentUser(currentUser =>
          currentUser ? { ...currentUser, ...newDemographics, sms_phone_number: smsPhoneNumber } : currentUser
        )

        setSavingMe(false)
      } catch (error) {
        setError(error)
        setSavingMe(false)
      }
    }

    setSavingMe(true)
    saveMe()
  }

  const handleRevertMe = event => {
    setCurrentUser({ ...currentUser }) // Spread creates a new object and will call the useEffect.
  }

  const handleResetPassword = async event => {
    if (!currentUser) {
      return
    }

    setResettingPassword(true)
    await resetPassword(currentUser.email)
    setResettingPassword(false)
    setResetPasswordSnackbarOpen(true)
  }

  useEffect(() => {
    let active = true
    const retrieveMe = async () => {
      try {
        const [me, demographics] = await Promise.all([getMe(), getDemographics()])
        if (active) {
          setCurrentUser({ ...me, ...demographics })
          setLoadingMe(false)
        }
      } catch (error) {
        setError(error)
        setLoadingMe(false)
      }
    }

    setLoadingMe(true)
    retrieveMe()
    return () => {
      active = false
    }
  }, [currentUsername])

  useEffect(() => {
    setBirthYear(currentBirthYear)
    setPostalCode(currentPostalCode ?? '')
    setCountry(currentCountry ?? '')
    setUrbanicity(currentUrbanicity ?? '')
    setGender(currentGender ?? '')
    setRace(currentRace ?? [])
    setEducation(currentEducation ?? '')
    setSexuality(currentSexuality ?? '')
    setRelationshipStatus(currentRelationshipStatus ?? '')
    setOccupation(currentOccupation ?? '')
    setFilmCareerAspiration(currentFilmCareerAspiration)
    setFilmCareerCurrent(currentFilmCareerCurrent)
    setParentalStatus(currentParentalStatus ?? '')
    setHouseholdIncomeRange(currentHouseholdIncomeRange ?? '')
    setFirstToSee(currentFirstToSee)
    setReliesOnReviews(currentReliesOnReviews)
    setGenrePreferences(currentGenrePreferences ?? [])
    setOutsideScriptReading(currentOutsideScriptReading ?? '')
    setVenuePreference(currentVenuePreference ?? '')
    setMediaTypeRanking(currentMediaTypeRanking ?? '')
    setStreamingPreference(currentStreamingPreference ?? '')
    setTvOrMovieWeeklyHours(currentTvOrMovieWeeklylHours ?? '')
    setStreamingServices(currentStreamingServices ?? [])
    setNonPandemicTheaterAttendance(currentNonPandemicTheaterAttendance ?? '')
    setCurrentSmsPhoneNumber(currentSmsPhoneNumber ?? '')
  }, [
    currentUser,
    currentBirthYear,
    currentPostalCode,
    currentCountry,
    currentUrbanicity,
    currentGender,
    currentRace,
    currentEducation,
    currentSexuality,
    currentRelationshipStatus,
    currentOccupation,
    currentFilmCareerAspiration,
    currentFilmCareerCurrent,
    currentParentalStatus,
    currentHouseholdIncomeRange,
    currentFirstToSee,
    currentReliesOnReviews,
    currentGenrePreferences,
    currentOutsideScriptReading,
    currentVenuePreference,
    currentMediaTypeRanking,
    currentStreamingPreference,
    currentTvOrMovieWeeklylHours,
    currentStreamingServices,
    currentNonPandemicTheaterAttendance,
    currentSmsPhoneNumber
  ])

  const dirty =
    dirtyValue(currentBirthYear, birthYear) ||
    dirtyValue(currentPostalCode, postalCode) ||
    dirtyValue(currentCountry, country) ||
    dirtyValue(currentUrbanicity, urbanicity) ||
    dirtyValue(currentGender, gender) ||
    dirtyArray(currentRace, race) ||
    dirtyValue(currentEducation, education) ||
    dirtyValue(currentSexuality, sexuality) ||
    dirtyValue(currentRelationshipStatus, relationshipStatus) ||
    dirtyValue(currentOccupation, occupation) ||
    dirtyBool(currentFilmCareerAspiration, filmCareerAspiration) ||
    dirtyBool(currentFilmCareerCurrent, filmCareerCurrent) ||
    dirtyValue(currentParentalStatus, parentalStatus) ||
    dirtyValue(currentHouseholdIncomeRange, householdIncomeRange) ||
    dirtyBool(currentFirstToSee, firstToSee) ||
    dirtyBool(currentReliesOnReviews, reliesOnReviews) ||
    dirtyArray(currentGenrePreferences, genrePreferences) ||
    dirtyValue(currentOutsideScriptReading, outsideScriptReading) ||
    dirtyValue(currentVenuePreference, venuePreference) ||
    dirtyValue(currentMediaTypeRanking, mediaTypeRanking) ||
    dirtyValue(currentStreamingPreference, streamingPreference) ||
    dirtyValue(currentTvOrMovieWeeklylHours, tvOrMovieWeeklyHours) ||
    dirtyArray(currentStreamingServices, streamingServices) ||
    dirtyValue(currentNonPandemicTheaterAttendance, nonPandemicTheaterAttendance) ||
    dirtyValue(currentSmsPhoneNumber, smsPhoneNumber)

  const filled =
    birthYear &&
    postalCode &&
    country &&
    urbanicity &&
    gender &&
    !emptyArray(race) &&
    education &&
    sexuality &&
    relationshipStatus &&
    occupation &&
    !emptyBool(filmCareerAspiration) &&
    !emptyBool(filmCareerCurrent) &&
    parentalStatus &&
    householdIncomeRange &&
    !emptyBool(firstToSee) &&
    !emptyBool(reliesOnReviews) &&
    !emptyArray(genrePreferences) &&
    outsideScriptReading &&
    venuePreference &&
    mediaTypeRanking &&
    streamingPreference &&
    tvOrMovieWeeklyHours &&
    !emptyArray(streamingServices) &&
    nonPandemicTheaterAttendance

  const sameAsCurrent = !dirty
  const incomplete = demographicsComplete && !filled
  const cannotSave = savingMe || sameAsCurrent || incomplete

  const classes = useStyles(props)
  return (
    <article className={classes.root}>
      <h1 className={classes.title}>This is You, {currentUsername}</h1>

      <section className={classes.scriptListContainer}>
        <Disclosure className={classes.list} title="Your Scripts" initiallyClosed>
          <ScriptList getter={getAuthoredScripts} reload={reloadScripts /* Update trickery. */} />
        </Disclosure>

        <Disclosure className={classes.list} title="Finished Reads" initiallyClosed>
          <ScriptList getter={getFinishedScripts} />
        </Disclosure>
      </section>

      <TitledBorder
        className={classes.demographicsContainer}
        title="Demographic Information"
        help={
          <span>
            Please answer all questions below to be eligible for payment. Questions in{' '}
            <span className={classes.incomplete}>bold, red, italicized text with an asterisk (*)</span> indicate missing
            answers.
          </span>
        }
      >
        {error && (
          <section>
            <p>Sorry, it looks like we’ve run into a problem—but it’s not you; it’s us.</p>
            <ErrorResponse error={error} />
          </section>
        )}

        {loadingMe ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSaveMe}>
            <Profile
              birthYear={birthYear}
              setBirthYear={setBirthYear}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              country={country}
              setCountry={setCountry}
              urbanicity={urbanicity}
              setUrbanicity={setUrbanicity}
              gender={gender}
              setGender={setGender}
              race={race}
              setRace={setRace}
              education={education}
              setEducation={setEducation}
              sexuality={sexuality}
              setSexuality={setSexuality}
              relationshipStatus={relationshipStatus}
              setRelationshipStatus={setRelationshipStatus}
              occupation={occupation}
              setOccupation={setOccupation}
              filmCareerAspiration={filmCareerAspiration}
              setFilmCareerAspiration={setFilmCareerAspiration}
              filmCareerCurrent={filmCareerCurrent}
              setFilmCareerCurrent={setFilmCareerCurrent}
              parentalStatus={parentalStatus}
              setParentalStatus={setParentalStatus}
              householdIncomeRange={householdIncomeRange}
              setHouseholdIncomeRange={setHouseholdIncomeRange}
              firstToSee={firstToSee}
              setFirstToSee={setFirstToSee}
              reliesOnReviews={reliesOnReviews}
              setReliesOnReviews={setReliesOnReviews}
              genrePreferences={genrePreferences}
              setGenrePreferences={setGenrePreferences}
              outsideScriptReading={outsideScriptReading}
              setOutsideScriptReading={setOutsideScriptReading}
              venuePreference={venuePreference}
              setVenuePreference={setVenuePreference}
              mediaTypeRanking={mediaTypeRanking}
              setMediaTypeRanking={setMediaTypeRanking}
              streamingPreference={streamingPreference}
              setStreamingPreference={setStreamingPreference}
              tvOrMovieWeeklyHours={tvOrMovieWeeklyHours}
              setTvOrMovieWeeklyHours={setTvOrMovieWeeklyHours}
              streamingServices={streamingServices}
              setStreamingServices={setStreamingServices}
              nonPandemicTheaterAttendance={nonPandemicTheaterAttendance}
              setNonPandemicTheaterAttendance={setNonPandemicTheaterAttendance}
              textMessage={smsPhoneNumber}
              setTextMessage={setCurrentSmsPhoneNumber}
            />

            <section className={clsx(classes.messageContainer, !sameAsCurrent && !incomplete && classes.hidden)}>
              <span role="img" aria-label="note">
                👉🏽
              </span>
              {' '}
              {incomplete
                ? 'To save this form, ensure that nothing is blank or unfilled.'
                : [
                    'There is nothing to Revert nor Save as of now',
                    'because you haven’t changed anything since the last save.'
                  ].join(' ')}
            </section>

            <section className={classes.buttonContainer}>
              <span>
                <Button color="secondary" size="large" disabled={sameAsCurrent} onClick={handleRevertMe}>
                  Revert Me
                </Button>

                <Button color="primary" size="large" disabled={!currentUser} onClick={handleResetPassword}>
                  Reset my Password
                  {resettingPassword && <CircularProgress className={classes.inlineProgress} size={16} />}
                </Button>
              </span>

              <Button variant="contained" color="primary" size="large" type="submit" disabled={cannotSave}>
                Save Me
                {savingMe && <CircularProgress className={classes.inlineProgress} size={16} />}
              </Button>
            </section>

            <section className={clsx(classes.messageContainer, classes.ineligible)}>
              Readers with incomplete demographics forms are ineligible for reading and payment.
            </section>
          </form>
        )}
      </TitledBorder>

      <ResetPasswordSnackbar
        open={resetPasswordSnackbarOpen}
        handleClose={(event, reason) => setResetPasswordSnackbarOpen(false)}
      />
    </article>
  )
}

export default User

import { user } from './api'

const STATUS_DELETED = 'DELETED'

const RESPONSE_STATUS_NEW = 'new'

const authorReview = script => script.author === user()
const scriptIsDeleted = script => script.status === STATUS_DELETED
const unread = readerStatus => [RESPONSE_STATUS_NEW].includes(readerStatus)
const unfinished = readerStatus => [RESPONSE_STATUS_NEW, 'in progress'].includes(readerStatus)
const needsSurvey = readerStatus => ['done reading', 'quit reading'].includes(readerStatus)

const valueFromPropsOrMatch = (key, props) => props[key] || (props.match && props.match.params[key])
const scriptIdFromProps = props => valueFromPropsOrMatch('scriptId', props)

export { STATUS_DELETED, authorReview, scriptIsDeleted, scriptIdFromProps, unread, unfinished, needsSurvey }

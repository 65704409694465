import React, { useState, useEffect } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import TextField from '@material-ui/core/TextField'

import { findUsers, findGroups } from './api'
import debounce from './debounce'
import GENRES from './models/genres'
import MEDIA, { MediaChip } from './models/media'
import WARNINGS from './models/warnings'

import avatar from './assets/avatar.png'

import TitledBorder from './TitledBorder'
import UserBadge from './UserBadge'
import CheckboxMultiselect from './form/CheckboxMultiselect'

const GROUP_ELEMENT_WIDTH = 36
const USER_ELEMENT_WIDTH = 44

const useStyles = makeStyles(
  theme => ({
    userSearch: {
      display: 'flex'
    },

    loglineField: {
      marginBottom: theme.spacing(2.25),
      marginTop: theme.spacing(2.25)
    },

    mediaChip: {
      border: 'none',
      fontSize: theme.spacing(2)
    },

    userQueryField: {
      flexGrow: 1,
      marginBottom: theme.spacing(2),
      maxWidth: `calc(100% - ${USER_ELEMENT_WIDTH * 8}px)` // * 8 because that’s the Material UI spacing unit.
    },

    userQueryResults: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(2),
      maxWidth: theme.spacing(USER_ELEMENT_WIDTH),

      '& > ul': {
        listStyle: 'none',
        margin: 0,
        padding: 0
      },

      '& > ul > li': {
        marginBottom: theme.spacing(2)
      }
    },

    userBadge: {
      width: '100%'
    },

    userButton: {
      padding: theme.spacing(1.5, 2)
    },

    userGroup: {
      border: '1px solid rgba(10, 10, 10, 0.25)',
      display: 'flex',
      flexWrap: 'wrap',
      margin: theme.spacing(0, 0, 2, 0),
      padding: theme.spacing(2)
    },

    readerTile: {
      alignItems: 'start',
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(0, 2),
      padding: theme.spacing(1.5, 2)
    },

    userTile: {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      minWidth: theme.spacing(USER_ELEMENT_WIDTH),
      maxWidth: theme.spacing(USER_ELEMENT_WIDTH)
    },

    groupBadge: {
      alignItems: 'center',
      display: 'flex',
      flexGrow: 1,
      fontStyle: 'italic',
      fontWeight: 'bold',
      '& > *:first-child': {
        marginRight: theme.spacing(1)
      }
    },

    groupTile: {
      border: `2px solid ${theme.palette.secondary.main}`,
      minWidth: theme.spacing(GROUP_ELEMENT_WIDTH),
      maxWidth: theme.spacing(GROUP_ELEMENT_WIDTH)
    },

    removeButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      fontSize: theme.typography.pxToRem(12),
      minWidth: theme.spacing(9),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    }
  }),
  {
    name: 'ScriptProperties'
  }
)

const FIND_USERS_DEBOUNCE_TIME = 250
const findUsersDebounced = debounce(findUsers, FIND_USERS_DEBOUNCE_TIME)
const findGroupsDebounced = debounce(findGroups, FIND_USERS_DEBOUNCE_TIME)

const ScriptProperties = props => {
  const [userQuery, setUserQuery] = useState('')
  const [userQueryInProgress, setUserQueryInProgress] = useState(false)
  const [matchingUsers, setMatchingUsers] = useState([])

  const [groupQuery, setGroupQuery] = useState('')
  const [groupQueryInProgress, setGroupQueryInProgress] = useState(false)
  const [matchingGroups, setMatchingGroups] = useState([])

  const {
    logline,
    setLogline,
    genres,
    setGenres,
    medium,
    setMedium: setMediumProp,
    warnings,
    setWarnings,
    group,
    setGroup: setGroupProp,
    groups,
    setGroups: setGroupsProp,
    showGroups
  } = props

  const setMedium = setMediumProp || (() => {})
  const setGroup = setGroupProp || (() => {})
  const setGroups = setGroupsProp || (() => {})

  const handleLoglineChange = event => setLogline(event.target.value)
  const handleMediumChange = event => setMedium(event.target.value)
  const handleUserQueryChange = event => setUserQuery(event.target.value)
  const handleGroupQueryChange = event => setGroupQuery(event.target.value)

  const addUserToGroup = user => event => {
    setGroup([...group, user])
    setMatchingUsers([])
    setUserQuery('')
  }

  const addGroupToGroups = group => event => {
    setGroups([...groups, group])
    setMatchingGroups([])
    setGroupQuery('')
  }

  const removeUserFromGroup = user => event => setGroup(group.filter(currentUser => user !== currentUser))
  const removeGroupFromGroups = group => event => setGroups(groups.filter(currentGroup => group !== currentGroup))

  useEffect(() => {
    let active = true

    if (!userQuery) {
      setUserQueryInProgress(false)
      return
    }

    const doUserQuery = async () => {
      setUserQueryInProgress(true)
      const response = await findUsersDebounced(
        userQuery,
        group.map(groupMember => groupMember.username)
      )
      if (active) {
        setMatchingUsers(response)
        setUserQueryInProgress(false)
      }
    }

    doUserQuery()
    return () => {
      active = false
    }
  }, [userQuery, group])

  useEffect(() => {
    let active = true

    if (!groupQuery) {
      setGroupQueryInProgress(false)
      return
    }

    const doGroupQuery = async () => {
      setGroupQueryInProgress(true)
      const response = await findGroupsDebounced(groupQuery, groups)
      if (active) {
        setMatchingGroups(response)
        setGroupQueryInProgress(false)
      }
    }

    doGroupQuery()
    return () => {
      active = false
    }
  }, [groupQuery, groups])

  const classes = useStyles(props)
  return (
    <>
      <TextField
        className={classes.loglineField}
        fullWidth
        multiline
        variant="outlined"
        label="Logline"
        name="logline"
        value={logline}
        onChange={handleLoglineChange}
        InputLabelProps={{ shrink: true }}
      />

      <TitledBorder title="Genres" help="Check all that apply, but as few as possible.">
        <CheckboxMultiselect all={GENRES} current={genres} setCurrent={setGenres} ariaLabel="genres" />
      </TitledBorder>

      <TitledBorder title="Medium">
        <RadioGroup row={true} aria-label="medium" name="medium" value={medium} onChange={handleMediumChange}>
          {Object.keys(MEDIA).map(mediumKey => (
            <FormControlLabel
              key={mediumKey}
              value={mediumKey}
              control={<Radio />}
              label={<MediaChip className={classes.mediaChip} medium={MEDIA[mediumKey]} />}
            />
          ))}
        </RadioGroup>
      </TitledBorder>

      <TitledBorder title="Warnings">
        <CheckboxMultiselect all={WARNINGS} current={warnings} setCurrent={setWarnings} ariaLabel="warnings" />
      </TitledBorder>

      <TitledBorder
        title="Readers"
        help={`Please list the Zoodǐker users who will be allowed to see this draft. Keep the list empty to allow
              anyone to read it.`}
      >
        <div className={classes.userSearch}>
          <TextField className={classes.userQueryField} value={userQuery} onChange={handleUserQueryChange} />

          <div className={classes.userQueryResults}>
            {userQueryInProgress ? (
              <CircularProgress />
            ) : (
              <ul>
                {matchingUsers.map(user => (
                  <li key={user.username}>
                    <Button
                      className={classes.userButton}
                      color="primary"
                      fullWidth
                      variant="contained"
                      onClick={addUserToGroup(user)}
                    >
                      <UserBadge className={classes.userBadge} user={user} />
                    </Button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className={classes.userGroup}>
          {group.length === 0 ? (
            <em>No group—anyone can read.</em>
          ) : (
            <>
              {group.map(user => (
                <div key={user.username} className={clsx(classes.readerTile, classes.userTile)}>
                  <UserBadge className={classes.userBadge} user={user} />

                  <Button
                    classes={{ containedPrimary: classes.removeButton }}
                    variant="contained"
                    color="primary"
                    aria-label="Remove user from group"
                    onClick={removeUserFromGroup(user)}
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </>
          )}
        </div>
      </TitledBorder>

      {showGroups && (
        <TitledBorder
          title="Reading Groups"
          help={`Please list the Zoodǐker groups who will be allowed to see this draft.`}
        >
          <div className={classes.userSearch}>
            <TextField className={classes.userQueryField} value={groupQuery} onChange={handleGroupQueryChange} />

            <div className={classes.userQueryResults}>
              {groupQueryInProgress ? (
                <CircularProgress />
              ) : (
                <ul>
                  {matchingGroups.map(group => (
                    <li key={group}>
                      <Button
                        className={classes.userButton}
                        color="secondary"
                        fullWidth
                        variant="contained"
                        onClick={addGroupToGroups(group)}
                      >
                        <div className={classes.groupBadge}>
                          <Avatar src={avatar} />
                          {group}
                        </div>
                      </Button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div className={classes.userGroup}>
            {groups.length === 0 ? (
              <em>No groups.</em>
            ) : (
              <>
                {groups.map(group => (
                  <div key={group} className={clsx(classes.readerTile, classes.groupTile)}>
                    <div className={classes.groupBadge}>
                      <Avatar src={avatar} />
                      {group}
                    </div>

                    <Button
                      classes={{ containedPrimary: classes.removeButton }}
                      variant="contained"
                      color="primary"
                      aria-label="Remove group"
                      onClick={removeGroupFromGroups(group)}
                    >
                      Remove
                    </Button>
                  </div>
                ))}
              </>
            )}
          </div>
        </TitledBorder>
      )}
    </>
  )
}

ScriptProperties.propTypes = {
  logline: PropTypes.string.isRequired,
  genres: PropTypes.arrayOf(PropTypes.string).isRequired,
  medium: PropTypes.oneOf([...Object.keys(MEDIA), '']),
  warnings: PropTypes.arrayOf(PropTypes.string).isRequired,
  group: PropTypes.arrayOf(PropTypes.object).isRequired,
  groups: PropTypes.arrayOf(PropTypes.string).isRequired,
  setLogline: PropTypes.func,
  setGenres: PropTypes.func,
  setMedium: PropTypes.func,
  setWarnings: PropTypes.func,
  setGroup: PropTypes.func,
  setGroups: PropTypes.func,
  showGroups: PropTypes.bool
}

export default ScriptProperties

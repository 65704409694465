import constProperty from '../constProperty'

const WARNINGS = {}

// key = internal value; value = display value
constProperty(WARNINGS, 'sex', 'Sex', { enumerable: true })
constProperty(WARNINGS, 'violence', 'Violence', { enumerable: true })
constProperty(WARNINGS, 'sexual violence', 'Sexual Violence', { enumerable: true })
constProperty(WARNINGS, 'scary', 'Scary', { enumerable: true })

constProperty(WARNINGS, 'keys', Object.keys(WARNINGS))

export default WARNINGS

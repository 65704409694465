import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'

const useStyles = makeStyles(
  theme => ({
    fieldset: {
      borderColor: '#cacaca',
      borderStyle: 'solid',
      borderWidth: theme.spacing(0.125),
      margin: theme.spacing(2.25, 0),
      padding: theme.spacing(2.5),
      transition: theme.transitions.create('border'),

      '&:focus-within': {
        borderColor: theme.palette.primary.main
      }
    },

    legend: {
      margin: theme.spacing(0, 0, 0, -0.5),
      padding: theme.spacing(0, 0.5),
      transition: theme.transitions.create('color')
    },

    help: {
      fontSize: theme.typography.pxToRem(14),
      fontStyle: 'italic',
      marginBottom: theme.spacing(2),
      marginTop: 0
    }
  }),
  {
    name: 'TitledBorder'
  }
)

const TitledBorder = props => {
  const { title, help, children, className } = props
  const classes = useStyles(props)
  return (
    <FormControl component="fieldset" className={clsx(classes.fieldset, className)}>
      {title && (
        <FormLabel component="legend" className={classes.legend}>
          {title}
        </FormLabel>
      )}

      {help && <FormHelperText className={classes.help}>{help}</FormHelperText>}

      {children}
    </FormControl>
  )
}

TitledBorder.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node, // Content to display in the title.
  help: PropTypes.node // Content to display as a help message.
}

export default TitledBorder

import React, { useEffect, useMemo } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'

import { OPTION_NONE } from './constants'

const useStyles = makeStyles(
  theme => ({
    root: {
      margin: theme.spacing(2.125, 0, 3.125),
      rowGap: `${theme.spacing(2.25)}px` // Replaces the vertical space removed from checkbox, see below.
    },

    label: {
      alignItems: 'flex-start'
    },

    checkbox: {
      margin: theme.spacing(-1.125, 0) // Remove vertical space to foster top alignment.
    }
  }),
  {
    name: 'CheckboxMultiselect'
  }
)

const CheckboxMultiselect = props => {
  const { className, all, column, current, setCurrent, ariaLabel } = props

  const includesNone = useMemo(() => Object.keys(all).includes(OPTION_NONE), [all])

  const handleChange = event => {
    const { checked, value } = event.target

    // Special handling for OPTION_NONE: Checking this should uncheck everything else.
    if (includesNone && checked && value === OPTION_NONE) {
      if (setCurrent) {
        setCurrent([OPTION_NONE])
        return
      }
    }

    if (current.includes(value)) {
      if (!checked && setCurrent) {
        setCurrent(current.filter(key => key !== value))
      }
    } else {
      if (checked && setCurrent) {
        // OPTION_NONE is mutually exclusive from other items.
        setCurrent([...current, value].filter(key => !includesNone || key !== OPTION_NONE))
      }
    }
  }

  useEffect(() => {
    // The OPTION_NONE item, if present, is mutually exclusive from other items.
    if (!includesNone) {
      return
    }

    if (current.includes(OPTION_NONE) && current.length > 1) {
      setCurrent(current.filter(key => key !== OPTION_NONE))
    }
  }, [includesNone, current, setCurrent])

  const classes = useStyles(props)
  return (
    <FormGroup className={clsx(classes.root, className)} row={!column} aria-label={ariaLabel}>
      {Object.keys(all).map(key => (
        <FormControlLabel
          key={key}
          className={classes.label}
          control={
            <Checkbox
              className={classes.checkbox}
              name={key}
              value={key}
              checked={current.includes(key)}
              onChange={handleChange}
            />
          }
          label={all[key]}
        />
      ))}
    </FormGroup>
  )
}

CheckboxMultiselect.propTypes = {
  className: PropTypes.string,
  all: PropTypes.object.isRequired, // The overall choices as key-label pairs.
  column: PropTypes.bool, // Whether to display the checkboxes as a column.
  current: PropTypes.arrayOf(PropTypes.string).isRequired, // Strings should be keys of the `all` object.
  setCurrent: PropTypes.func,
  ariaLabel: PropTypes.string
}

export default CheckboxMultiselect

/**
 * MinimalButton is a Button component with very minimalist styling.
 */
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

const useStyles = makeStyles(
  theme => ({
    root: {
      justifyContent: 'start',
      lineHeight: '100%',
      minWidth: 'min-content',
      padding: 0,

      '&:hover': {
        backgroundColor: 'initial'
      }
    },

    label: {
      borderBottomColor: theme.palette.grey[500],
      borderBottomStyle: 'dotted',
      borderBottomWidth: theme.spacing(0.125),
      padding: 0,
      transition: theme.transitions.create('border', {
        duration: theme.transitions.duration.complex
      }),

      '&:hover': {
        borderBottomColor: 'currentColor',
        borderBottomStyle: 'solid'
      }
    }
  }),
  {
    name: 'MinimalButton'
  }
)

const MinimalButton = props => {
  const classes = useStyles(props)
  return <Button classes={classes} {...props} />
}

export default MinimalButton

import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

import EmailIcon from '@material-ui/icons/MailOutline'

const useStyles = makeStyles(
  theme => ({
    icon: {
      marginRight: theme.spacing(0.5)
    }
  }),
  {
    name: 'ContactUs'
  }
)

const ContactUs = props => {
  const classes = useStyles(props)
  return (
    <Button color="primary" component="a" href="mailto:admin@zoodiker.com">
      <EmailIcon className={classes.icon} />
      Contact Us
    </Button>
  )
}

export default ContactUs

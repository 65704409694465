import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import Tooltip from '@material-ui/core/Tooltip'

import { useVersion } from './app/hooks'

const useStyles = makeStyles(
  theme => ({
    root: {
      color: theme.palette.primary.main,
      textDecoration: 'none',

      '&:visited': {
        color: theme.palette.primary.main
      },

      '& > h4': {
        alignItems: 'center',
        display: 'flex',
        fontSize: 25,
        fontWeight: 400,
        margin: 0
      }
    },

    icon: {
      fontSize: 22,
      marginRight: theme.spacing(1)
    }
  }),
  {
    name: 'Logo'
  }
)

const Logo = props => {
  const version = useVersion()
  const classes = useStyles(props)

  return (
    <Tooltip arrow title={`v${version.app} build ${version.build}`}>
      <Link to="/" className={classes.root}>
        <h4>
          <span className={classes.icon}>
            <FontAwesomeIcon icon={faPencilAlt} />
          </span>
          Zoodǐker
        </h4>
      </Link>
    </Tooltip>
  )
}

export default Logo

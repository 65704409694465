import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import CircularProgress from '@material-ui/core/CircularProgress'

import { getScript, getAllResponses } from './api'

import { scriptIdFromProps } from './utility'
import { AVERAGE_KEY, calculatePagesPerMinute } from './visualization/visualizationHelpers'

import ReviewerFilter from './visualization/ReviewerFilter'

import PagesPerMinute from './visualization/PagesPerMinute'
import RatingsPerPage from './visualization/RatingsPerPage'
import TimeToPage from './visualization/TimeToPage'

const useStyles = makeStyles(theme => ({
  graphHeader: {
    // Based on Zurb Foundation h3 style.
    fontSize: theme.typography.pxToRem(31),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.4,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(4)
  },

  progressContainer: {
    textAlign: 'center'
  }
}))

const Graph = props => {
  const [script, setScript] = useState(null)
  const [allResponses, setAllResponses] = useState(null)
  const [reviewerVisible, setReviewerVisible] = useState({ [AVERAGE_KEY]: true })

  const scriptId = scriptIdFromProps(props)
  const reviewerKeys = allResponses ? [AVERAGE_KEY, ...Object.keys(allResponses).sort()] : []
  const selectedReviewers = reviewerKeys.filter(reviewer => Boolean(reviewerVisible[reviewer]))

  useEffect(() => {
    const loadScript = async () => {
      setScript(await getScript(scriptId))
    }

    loadScript()
  }, [scriptId])

  useEffect(() => {
    if (!script) {
      return
    }

    const loadAllScriptResponses = async () => {
      const allResponses = await getAllResponses(script)
      Object.keys(allResponses).forEach(reviewer => {
        const reviewerResponses = allResponses[reviewer]
        reviewerResponses.forEach(pageResponse => {
          pageResponse.absoluteDate = new Date(pageResponse.submitted_at)
        })

        reviewerResponses.sort((left, right) => left.absoluteDate - right.absoluteDate)

        const startDate = reviewerResponses[0].absoluteDate
        reviewerResponses.forEach(pageResponse => {
          pageResponse.epoch = pageResponse.absoluteDate - startDate
        })
      })

      calculatePagesPerMinute(allResponses)
      setAllResponses(allResponses)
    }

    loadAllScriptResponses()
  }, [script])

  const classes = useStyles(props)
  return allResponses ? (
    <>
      <h3 className={classes.graphHeader}>Ratings per Page</h3>
      <ReviewerFilter
        allResponses={allResponses}
        reviewerVisible={reviewerVisible}
        setReviewerVisible={setReviewerVisible}
      />

      <RatingsPerPage
        script={script}
        allResponses={allResponses}
        reviewerKeys={reviewerKeys}
        selectedReviewers={selectedReviewers}
      />

      <h3 className={classes.graphHeader}>Pages per Minute</h3>
      <PagesPerMinute
        script={script}
        allResponses={allResponses}
        reviewerKeys={reviewerKeys}
        selectedReviewers={selectedReviewers}
      />

      <h3 className={classes.graphHeader}>Time-to-Page</h3>
      <TimeToPage
        script={script}
        allResponses={allResponses}
        reviewerKeys={reviewerKeys}
        selectedReviewers={selectedReviewers}
      />
    </>
  ) : (
    <div className={classes.progressContainer}>
      <CircularProgress />
    </div>
  )
}

export default Graph

import React, { useCallback, useEffect, useState } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'

import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import StopIcon from '@material-ui/icons/Stop'

import RATINGS, { RATING_COLORS } from '../models/ratings'
import { unfinished } from '../utility'

import PauseDialog from './PauseDialog'
import TermsDialog from './TermsDialog'

const ratingClass = rating => `${rating}Rating`

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100%'
    },

    controlsContainer: {
      alignItems: 'flex-start',
      display: 'flex',
      flexWrap: 'wrap',
      gap: `${theme.spacing(1)}px`,
      justifyContent: 'center'
    },

    controlsLabel: {
      whiteSpace: 'nowrap'
    },

    skipPreviousAdjust: {
      marginLeft: theme.spacing(-1) // To compensate for skip previous icon’s intrinsic margin.
    },

    skipNextAdjust: {
      marginRight: theme.spacing(-1) // To compensate for skip next icon’s intrinsic margin.
    },

    pageSelectContainer: {
      '& > select': {
        paddingBottom: 9, // Meant to match height of Previous Page button exactly.
        paddingTop: 9
      }
    },

    progressContainer: {
      alignItems: 'center',
      display: 'flex',
      flexGrow: 1,
      height: 37, // Meant to exactly match height of buttons.
      justifyContent: 'center'
    },

    comments: {
      flexGrow: 1,
      width: '100%',

      [theme.breakpoints.up('sm')]: {
        width: 'auto'
      }
    },

    advancementContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },

    buttonRow: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',

      '& > button': {
        marginBottom: theme.spacing(0.125),
        marginRight: theme.spacing(0.125)
      },

      '& > button:last-child': {
        marginRight: 0
      },

      [theme.breakpoints.up('sm')]: {
        width: 'auto'
      }
    },

    ratingButtonRow: {
      '& > button': {
        flexGrow: 1
      }
    },

    ratingLabel: {
      fontSize: theme.typography.pxToRem(14)
    },

    ratingPrompt: {
      margin: theme.spacing(1.75, 0, 0.5),
      textAlign: 'center'
    },

    ratingRadioContainer: {
      margin: theme.spacing(0.75, 2)
    },

    ratingRadioGroup: {
      flexDirection: 'row',
      justifyContent: 'center',

      [theme.breakpoints.up('sm')]: {
        flexBasis: 'auto',
        flexDirection: 'column'
      }
    },

    ratingRadio: {
      marginBottom: theme.spacing(-0.25),
      marginRight: theme.spacing(0.25),
      padding: theme.spacing(0.5)
    },

    ...Object.fromEntries(
      Object.keys(RATING_COLORS).map(rating => [
        ratingClass(rating),
        {
          backgroundColor: RATING_COLORS[rating].backgroundColor,
          color: RATING_COLORS[rating].color,

          '&:hover': {
            backgroundColor: RATING_COLORS[rating].highlightBackgroundColor
          }
        }
      ])
    ),

    abandon: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    },

    abandonContainer: {
      alignItems: 'center',
      display: 'flex',
      marginTop: theme.spacing(2),
      '& > div:last-child': {
        marginLeft: theme.spacing(2)
      }
    }
  }),
  {
    name: 'UnfinishedReview'
  }
)

const TIMEOUT_MILLISECONDS = 3 * 60 * 1000

const UnfinishedReview = props => {
  const {
    responseStatus,
    pageNumber,
    setPageNumber,
    pageInProgress,
    minimumReadingTimeLapsed,
    replacedResponseStatus,
    loading,
    showTerms,
    agreeToTerms,
    onLastPage,
    timerPage,
    abandon,
    availablePages,
    ratePage,
    comment,
    updateComment,
    rating,
    updateRating
  } = props

  // The firstEntry state is a one-way variable that is only true upon initial mount.
  // (read on to see why we are tracking this)
  const [firstEntry, setFirstEntry] = useState(true)

  const [pauseDialogVisible, setPauseDialogVisible] = useState(false)

  const readerMayProceed = !showTerms && !loading && minimumReadingTimeLapsed
  const readerIsUnfinished = unfinished(responseStatus.status)

  // We need to memoize this so that PauseDialog effects don’t spuriously fire just because
  // this component re-renders (and redefines this function).
  const handleClosePauseDialog = useCallback((event, reason) => {
    if (reason !== 'backdropClick') {
      setPauseDialogVisible(false)
    }
  }, [])

  useEffect(() => {
    // If we got here because the pause dialog was opened, then we do nothing.
    // Ditto if honesty terms have not yet been agreed to.
    if (pauseDialogVisible || showTerms) {
      return
    }

    let timeout = setTimeout(() => {
      setPauseDialogVisible(true)
    }, TIMEOUT_MILLISECONDS)

    return () => {
      clearTimeout(timeout)
    }
  }, [pageNumber, pauseDialogVisible, comment /* Comment changes also restart the timer. */, showTerms])

  useEffect(() => {
    if (firstEntry && !showTerms) {
      // Immediate-pause-popup check: we have just entered the page and the user has
      // already checked all of the honesty boxes.
      setPauseDialogVisible(true)
    }
  }, [firstEntry, showTerms, pageNumber])

  useEffect(() => {
    // We track “first entry” so that, if `showTerms` changes after the fact, we don’t
    // spuriously open the pause popup.
    setFirstEntry(false)
  }, [])

  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      <div className={classes.controlsContainer}>
        <Button
          classes={{ label: classes.controlsLabel }}
          variant="contained"
          color="primary"
          disabled={loading || pageNumber <= 1}
          onClick={() => setPageNumber(pageNumber - 1)}
        >
          <SkipPreviousIcon className={classes.skipPreviousAdjust} />
          Previous Page
        </Button>

        <FormControl variant="outlined">
          <Select
            native
            className={classes.pageSelectContainer}
            disabled={loading}
            value={pageNumber}
            onChange={event => setPageNumber(+event.target.value)}
          >
            {availablePages().map(pageNumber => (
              <option key={pageNumber} value={pageNumber}>
                {pageNumber}
              </option>
            ))}
          </Select>
        </FormControl>

        {loading ? (
          <div className={classes.progressContainer}>
            <CircularProgress size={24} />
          </div>
        ) : (
          <TextField
            className={classes.comments}
            disabled={!readerMayProceed}
            multiline
            variant="outlined"
            minRows={5}
            placeholder={
              readerMayProceed ? 'No comment' : 'Please agree to the statement on the right before proceeding.'
            }
            value={comment ?? ''}
            onChange={event => updateComment(event.target.value)}
          />
        )}

        {pageNumber === pageInProgress ? (
          <div className={classes.advancementContainer}>
            <div className={clsx(classes.buttonRow, classes.ratingButtonRow)}>
              {RATINGS.keys.map(rating => (
                <Button
                  key={rating}
                  variant="contained"
                  className={classes[ratingClass(rating)]}
                  disabled={!readerMayProceed}
                  onClick={
                    /* We withhold the onClick if the reader can’t proceed yet so that a savvy user doesn’t just hack-to-enable */
                    readerMayProceed ? () => ratePage(rating) : null
                  }
                >
                  <span className={classes.ratingLabel}>{RATINGS[rating]}</span>
                </Button>
              ))}
            </div>

            <p className={classes.ratingPrompt}>This is how I feel about turning the page.</p>
          </div>
        ) : (
          <>
            <FormControl className={classes.ratingRadioContainer} component="fieldset">
              <RadioGroup
                className={classes.ratingRadioGroup}
                aria-label="rating"
                name="rating"
                value={rating || ''}
                onChange={event => updateRating(event.target.value)}
              >
                {RATINGS.keys.map(rating => (
                  <FormControlLabel
                    key={rating}
                    value={rating}
                    control={
                      <Radio color="primary" disabled={loading} classes={{ colorPrimary: classes.ratingRadio }} />
                    }
                    label={<span className={classes.ratingLabel}>{RATINGS[rating]}</span>}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            <div className={classes.buttonRow}>
              <Button
                classes={{ label: classes.controlsLabel }}
                variant="contained"
                color="primary"
                disabled={loading || onLastPage()}
                onClick={() => setPageNumber(pageNumber + 1)}
              >
                Next Page
                <SkipNextIcon className={classes.skipNextAdjust} />
              </Button>

              {readerIsUnfinished && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={() => setPageNumber(pageInProgress)}
                >
                  Resume
                </Button>
              )}
            </div>
          </>
        )}
      </div>

      {readerIsUnfinished && (
        <div className={classes.abandonContainer}>
          <Button
            classes={{ containedPrimary: classes.abandon }}
            variant="contained"
            color="primary"
            disabled={!readerMayProceed || loading || (!minimumReadingTimeLapsed && pageNumber === pageInProgress)}
            onClick={abandon}
          >
            <StopIcon />
            Abandon
          </Button>

          {replacedResponseStatus && pageNumber < timerPage && (
            <div>You have read an earlier version of this script. The timer is disabled until page {timerPage}.</div>
          )}
        </div>
      )}

      <PauseDialog open={pauseDialogVisible} onClose={handleClosePauseDialog} pageNumber={pageNumber} />
      <TermsDialog open={showTerms} onAgree={agreeToTerms} />
    </div>
  )
}

UnfinishedReview.propTypes = {
  responseStatus: PropTypes.object.isRequired,
  pageNumber: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  pageInProgress: PropTypes.number.isRequired,
  minimumReadingTimeLapsed: PropTypes.bool.isRequired,
  replacedResponseStatus: PropTypes.object,
  loading: PropTypes.bool,
  showTerms: PropTypes.bool, // Whether the component should display the terms dialog.
  agreeToTerms: PropTypes.func, // Function to call when user has agreed to terms.
  onLastPage: PropTypes.func.isRequired,
  timerPage: PropTypes.number.isRequired,
  abandon: PropTypes.func,
  availablePages: PropTypes.func.isRequired,
  ratePage: PropTypes.func.isRequired,
  comment: PropTypes.string,
  updateComment: PropTypes.func.isRequired,
  rating: PropTypes.oneOf(RATINGS.keys),
  updateRating: PropTypes.func.isRequired
}

export default UnfinishedReview

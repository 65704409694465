import constProperty from '../constProperty'

const RESPONSE_CATEGORIES = {}

// key = internal value; value = display value
constProperty(RESPONSE_CATEGORIES, 'Beginning', 'Beginning', { enumerable: true })
constProperty(RESPONSE_CATEGORIES, 'Clarity', 'Clarity', { enumerable: true })
constProperty(RESPONSE_CATEGORIES, 'Dialogue', 'Dialogue', { enumerable: true })
constProperty(RESPONSE_CATEGORIES, 'Execution', 'Execution', { enumerable: true })
constProperty(RESPONSE_CATEGORIES, 'Idea', 'Idea', { enumerable: true })
constProperty(RESPONSE_CATEGORIES, 'Middle', 'Middle', { enumerable: true })
constProperty(RESPONSE_CATEGORIES, 'Originality', 'Originality', { enumerable: true })

constProperty(RESPONSE_CATEGORIES, 'keys', Object.keys(RESPONSE_CATEGORIES))

export default RESPONSE_CATEGORIES

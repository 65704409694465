import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { getNewScripts, getPublicScripts, getScriptsInProgress } from './api'
import { useAppUser } from './app/hooks'
import { submitButton } from './theme'

import ContactUs from './ContactUs'
import Disclosure from './Disclosure'
// import GroupList from './GroupList'  Temporarily withheld for full fidelity to original version.
import ScriptList from './ScriptList'
import Submit from './Submit'

const DASHBOARD_GUTTER = 1

const HEX_WIDTH_ORIGINAL = 726
const HEX_HEIGHT_ORIGINAL = 628 // From original Wikipedia Hexagon SVG.
const HEX_RATIO = HEX_WIDTH_ORIGINAL / HEX_HEIGHT_ORIGINAL

const HEX_WIDTH_PIXELS = 160
const HEX_HEIGHT_PIXELS = HEX_WIDTH_PIXELS / HEX_RATIO
const HEX_WIDTH_SPACING = HEX_WIDTH_PIXELS / 8

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: theme.spacing(-DASHBOARD_GUTTER),

      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(DASHBOARD_GUTTER),

        [theme.breakpoints.up('sm')]: {
          width: `calc(50% - ${theme.spacing(DASHBOARD_GUTTER * 2)}px)`
        },

        [theme.breakpoints.down('xs')]: {
          width: `calc(100% - ${theme.spacing(DASHBOARD_GUTTER * 2)}px)`
        }
      }
    },

    dashboardButtons: {
      marginTop: theme.spacing(4)
    },

    list: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(4)
    },

    groupListContainer: {
      display: 'flex',
      '& > *': {
        width: '50%'
      }
    },

    submit: submitButton(theme),

    hidden: {
      opacity: '0.0 !important', // For some reason this gets overridden by the unpaid class below.
      pointerEvents: 'none'
    },

    unpaid: {
      fontSize: theme.typography.pxToRem(14),
      opacity: 1.0,
      position: 'absolute',
      right: theme.spacing(9),
      textDecoration: 'none',
      top: theme.spacing(1),
      transition: theme.transitions.create('opacity'),
      width: theme.spacing(HEX_WIDTH_SPACING),

      '&:hover': {
        textDecoration: 'none',

        '& > .hex': {
          '& > svg': {
            opacity: 0.85
          }
        }
      },

      '& > .hex': {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        textAlign: 'center',

        '& > svg': {
          opacity: 1.0,
          position: 'absolute',
          transition: theme.transitions.create('opacity')
        },

        '& > span': {
          color: '#fdf752',
          width: theme.spacing(16),
          zIndex: theme.zIndex.tooltip
        }
      }
    }
  }),
  {
    name: 'Dashboard'
  }
)

// Hexagon derived from https://en.wikipedia.org/wiki/File:Hexagon.svg
const UNPAID_MESSAGE = (
  <span className="hex">
    <svg height={HEX_HEIGHT_PIXELS} width={HEX_WIDTH_PIXELS}>
      <polygon
        points="723,314 543,625.769145 183,625.769145 3,314 183,2.230855 543,2.230855 723,314"
        fill="#eb4025"
        transform={`scale(${HEX_WIDTH_PIXELS / HEX_WIDTH_ORIGINAL})`}
      />
    </svg>

    <span>
      Complete your demographics form{' '}
      <b>
        <u>HERE</u>
      </b>{' '}
      to be eligible for paid reads
    </span>
  </span>
)

const Dashboard = props => {
  const appUser = useAppUser()

  const [submitOpen, setSubmitOpen] = useState(false)

  const { demographics_complete: demographicsComplete } = appUser ?? {}

  const handleUploadClose = event => setSubmitOpen(false)
  const handleUploadCompleted = handleUploadClose // As of now, it’s the same.

  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      <div>
        <Disclosure className={classes.list} title="Your Reads in Progress">
          <ScriptList getter={getScriptsInProgress} />
        </Disclosure>

        <Disclosure
          className={classes.list}
          title="Public Writing"
          subtitle="These scripts are shared by community members, with no compensation."
        >
          <ScriptList getter={getPublicScripts} />
        </Disclosure>

        <p className={classes.dashboardButtons}>
          <ContactUs />
        </p>
      </div>
      <div>
        {/* Temporarily withheld for full fidelity to original version.
        <div className={classes.groupListContainer}>
          <Disclosure
            className={classes.list}
            title="Your Groups"
            titleVariant="h5"
          >
            <GroupList />
          </Disclosure>

          <Disclosure
            className={classes.list}
            title="Groups You Lead"
            titleVariant="h5"
          >
            <GroupList />
          </Disclosure>
        </div>
        */}

        <Disclosure
          className={classes.list}
          title={
            <span>
              Scripts for Payment{' '}
              <NavLink className={clsx(classes.unpaid, (!appUser || demographicsComplete) && classes.hidden)} to="/me">
                {UNPAID_MESSAGE}
              </NavLink>
            </span>
          }
          subtitle="You will be paid for these scripts."
        >
          <ScriptList getter={getNewScripts} />
        </Disclosure>
      </div>

      {submitOpen && <Submit open={submitOpen} onClose={handleUploadClose} onSuccess={handleUploadCompleted} />}
    </div>
  )
}

export default Dashboard

/**
 * This module holds constants for values relating to forms.
 */
const TYPE_BOOLEAN = 'boolean'
const TYPE_RATING = 'stars'
const TYPE_FREEFORM = 'freeform'
const TYPE_SELECT = 'select'
const TYPE_MULTISELECT = 'multiselect'

const OPTION_NONE = 'None'

export { TYPE_BOOLEAN, TYPE_RATING, TYPE_FREEFORM, TYPE_SELECT, TYPE_MULTISELECT, OPTION_NONE }

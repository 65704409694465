/**
 * PauseDialog is a component that solicits information about a reader’s pause.
 */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import { postPauseDetection, postPauseReasons } from '../api'
import RATINGS, { RATING_GOOD, RATING_GREAT, RATING_LAGGING, RATING_NEUTRAL } from '../models/ratings'

import RadioSelect from '../form/RadioSelect'

const useStyles = makeStyles(
  theme => ({
    root: {},
    paper: {},

    content: {
      padding: theme.spacing(2, 6, 5, 5),

      '&:first-child': {
        paddingTop: theme.spacing(2) // We reset padding-top here because the default sets it in :first-child.
      }
    },

    disabled: {
      opacity: 0.5
    },

    text: {
      margin: theme.spacing(4, 0, 1.5)
    }
  }),
  {
    name: 'PauseDialog'
  }
)

const REASON_UNRELATED = 'unrelated'
const REASON_BREAK = 'break'

const PAUSE_REASON_OPTIONS = {
  [REASON_UNRELATED]: 'For reasons unrelated to the reading',
  [REASON_BREAK]: 'To take a break from reading'
}

const RESUME_READING_OPTIONS = {
  [RATING_GREAT]: 'Eager',
  [RATING_GOOD]: 'Interested',
  [RATING_NEUTRAL]: 'Neutral',
  [RATING_LAGGING]: 'Dragging myself back'
}

const PAUSE_REASON_VALUES = {
  [REASON_UNRELATED]: 1,
  [REASON_BREAK]: 2
}

const RESUME_READING_VALUES = Object.fromEntries(
  Object.keys(RESUME_READING_OPTIONS).map(key => [key, RATINGS.keys.indexOf(key)])
)

const CLOSE_TIMEOUT_MILLISECONDS = 750

const PauseDialog = props => {
  const { open, onClose, pageNumber } = props

  const { scriptId } = useParams()

  const [enabled, setEnabled] = useState(true)
  const [pauseReason, setPauseReason] = useState('')
  const [resumeReading, setResumeReading] = useState('')

  useEffect(() => {
    // Reset answers whenever the dialog is opened.
    if (open) {
      setPauseReason('')
      setResumeReading('')
      setEnabled(true)

      // We also fire-and-forget a pause detection report on open, if we have a script ID.
      if (scriptId) {
        postPauseDetection({ id: scriptId }, { page: pageNumber - 1 })
      }
    }
  }, [open, pageNumber, scriptId])

  useEffect(() => {
    // This dialog is special: it closes when answers have been provided to both questions.
    if (!open || !pauseReason || !resumeReading || !onClose) {
      return
    }

    // We pause before closing though, just in case the user decides to change their mind.
    let closeTimeout = setTimeout(() => {
      setEnabled(false)

      // Fire and forget
      if (scriptId) {
        postPauseReasons(
          { id: scriptId },
          { reason: PAUSE_REASON_VALUES[pauseReason], feel: RESUME_READING_VALUES[resumeReading], page: pageNumber - 1 }
        )
      }

      onClose()
    }, CLOSE_TIMEOUT_MILLISECONDS)

    return () => {
      clearTimeout(closeTimeout)
    }
  }, [open, pageNumber, pauseReason, resumeReading, onClose, scriptId])

  const classes = useStyles(props)
  return (
    <Dialog classes={{ root: classes.root, paper: classes.paper }} open={Boolean(open)}>
      <DialogContent className={classes.content}>
        <DialogContentText className={classes.text} color="primary">
          <span role="img" aria-label="timeout">
            🕰️
          </span>
            We notice you stopped reading. Please tell us why you paused:
        </DialogContentText>

        <RadioSelect
          className={enabled ? undefined : classes.disabled}
          column
          all={PAUSE_REASON_OPTIONS}
          value={pauseReason}
          setValue={enabled ? setPauseReason : undefined}
        />

        <DialogContentText className={classes.text} color="primary">
          And how do you feel about resuming reading?  
          <span role="img" aria-label="reading">
            👀
          </span>
        </DialogContentText>

        <RadioSelect
          className={enabled ? undefined : classes.disabled}
          column
          all={RESUME_READING_OPTIONS}
          value={resumeReading}
          setValue={enabled ? setResumeReading : undefined}
        />
      </DialogContent>
    </Dialog>
  )
}

PauseDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  pageNumber: PropTypes.number // The one-based page at which the abandon was invoked.
}

export default PauseDialog

/**
 * RadioSelectInput captures information for the select input type as a radio button group.
 */
import React, { useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { OPTION_NONE } from './constants'
import { arrayToKeyValue } from './utilities'

import RadioSelect from './RadioSelect'

const useStyles = makeStyles(
  theme => ({
    root: {
      margin: theme.spacing(1, 0, 2)
    }
  }),
  {
    name: 'RadioSelectInput'
  }
)

const valueToState = value => value ?? OPTION_NONE
const stateToValue = state => (state === OPTION_NONE ? undefined : state)

const RadioSelectInput = props => {
  const { options, column, value: valueProp, setValue: setValueProp } = props

  const { choices } = options ?? {}
  const all = useMemo(() => arrayToKeyValue(choices), [choices])

  const [value, setValue] = useState(valueToState(valueProp))

  useEffect(() => {
    setValue(valueToState(valueProp))
  }, [valueProp])

  useEffect(() => {
    if (setValueProp) {
      setValueProp(stateToValue(value))
    }
  }, [setValueProp, value])

  const classes = useStyles(props)
  return (
    <section className={classes.root}>
      <RadioSelect all={all} column={column} value={value} setValue={setValue} />
    </section>
  )
}

RadioSelectInput.propTypes = {
  options: PropTypes.shape({
    choices: PropTypes.arrayOf(PropTypes.string)
  }),

  column: PropTypes.bool, // Whether to display the checkboxes as a column.
  value: PropTypes.string, // The answer to display.
  setValue: PropTypes.func // Function to call if an answer changes.
}

export default RadioSelectInput

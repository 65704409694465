import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import screenplayEditIcon from './assets/screenplay-edit.svg'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex'
    },

    userBadge: {
      paddingRight: theme.spacing(2),

      '& > img': {
        borderRadius: theme.spacing(6),
        width: theme.spacing(6),
        height: theme.spacing(6)
      }
    },

    userData: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      lineHeight: theme.typography.pxToRem(16),
      textAlign: 'left'
    },

    genres: {
      marginTop: theme.spacing(0.5)
    }
  }),
  {
    name: 'UserBadge'
  }
)

const UserBadge = props => {
  const { className, user } = props

  const classes = useStyles(props)
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.userBadge}>
        <img src={user.image || screenplayEditIcon} alt={'avatar'} />
      </div>

      <div className={classes.userData}>
        <strong>{user.username}</strong>
        <div className={classes.genres}>{(user.genres || []).join(', ')}</div>
      </div>
    </div>
  )
}

UserBadge.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired
}

export default UserBadge

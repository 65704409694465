/**
 * RatingInput captures information for the stars question type, rendering actual
 * numbers instead of uniform stars.
 */
import React, { useEffect, useMemo, useState } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'

import Button from '@material-ui/core/Button'

const useStyles = makeStyles(
  theme => ({
    root: {
      alignItems: 'center',
      columnGap: `${theme.spacing(0.5)}px`,
      display: 'flex',
      justifyContent: 'space-between',
      margin: theme.spacing(2, 0, 2, -3), // Avoid the number indent in mobile.

      [theme.breakpoints.up('sm')]: {
        columnGap: `${theme.spacing(1)}px`
      },

      [theme.breakpoints.up('md')]: {
        columnGap: `${theme.spacing(2)}px`,
        margin: theme.spacing(2, 0)
      }
    },

    rating: {
      aspectRatio: 1,
      borderRadius: theme.spacing(1),
      borderStyle: 'solid', // Even for variant=contained, we want a border at all times to preserve widths.
      borderWidth: theme.spacing(0.125),
      flexBasis: 0,
      flexGrow: 1,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1,
      minWidth: 'min-content',
      overflow: 'hidden',
      padding: theme.spacing(0.5),

      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightBold,
        padding: theme.spacing(0.5)
      },

      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(24),
        fontWeight: theme.typography.fontWeightBold
      }
    },

    selected: {},
    icon: {}
  }),
  {
    name: 'RatingInput'
  }
)

const NONE = 0

const valueToState = value => value ?? NONE
const stateToValue = state => (state === NONE ? undefined : state)

const RatingInput = props => {
  const { options, value: valueProp, setValue: setValueProp } = props
  const { count } = options ?? {}

  const [value, setValue] = useState(valueToState(valueProp))

  const clickHandler = rating => event => setValue(value => (rating === value ? NONE : rating))

  const ratings = useMemo(() => {
    const result = []
    for (let i = 1; i <= count; i += 1) {
      result.push(i)
    }

    return result
  }, [count])

  useEffect(() => {
    setValue(valueToState(valueProp))
  }, [valueProp])

  useEffect(() => {
    if (setValueProp) {
      setValueProp(stateToValue(value))
    }
  }, [setValueProp, value])

  const classes = useStyles(props)
  return (
    <span className={classes.root}>
      {ratings.map((rating, index) => {
        const selected = rating === value
        return (
          <Button
            key={index}
            className={clsx(classes.rating, selected && classes.selected)}
            color={selected ? 'primary' : undefined}
            variant={selected ? 'contained' : 'outlined'}
            onClick={clickHandler(rating)}
          >
            {index + 1}
          </Button>
        )
      })}
    </span>
  )
}

RatingInput.propTypes = {
  options: PropTypes.shape({
    count: PropTypes.number
  }),

  value: PropTypes.number, // The answer to display.
  setValueProp: PropTypes.func // Function to call if an answer changes.
}

export default RatingInput

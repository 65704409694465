/**
 * TermsDialog is a component that displays Zoodiker’s terms for reading scripts.
 */
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'

import avatar from '../assets/avatar.png'

const useStyles = makeStyles(
  theme => ({
    root: {},
    paper: {},

    title: {
      alignItems: 'center',
      columnGap: `${theme.spacing(2)}px`,
      display: 'flex',
      fontSize: theme.typography.pxToRem(24),
      justifyContent: 'space-around',
      padding: theme.spacing(4, 4, 2)
    },

    avatar: {
      display: 'inline-flex'
    },

    content: {
      padding: theme.spacing(1, 6),

      '&:first-child': {
        paddingTop: theme.spacing(2) // We reset padding-top here because the default sets it in :first-child.
      }
    },

    actions: {
      justifyContent: 'space-between',
      padding: theme.spacing(3)
    },

    actionButton: {
      padding: theme.spacing(0.75, 2),
      transition: theme.transitions.create('opacity')
    },

    actionButtonLabel: {
      columnGap: `${theme.spacing(0.75)}px`
    },

    progress: {
      color: theme.palette.common.white
    },

    honestyCheckGroup: {
      rowGap: `${theme.spacing(1.25)}px`
    },

    honestyCheckRoot: {
      alignItems: 'flex-start',
      margin: 0,
      maxWidth: theme.spacing(56)
    },

    honestyCheckboxRoot: {
      marginLeft: theme.spacing(-0.625)
    },

    honestyCheckLabel: {
      paddingTop: theme.spacing(1), // Meant to match checkbox padding for proper alignment.

      '& > div:last-child': {
        marginTop: theme.spacing(0.75)
      }
    }
  }),
  {
    name: 'TermsDialog'
  }
)

const THE_READERS_NDA = 'I will not discuss this script without the Author’s consent.'

const THE_READERS_HONESTY = 'I will read thoroughly and respond honestly.'

const THE_READERS_ON_SITE = [
  'I understand reading must be done on this site.',
  'All downloading or use of tools for reading offsite',
  'violate the User Agreement and such readers will be ineligible for any payment.'
].join(' ')

const TermsDialog = props => {
  const { open, onAgree } = props

  const [agreeingInProgress, setAgreeingInProgress] = useState(false)

  const [ndaChecked, setNdaChecked] = useState(false)
  const [honestyChecked, setHonestyChecked] = useState(false)
  const [onSiteChecked, setOnSiteChecked] = useState(false)

  const canAgree = ndaChecked && honestyChecked && onSiteChecked && !agreeingInProgress

  const handleNdaChecked = event => setNdaChecked(event.target.checked)
  const handleHonestyChecked = event => setHonestyChecked(event.target.checked)
  const handleOnSiteChecked = event => setOnSiteChecked(event.target.checked)

  const handleAgree = async event => {
    event.preventDefault()

    setAgreeingInProgress(true)
    await onAgree()
  }

  useEffect(() => {
    // Reset answers whenever the dialog is opened.
    if (open) {
      setNdaChecked(false)
      setHonestyChecked(false)
      setOnSiteChecked(false)
      setAgreeingInProgress(false)
    }
  }, [open])

  const classes = useStyles(props)
  return (
    <Dialog classes={{ root: classes.root, paper: classes.paper }} maxWidth="md" open={Boolean(open)}>
      <form onSubmit={handleAgree}>
        <DialogTitle className={classes.title} disableTypography>
          <Avatar className={classes.avatar} alt="zoovatar" src={avatar} />
          Your Zoodiker Pledge
          <Avatar className={classes.avatar} alt="zoovatar" src={avatar} />
        </DialogTitle>

        <DialogContent className={classes.content}>
          <FormGroup className={classes.honestyCheckGroup}>
            <FormControlLabel
              classes={{ root: classes.honestyCheckRoot, label: classes.honestyCheckLabel }}
              control={
                <Checkbox
                  classes={{ root: classes.honestyCheckboxRoot }}
                  value={ndaChecked}
                  onChange={handleNdaChecked}
                />
              }
              label={THE_READERS_NDA}
            />

            <FormControlLabel
              classes={{ root: classes.honestyCheckRoot, label: classes.honestyCheckLabel }}
              control={
                <Checkbox
                  classes={{ root: classes.honestyCheckboxRoot }}
                  value={honestyChecked}
                  onChange={handleHonestyChecked}
                />
              }
              label={THE_READERS_HONESTY}
            />

            <FormControlLabel
              classes={{ root: classes.honestyCheckRoot, label: classes.honestyCheckLabel }}
              control={
                <Checkbox
                  classes={{ root: classes.honestyCheckboxRoot }}
                  value={onSiteChecked}
                  onChange={handleOnSiteChecked}
                />
              }
              label={THE_READERS_ON_SITE}
            />
          </FormGroup>
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button
            classes={{ root: classes.actionButton, label: classes.actionButtonLabel }}
            color="primary"
            type="submit"
            variant="contained"
            disabled={!canAgree}
          >
            <span role="img" aria-label="timeout">
              🫡
            </span>
            I agree
            {agreeingInProgress && <CircularProgress className={classes.progress} size={12} />}
          </Button>

          <Button classes={{ root: classes.actionButton, label: classes.actionButtonLabel }} component={NavLink} to="/">
            <span role="img" aria-label="timeout">
              😕
            </span>
            I do not agree
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

TermsDialog.propTypes = {
  open: PropTypes.bool,
  onAgree: PropTypes.func.isRequired
}

export default TermsDialog

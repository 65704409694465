import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import format from 'date-fns/format'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import GetAppIcon from '@material-ui/icons/GetApp'
import HourglassFullIcon from '@material-ui/icons/HourglassFull'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import ReplacementIcon from '@material-ui/icons/FileCopy'
import WarningIcon from '@material-ui/icons/Warning'
import TimelineIcon from '@material-ui/icons/Timeline'

import { getScriptResponses, getPageResponses, user } from './api'
import GENRES from './models/genres'
import MEDIA, { MediaChip } from './models/media'
import WARNINGS from './models/warnings'
import { needsSurvey, scriptIsDeleted } from './utility'

import Delete from './Delete'
import MinimalButton from './MinimalButton'
import ScriptDialog from './ScriptDialog'
import Submit from './Submit'

const SCRIPT_ITEM_GUTTER = 0.5
const GENRE_GUTTER = 0.25

const CELL_TITLE = 'title'
const CELL_SUBTITLE = 'subtitle'
const CELL_AUTHOR_CONTROLS = 'authorControls'
const CELL_MORE = 'more'

const CELL_LOGLINE = 'logline'
const CELL_PAGE_COUNT = 'pageCount'
const CELL_MEDIUM = 'medium'
const CELL_GENRES = 'genres'

const useStyles = makeStyles(
  theme => ({
    top: {
      // Not called root because there can be more than one.
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      margin: theme.spacing(-SCRIPT_ITEM_GUTTER)
    },

    deletedMessage: {
      marginTop: theme.spacing(2.25)
    },

    info: {
      columnGap: theme.spacing(SCRIPT_ITEM_GUTTER),
      display: 'grid',
      gridTemplateColumns: '1fr min-content',
      margin: theme.spacing(0, 0, SCRIPT_ITEM_GUTTER * 2.125),

      // For some reason, px gets appended to columnGap but not yet rowGap.
      rowGap: `${theme.spacing(SCRIPT_ITEM_GUTTER * 0.75)}px`
    },

    infoGridForAuthor: {
      gridTemplateAreas: [
        `"${CELL_TITLE}         ${CELL_AUTHOR_CONTROLS}"`,
        `"${CELL_SUBTITLE}      ${CELL_MORE}"`
      ].join('\n')
    },

    infoGridForOthers: {
      gridTemplateAreas: [
        `"${CELL_TITLE}              ${CELL_TITLE}"`,
        `"${CELL_SUBTITLE}           ${CELL_MORE}"`
      ].join('\n')
    },

    infoTitle: {
      gridArea: CELL_TITLE
    },

    infoSubtitle: {
      display: 'inline-flex',
      gridArea: CELL_SUBTITLE,
      '& small': {
        marginRight: theme.spacing(SCRIPT_ITEM_GUTTER / 2)
      }
    },

    infoAuthorControls: {
      gridArea: CELL_AUTHOR_CONTROLS,
      whiteSpace: 'nowrap',

      '& > span': {
        // For tooltip-wrapped disabled buttons.
        display: 'inline-block'
      }
    },

    infoMore: {
      gridArea: CELL_MORE,
      lineHeight: '100%',
      textAlign: 'right' // We want right-justification independent of language direction.
    },

    more: {
      columnGap: theme.spacing(SCRIPT_ITEM_GUTTER),
      display: 'grid',
      gridTemplateAreas: [
        `"${CELL_GENRES}       ${CELL_GENRES}"`,
        `"${CELL_LOGLINE}      ${CELL_LOGLINE}"`,
        `"${CELL_PAGE_COUNT}   ${CELL_MEDIUM}"`
      ].join('\n'),
      gridTemplateColumns: 'repeat(2, 1fr)',
      margin: theme.spacing(0, 0, SCRIPT_ITEM_GUTTER * 2.125),

      // For some reason, px gets appended to columnGap but not yet rowGap.
      rowGap: `${theme.spacing(SCRIPT_ITEM_GUTTER * 0.75)}px`
    },

    moreLogline: {
      color: theme.palette.grey.A700,
      gridArea: CELL_LOGLINE,
      margin: theme.spacing(2, 3)
    },

    morePageCount: {
      gridArea: CELL_PAGE_COUNT
    },

    moreMedium: {
      gridArea: CELL_MEDIUM,
      marginLeft: 'auto'
    },

    moreGenres: {
      display: 'flex',
      flexWrap: 'wrap',
      gridArea: CELL_GENRES,
      margin: theme.spacing(-GENRE_GUTTER),

      '& > $infoChip': {
        margin: theme.spacing(GENRE_GUTTER)
      }
    },

    buttons: {
      '& > div': {
        alignItems: 'center',
        display: 'flex',
        margin: theme.spacing(SCRIPT_ITEM_GUTTER)
      },

      '& a': {
        marginRight: theme.spacing(0.5),
        whiteSpace: 'nowrap'
      },

      // Per Material-UI, class names constructed in this way are stable and may be used for overrides:
      //   https://material-ui.com/customization/components/#global-css-override
      '& .MuiCircularProgress-root': {
        marginLeft: theme.spacing(1)
      }
    },

    authorIcon: {
      fontSize: theme.typography.pxToRem(16)
    },

    authorControlButton: {
      '&.Mui-disabled > *': {
        opacity: 0.15
      }
    },

    replace: {
      color: theme.palette.success.main
    },

    replacement: {
      marginLeft: theme.spacing(0.75),
      userSelect: 'none' // For long-touch tooltip appearance.
    },

    delete: {
      color: theme.palette.error.main
    },

    downloadResponses: {
      color: theme.palette.warning.main
    },

    title: {
      fontSize: theme.spacing(2)
    },

    triggers: {
      color: theme.palette.error.main,
      marginLeft: theme.spacing(0.25),
      userSelect: 'none' // For long-touch tooltip appearance.
    },

    genreChip: {
      textTransform: 'lowercase'
    },

    infoChip: {
      fontSize: theme.typography.pxToRem(12),
      height: theme.spacing(2.375)
    },

    mediaChipOutlined: {
      border: 'none',

      '& .MuiChip-iconSmall': {
        fontSize: theme.typography.pxToRem(24),
        marginLeft: theme.spacing(0.5)
      }
    }
  }),
  {
    name: 'ScriptItem'
  }
)

const ScriptItem = props => {
  const { script, onScriptChange, onScriptReplace, onScriptDelete } = props
  const {
    id,
    title,
    author,
    genres,
    logline,
    medium,
    page_count: pageCount,
    permitted_completed_survey_count: permittedCompletedSurveyCount,
    reader_status: readerStatus,
    uploaded_at: uploadedAt,
    replacement_of: replacementOf,
    reviewable,
    warnings
  } = script ?? {}

  const [expanded, setExpanded] = useState(false)
  const [infoOpen, setInfoOpen] = useState(false)
  const [replaceOpen, setReplaceOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [downloadResponsesInProgress, setDownloadResponsesInProgress] = useState(false)

  const navToSurvey = needsSurvey(readerStatus)
  const navLink = navToSurvey ? `/surveys/${id}` : `/reader/${id}`
  const warningList = warnings && warnings.length > 0 && warnings.map(warning => WARNINGS[warning]).join(', ')

  const currentUser = user()
  const datestamp = dateString => format(new Date(dateString), 'yyyy-MM-dd h:mma')
  const deleted = scriptIsDeleted(script)
  const userIsAuthor = currentUser && currentUser === author

  const handleInfoOpen = event => setInfoOpen(true)
  const handleInfoClose = event => setInfoOpen(false)
  const toggleExpanded = event => setExpanded(!expanded)

  const handleInfoSuccess = changedScript => {
    if (onScriptChange) {
      onScriptChange(changedScript)
    }

    setInfoOpen(false)
  }

  const handleReplaceOpen = event => setReplaceOpen(true)
  const handleReplaceClose = event => setReplaceOpen(false)

  const handleReplaceCompleted = () => {
    setReplaceOpen(false)
    if (onScriptReplace) {
      onScriptReplace(script)
    }
  }

  const handleDeleteOpen = event => setDeleteOpen(true)
  const handleDeleteClose = event => setDeleteOpen(false)

  const handleDelete = () => {
    setDeleteOpen(false)
    if (onScriptDelete) {
      onScriptDelete(script)
    }
  }

  const handleDownloadResponses = () => {
    if (downloadResponsesInProgress) {
      return
    }

    setDownloadResponsesInProgress(true)
    // Separate async function to handle download in background
    const downloadResponses = async () => {
      // Create, bundle, and wait for API promises for script/page responses
      const responsesPromises = [getScriptResponses(script)]
      for (let page = 1; page <= pageCount; page++) {
        responsesPromises.push(getPageResponses(script, page))
      }

      const responsesArray = await Promise.all(responsesPromises)
      // Define a function to append valid text sections for a page/script response list
      // If no response in the list has a comment, don't append anything
      const appendToStringIfHasResponses = (string, pageResponses, pageHeader) => {
        let hasResponses = false
        pageResponses.forEach(response => {
          if (response.comment.length > 0) {
            if (!hasResponses) {
              hasResponses = true
              string += pageHeader + '\n\n'
            }
            string += response.reviewer + ': ' + response.comment + '\n'
          }
        })
        if (hasResponses) {
          string += '\n\n'
        }
        return string
      }

      // Create a string for the file contents and call append function for script/each page
      let file = appendToStringIfHasResponses('', responsesArray[0].responses, 'Draft Responses:')
      for (let page = 1; page <= pageCount; page++) {
        file = appendToStringIfHasResponses(file, responsesArray[page].responses, 'Page ' + page + ':')
      }

      // Sadly we have to "trick" the code into downloading this file by making a temporary link element
      var element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(file))
      element.setAttribute('download', `${title} Responses.txt`)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
      setDownloadResponsesInProgress(false)
    }

    downloadResponses()
  }

  const classes = useStyles(props)
  return (
    <>
      <section
        className={clsx(classes.top, classes.info, classes[userIsAuthor ? 'infoGridForAuthor' : 'infoGridForOthers'])}
      >
        <section className={classes.infoTitle}>
          <em className={classes.title}>{title}</em>
        </section>

        <section className={classes.infoSubtitle}>
          <small className={classes.uploadDate}>
            <em>{datestamp(uploadedAt)}</em>
          </small>

          {warningList && (
            <Tooltip arrow title={warningList} aria-label="trigger warnings" placement="right">
              <WarningIcon classes={{ fontSizeSmall: clsx(classes.authorIcon, classes.triggers) }} fontSize="small" />
            </Tooltip>
          )}

          {replacementOf && (
            <Tooltip arrow title="Replaces another draft" aria-label="replacement" placement="right">
              <ReplacementIcon
                classes={{ fontSizeSmall: clsx(classes.authorIcon, classes.replacement) }}
                color="secondary"
                fontSize="small"
              />
            </Tooltip>
          )}
        </section>

        {userIsAuthor && (
          <section className={classes.infoAuthorControls}>
            <Tooltip arrow title="Download responses" aria-label="download responses" placement="top">
              <IconButton
                className={classes.authorControlButton}
                size="small"
                aria-label="download responses"
                onClick={handleDownloadResponses}
              >
                {downloadResponsesInProgress ? (
                  <HourglassFullIcon
                    classes={{ fontSizeSmall: clsx(classes.authorIcon, classes.downloadResponses) }}
                    fontSize="small"
                  />
                ) : (
                  <GetAppIcon
                    classes={{ fontSizeSmall: clsx(classes.authorIcon, classes.downloadResponses) }}
                    fontSize="small"
                  />
                )}
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Update" aria-label="update" placement="top">
              <IconButton
                className={classes.authorControlButton}
                color="primary"
                size="small"
                aria-label="update"
                onClick={handleInfoOpen}
              >
                <InfoIcon classes={{ fontSizeSmall: classes.authorIcon }} fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip
              arrow
              title={deleted ? 'Cannot replace—already deleted' : 'Replace'}
              aria-label="replace"
              placement="top"
            >
              <span>
                {/* Wrapper needed to enable tooltip when disabled. */}
                <IconButton
                  className={classes.authorControlButton}
                  size="small"
                  aria-label="replace"
                  onClick={handleReplaceOpen}
                  disabled={deleted}
                >
                  <CloudUploadIcon
                    classes={{ fontSizeSmall: clsx(classes.authorIcon, classes.replace) }}
                    fontSize="small"
                  />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip
              arrow
              title={deleted ? 'Cannot delete—already deleted' : 'Delete'}
              aria-label="delete"
              placement="top"
            >
              <span>
                {/* Wrapper needed to enable tooltip when disabled. */}
                <IconButton
                  className={classes.authorControlButton}
                  size="small"
                  aria-label="delete"
                  onClick={handleDeleteOpen}
                  disabled={deleted}
                >
                  <DeleteIcon classes={{ fontSizeSmall: clsx(classes.authorIcon, classes.delete) }} fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
          </section>
        )}

        <section className={classes.infoMore}>
          <MinimalButton onClick={toggleExpanded}>{expanded ? 'less' : 'more'}</MinimalButton>
        </section>
      </section>

      <Collapse in={expanded}>
        <article className={classes.more}>
          <section className={classes.moreGenres}>
            {genres?.sort().map(genre => (
              <Chip
                key={genre}
                variant="outlined"
                className={clsx(classes.infoChip, classes.genreChip)}
                size="small"
                label={GENRES[genre]}
              />
            ))}
          </section>

          {logline && <section className={classes.moreLogline}>{logline}</section>}

          <section className={classes.morePageCount}>
            {pageCount} page{pageCount === 1 ? '' : 's'}
          </section>

          <section className={classes.moreMedium}>
            {medium && (
              <MediaChip
                className={classes.infoChip}
                classes={{ outlined: classes.mediaChipOutlined }}
                size="small"
                medium={MEDIA[medium]}
              />
            )}
          </section>
        </article>
      </Collapse>

      <section className={clsx(classes.top, classes.buttons)}>
        <div>
          <Button variant="contained" color="primary" component={Link} to={navLink}>
            {navToSurvey ? 'Finish Feedback' : reviewable ? 'Review' : 'Read'}
          </Button>

          {permittedCompletedSurveyCount > 0 && (
            <>
              <Button variant="contained" color="secondary" component={Link} to={`/response/${id}`}>
                View Feedback
              </Button>

              <Button color="primary" component={Link} to={`/graph/${id}`}>
                <TimelineIcon />
              </Button>
            </>
          )}
        </div>
      </section>

      {deleted && (
        <article className={classes.deletedMessage}>
          <i>
            <b>This script has been deleted.</b> It appears only in your listings so that you can review feedback or
            other information, but is otherwise not listed to everyone else.
          </i>
        </article>
      )}

      {userIsAuthor && (
        <>
          <ScriptDialog open={infoOpen} onClose={handleInfoClose} onSuccess={handleInfoSuccess} script={script} />
          <Submit open={replaceOpen} onClose={handleReplaceClose} onSuccess={handleReplaceCompleted} script={script} />
          <Delete open={deleteOpen} onClose={handleDeleteClose} onDelete={handleDelete} script={script} />
        </>
      )}
    </>
  )
}

ScriptItem.propTypes = {
  script: PropTypes.object.isRequired,
  onScriptChange: PropTypes.func,
  onScriptReplace: PropTypes.func,
  onScriptDelete: PropTypes.func
}

export default ScriptItem

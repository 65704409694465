import React, { useState } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'

import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Button from '@material-ui/core/Button'

import { user, postScriptResponse } from './api'
import RESPONSE_CATEGORIES from './models/responseCategories'

import Feedback from './LegacyFeedback'

const useStyles = makeStyles(
  theme => ({
    root: {
      color: '#0a0a0a',
      height: '100%',

      '& h4': {
        fontSize: theme.typography.pxToRem(25),
        fontWeight: theme.typography.fontWeightRegular,
        marginBottom: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 0
      },

      '& h5': {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightRegular,
        margin: 0
      },

      '& hr': {
        clear: 'both',
        height: 0,
        marginBottom: theme.spacing(0.5),
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(0.5),
        borderBottom: '1px solid #cacaca',
        borderLeft: 0,
        borderRight: 0,
        borderTop: 0
      },

      '& svg': {
        marginRight: theme.spacing(1)
      }
    },

    divider: {
      alignItems: 'center',
      backgroundColor: '#e6e6e6',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      padding: theme.spacing(2)
    },

    label: {
      color: '#fefefe',
      display: 'inline-block',
      fontSize: theme.typography.pxToRem(12.8),
      padding: theme.spacing(0.5, 1)
    },

    moreToCome: {
      backgroundColor: theme.palette.primary.main
    },

    abandoned: {
      backgroundColor: theme.palette.error.main
    },

    rating: {
      paddingBottom: 0,
      paddingTop: 0
    },

    aggregateAvatar: {
      marginRight: theme.spacing(1)
    },

    aggregateText: {
      fontSize: theme.typography.pxToRem(24)
    },

    aggregateRating: {
      height: theme.spacing(7),
      width: theme.spacing(7)
    },

    reviewerAvatar: {
      marginRight: theme.spacing(0.5)
    },

    reviewerText: {
      fontSize: theme.typography.pxToRem(16)
    },

    reviewerRating: {
      fontSize: theme.typography.pxToRem(12),
      height: theme.spacing(3),
      width: theme.spacing(3)
    },

    aggregateNote: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginLeft: theme.spacing(8),
      paddingBottom: 0,
      paddingTop: 0
    },

    individualRatings: {
      display: 'grid',
      gridGap: theme.spacing(1),
      padding: 0,

      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(3, 1fr)'
      },

      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: 'repeat(2, 1fr)'
      }
    },

    individualRatingsAggregate: {
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(3, 1fr)'
      },

      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: 'repeat(1, 1fr)'
      }
    },

    ratingHigh: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.black
    },

    ratingMedium: {
      backgroundColor: theme.palette.primary.main
    },

    ratingLow: {
      backgroundColor: theme.palette.grey[600]
    },

    commentContainer: {
      marginTop: theme.spacing(2),
      '& > p': {
        marginBottom: 0,
        whiteSpace: 'pre-wrap'
      }
    }
  }),
  {
    name: 'ResponseCard'
  }
)

const ResponseCard = props => {
  const { aggregate, response, script, editResponseCallback } = props
  const { title, reviewer, abandoned, completed, page_response_count, overall } = response

  const currentUser = user()
  const incompleteResponse = script.author !== currentUser && reviewer === currentUser && !abandoned && !completed
  // ^^^^^ The current user did not write this script but we are showing his/her incomplete review.

  const classes = useStyles(props)

  const ratingClasses = {
    root: classes.rating,
    avatar: aggregate ? classes.aggregateAvatar : classes.reviewerAvatar,
    title: aggregate ? classes.aggregateText : classes.reviewerText
  }

  const ratingAvatarClassName = aggregate
    ? clsx(classes.aggregateText, classes.aggregateRating)
    : clsx(classes.reviewerText, classes.reviewerRating)

  const ratingLevel = rating => classes[`rating${rating >= 8 ? 'High' : rating >= 3 ? 'Medium' : 'Low'}`]

  const [feedbackModalIsOpen, setFeedbackModalIsOpen] = useState(false)
  const [feedbackInProgress, setFeedbackInProgress] = useState(false)

  const closeFeedbackModal = () => setFeedbackModalIsOpen(false)

  const submitFeedbackModal = async feedback => {
    if (!feedback) {
      return
    }

    setFeedbackInProgress(true)

    await postScriptResponse(script, feedback)
    setFeedbackModalIsOpen(false)
    editResponseCallback()
  }

  const handleEditResponse = () => {
    setFeedbackModalIsOpen(true)
  }

  return (
    <Card className={classes.root} variant="outlined">
      <div className={classes.divider}>
        {title && <h4>{title}</h4>}

        {reviewer && (
          <h5>
            <FontAwesomeIcon icon={faPencilAlt} />
            Review by: {reviewer}
          </h5>
        )}

        {reviewer && currentUser === reviewer && (
          <Button variant="contained" color="primary" onClick={handleEditResponse}>
            Edit Response
          </Button>
        )}

        {abandoned && (
          <span className={clsx(classes.label, classes.abandoned)}>
            Script was abandoned at page {page_response_count}.
          </span>
        )}

        {!completed && !abandoned && <span className={clsx(classes.label, classes.moreToCome)}>More to come…</span>}
      </div>

      {overall ? (
        <React.Fragment>
          <CardContent>
            <h5>Scale of 1-10, with 10 being terrific:</h5>
          </CardContent>

          <CardHeader
            classes={ratingClasses}
            avatar={
              <Avatar className={clsx(ratingAvatarClassName, ratingLevel(overall.rating))}>{overall.rating}</Avatar>
            }
            title="Overall Rating"
          />

          {aggregate && (
            <CardContent className={classes.aggregateNote}>
              <small>
                <em>Aggregate scores only include ratings from completed or abandoned reviews.</em>
              </small>
            </CardContent>
          )}

          <CardContent>
            <hr />
          </CardContent>

          <CardContent className={clsx(classes.individualRatings, aggregate && classes.individualRatingsAggregate)}>
            {RESPONSE_CATEGORIES.keys.map(category => (
              <CardHeader
                key={category}
                classes={ratingClasses}
                avatar={
                  <Avatar className={clsx(ratingAvatarClassName, ratingLevel(overall.evaluation[category]))}>
                    {overall.evaluation[category]}
                  </Avatar>
                }
                title={RESPONSE_CATEGORIES[category]}
              />
            ))}
          </CardContent>

          {overall.comment !== undefined && (
            <CardContent>
              <hr />

              <div className={classes.commentContainer}>
                <h5>Comments</h5>
                <p>{overall.comment}</p>
              </div>
            </CardContent>
          )}
        </React.Fragment>
      ) : (
        <CardContent>Review in progress; no overall feedback yet.</CardContent>
      )}

      {incompleteResponse && (
        <CardContent>
          If others have finished reading this script, you will see those responses after you submit yours!
        </CardContent>
      )}

      <Feedback
        open={feedbackModalIsOpen}
        inProgress={feedbackInProgress}
        onClose={closeFeedbackModal}
        onSubmit={submitFeedbackModal}
        values={overall}
      />
    </Card>
  )
}

ResponseCard.propTypes = {
  aggregate: PropTypes.bool,
  response: PropTypes.object,
  script: PropTypes.object
}

export default ResponseCard

/**
 * FreeformInput captures information for the freeform input type.
 */
import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'

import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(
  theme => ({
    root: {},

    textarea: {
      margin: theme.spacing(2, 0)
    }
  }),
  {
    name: 'FreeformInput'
  }
)

const valueToState = value => value ?? ''
const stateToValue = state => state

const FreeformInput = props => {
  const { options, value, setValue } = props
  const { limit } = options ?? {}

  const [text, setText] = useState(valueToState(value))

  const handleChange = event => setText(event.target.value)

  useEffect(() => {
    setText(valueToState(value))
  }, [value])

  useEffect(() => {
    if (setValue) {
      setValue(stateToValue(text))
    }
  }, [setValue, text])

  const classes = useStyles(props)
  return (
    <span className={classes.root}>
      <TextField
        className={classes.textarea}
        fullWidth
        inputProps={{ maxLength: limit }}
        multiline
        variant="outlined"
        value={text}
        onChange={handleChange}
      />
    </span>
  )
}

FreeformInput.propTypes = {
  options: PropTypes.shape({
    limit: PropTypes.number
  }),

  value: PropTypes.string, // The answer to display.
  setValue: PropTypes.func // Function to call if an answer changes.
}

export default FreeformInput

/**
 * This module contains functions that provide a concept of “dirty”-ness: i.e., whether one value has
 * been changed from a previous one. It contains logic beyond plain equality, facilitating multiple
 * values that represent “no value,” for example.
 */

// First, some baseline notions of “no (empty) values.”
const emptyArray = array => !(array?.length > 0)
const emptyBool = bool => bool !== true && bool !== false

/**
 * Helper function to determine whether a value is “dirty”—that is, changed from a base value
 * due to user edits.
 *
 * Beyond checking for equality, the function also considers falsy values to be the same.
 * An optional `nullValue` argument allows the caller to also designate a non-falsy value
 * as equivalent to “none,” for cases where placeholders are used.
 *
 * This may run into trouble with zero and false one day, but we’ll cross that bridge when
 * we get there.
 *
 * @param {any} initialValue initial value of a property
 * @param {any} formValue current, being-edited value of a property
 * @param {any} noneValue (optional) a non-falsy value that should also be equated with “none”
 * @returns
 */
const dirtyValue = (initialValue, formValue, noneValue) =>
  initialValue !== formValue &&
  Boolean(initialValue || formValue) &&
  (!noneValue || ((initialValue || formValue !== noneValue) && (formValue || initialValue !== noneValue)))

const dirtyArray = (initialArray, formArray, initialIdMap, formIdMap) => {
  // When one or both arrays is falsy, we have easy cases.
  if (!initialArray || !formArray) {
    // We consider falsy and empty arrays to be the same.
    return !(
      (!initialArray && !formArray) ||
      (!initialArray && emptyArray(formArray)) ||
      (!formArray && emptyArray(initialArray))
    )
  }

  // Get rid of another easy case.
  if (initialArray.length !== formArray.length) {
    return true
  }

  // Based on https://stackoverflow.com/questions/31128855/comparing-ecma6-sets-for-equality
  //
  // We use sets to eliminate potential duplicates…
  const initialIds = new Set(initialIdMap ? initialArray.map(initialIdMap) : initialArray)
  const formIds = new Set(formIdMap ? formArray.map(formIdMap) : formArray)

  // …but we still need the array version so that we can use `some`.
  return initialIds.size !== formIds.size || [...initialIds].some(id => !formIds.has(id))
}

const dirtyArrayWithOrder = (initialArray, formArray) =>
  initialArray.length !== formArray.length || !initialArray.every((item, index) => item === formArray[index])

// Booleans distinguish the actual value false from other falsy values.
const dirtyBool = (initialBool, formBool) =>
  !(initialBool === formBool || (emptyBool(initialBool) && emptyBool(formBool)))

// Dates also need special handling because they may have the same timestamp while still
// being different objects (and thus !==). But we do still consider falsy Dates to be the
// same so we pass them through `dirtyValue` too.
const dirtyDate = (initialDate, formDate) => dirtyValue(initialDate?.getTime(), formDate?.getTime())

export { dirtyValue, dirtyArray, dirtyArrayWithOrder, dirtyBool, dirtyDate, emptyArray, emptyBool }

import React, { useState, useEffect, useRef } from 'react'
import * as d3 from 'd3'

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import RATINGS from '../models/ratings'

import {
  AVERAGE_KEY,
  REVIEWER_STYLES,
  minimumPagesPerMinute,
  maximumPagesPerMinute,
  averageRatingPerPage
} from './visualizationHelpers'

const useStyles = makeStyles(
  theme => ({
    graphComment: {
      marginLeft: 'auto'
    },

    graphContainer: {
      overflow: 'auto'
    },

    circleDot: {
      transition: theme.transitions.create('r', {
        duration: theme.transitions.duration.short
      })
    },

    reviewerCallout: {
      // Based on Zurb Foundation .callout.primary classes.
      backgroundColor: '#d7ecfa',
      border: '1px solid rgba(10, 10, 10, 0.25)',
      borderRadius: 0,
      color: '#0a0a0a',
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2),
      position: 'relative'
    },

    reviewerHeader: {
      // Based on Zurb Foundation h5 style.
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: 1.4,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(0)
    },

    reviewerComments: {
      whiteSpace: 'pre-line'
    },

    ...REVIEWER_STYLES
  }),
  {
    name: 'RatingsPerPage'
  }
)

const LINE_THICKNESS = 2
const RING_THICKNESS = 4
const MINIMUM_OPACITY = 0.25
const MAXIMUM_OPACITY = 1.0
const MINIMUM_WIDTH_PER_PAGE = 40 // The minimum space we’ll allow between rating nodes.
const RADIUS = 1
const RADIUS_WITH_COMMENT = 8
const REVIEWER_GAP = 0 // With the ability to pick users, having an offset is less useful.
const RATING_TICKS = RATINGS.keys.map(key => RATINGS[key])

const DEFAULT_COMMENT_HEADER = 'Reviewer Comments'
const DEFAULT_COMMENT_CONTENT = [
  'Mouse over a circle to see when the reader rated that page.',
  'Larger circles indicate that additional comments are available.'
].join(' ')

const baseRadius = d => (d.comment ? RADIUS_WITH_COMMENT : RADIUS + RING_THICKNESS / 2)
const baseWidth = (pageCount, horizontalMargin) => pageCount * MINIMUM_WIDTH_PER_PAGE + horizontalMargin

const RatingsPerPage = props => {
  const [lastPageRead, setLastPageRead] = useState(0)

  const { script, allResponses, reviewerKeys, selectedReviewers } = props

  const graphContainerRef = useRef(null)
  const reviewerMetadataRef = useRef(null)
  const reviewerCommentsRef = useRef(null)

  const classes = useStyles(props)

  useEffect(() => {
    if (!allResponses) {
      return
    }

    const lastPageRead = d3.max(
      Object.keys(allResponses),
      reviewer => allResponses[reviewer][allResponses[reviewer].length - 1].page + 1
    )

    setLastPageRead(lastPageRead)
    const averagePerPage = averageRatingPerPage(allResponses, lastPageRead)

    const graphContainer = graphContainerRef.current
    if (!graphContainer) {
      return
    }

    const reviewerMetadata = reviewerMetadataRef.current
    const reviewerComments = reviewerCommentsRef.current

    const margin = { top: 20, right: 0, bottom: 30, left: 80 }
    const width = Math.max(graphContainer.offsetWidth, baseWidth(lastPageRead, margin.left + margin.right))
    const height = 384 // Since the ratings range never varies, we can have a fixed height.

    const lastPage = lastPageRead + 1 // This gives the graph a little space on the right.
    const x = d3
      .scaleLinear()
      .domain([0, lastPage])
      .range([margin.left, width - margin.right])

    const y = d3
      .scaleLinear()
      .domain([-1, RATINGS.keys.length])
      .nice() // One tick per rating, with margin above and below.
      .range([height - margin.bottom, margin.top])

    const opacity = d3
      .scaleLinear()
      .domain([minimumPagesPerMinute(allResponses), maximumPagesPerMinute(allResponses)])
      .nice()
      .range([MINIMUM_OPACITY, MAXIMUM_OPACITY])

    const xAxis = g =>
      g.attr('transform', `translate(0,${height - margin.bottom})`).call(
        d3
          .axisBottom(x)
          .ticks(lastPage + 1)
          .tickValues(d3.range(10, lastPage, 10))
          .tickSizeOuter(0)
      )

    const yAxis = g =>
      g
        .attr('transform', `translate(${margin.left},0)`)
        .call(
          d3
            .axisLeft(y)
            .tickValues(RATINGS.keys.map((key, index) => index))
            .tickFormat(value => RATING_TICKS[value] || '')
        )
        .call(g => g.select('.domain').remove())

    graphContainer.innerHTML = ''
    const svg = d3.select(graphContainer).append('svg').attr('width', width).attr('height', height)

    svg.append('g').call(xAxis)
    svg.append('g').call(yAxis)

    const handleMouseover = function (d) {
      d3.select(this).attr('r', baseRadius(d) * 2)

      if (reviewerMetadata) {
        reviewerMetadata.textContent = d.comment
          ? `${d.reviewer}, page ${d.page + 1}, on ${d.absoluteDate.toLocaleString()}:`
          : `${d.reviewer} rated page ${d.page + 1} on ${d.absoluteDate.toLocaleString()}`
      }

      if (reviewerComments) {
        reviewerComments.textContent = d.comment || DEFAULT_COMMENT_CONTENT
      }
    }

    const handleMouseout = function (d) {
      d3.select(this).attr('r', baseRadius(d))

      if (reviewerMetadata) {
        reviewerMetadata.textContent = DEFAULT_COMMENT_HEADER
      }

      if (reviewerComments) {
        reviewerComments.textContent = DEFAULT_COMMENT_CONTENT
      }
    }

    const responsesToGraph = {
      [AVERAGE_KEY]: averagePerPage,
      ...allResponses
    }

    selectedReviewers.forEach((reviewer, index) => {
      const line = d3
        .line()
        .x(d => x(d.page + 1))
        .y(d => y(d.rating) + index * REVIEWER_GAP)

      const data = responsesToGraph[reviewer].filter(response => response.rating > -1)
      const colorIndex = reviewerKeys.indexOf(reviewer) % d3.schemeCategory10.length

      svg
        .append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', d3.schemeCategory10[colorIndex])
        .attr('stroke-width', LINE_THICKNESS)
        .attr('stroke-linejoin', 'round')
        .attr('stroke-linecap', 'round')
        .attr('d', line)

      data.forEach(response =>
        svg
          .append('circle')
          .datum(response)
          .attr('class', classes.circleDot)
          .attr('fill', d => {
            const baseColor = d3.color(d3.schemeCategory10[colorIndex])
            baseColor.opacity = opacity(d.pagesPerMinute)
            return baseColor
          })
          .attr('stroke', d3.color(d3.schemeCategory10[colorIndex]).darker())
          .attr('stroke-width', d => (d.comment ? RING_THICKNESS * 0.75 : 0))
          .attr('cx', line.x())
          .attr('cy', line.y())
          .attr('r', baseRadius)
          .on('mouseover', handleMouseover)
          .on('mouseout', handleMouseout)
      )
    })
  }, [
    script,
    allResponses,
    graphContainerRef,
    reviewerMetadataRef,
    reviewerCommentsRef,
    reviewerKeys,
    selectedReviewers,
    classes.circleDot
  ])

  return (
    <div className={classes.root}>
      {lastPageRead && script.page_count > lastPageRead && (
        <p className={classes.graphComment}>
          Out of {script.page_count} pages, the furthest that any reviewer reached was page {lastPageRead}.
        </p>
      )}

      <div className={classes.graphContainer} ref={graphContainerRef}></div>

      <div className={classes.reviewerCallout}>
        <h5 className={classes.reviewerHeader} ref={reviewerMetadataRef}>
          {DEFAULT_COMMENT_HEADER}
        </h5>
        <em className={classes.reviewerComments} ref={reviewerCommentsRef}>
          {DEFAULT_COMMENT_CONTENT}
        </em>
      </div>
    </div>
  )
}

RatingsPerPage.propTypes = {
  script: PropTypes.object.isRequired,
  allResponses: PropTypes.object.isRequired,
  reviewerKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedReviewers: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default RatingsPerPage

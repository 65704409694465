import React, { useState } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'

import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(
  theme => ({
    title: {
      fontSize: theme.typography.pxToRem(40),
      paddingBottom: 0
    },

    closeButton: {
      color: theme.palette.grey[500],
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1)
    },

    row: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4)
    },

    ofThree: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: theme.spacing(2)
    },

    dialogActions: {
      padding: theme.spacing(2, 3, 3)
    },

    feedbackProgress: {
      color: theme.palette.common.white,
      marginLeft: theme.spacing(1)
    }
  }),
  {
    name: 'LegacyFeedback'
  }
)

const MIN_RATING = 1
const MAX_RATING = 10

const onRatingChange = setRating => event => {
  setRating(+event.target.value || '')
}

// We’ll allow blank ratings to not show an error—we’ll make it clear that they are required anyway.
const ratingError = rating => Boolean(rating && (rating < MIN_RATING || rating > MAX_RATING))

// We return a single space when there is no error so that it still occupies vertical space.
const ratingHelperText = rating => (ratingError(rating) ? 'Please enter 1, 2, 3, 4, 5, 6, 7, 8, 9, or 10.' : ' ')

const RatingField = props => (
  <TextField
    type="number"
    required={props.required}
    fullWidth
    variant="outlined"
    InputLabelProps={{
      shrink: true
    }}
    inputProps={{
      min: MIN_RATING,
      max: MAX_RATING,
      step: 1
    }}
    error={ratingError(props.value)}
    helperText={ratingHelperText(props.value)}
    label={props.label}
    value={props.value}
    onChange={onRatingChange(props.onChange)}
  />
)

RatingField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func
}

const initializeEvaluationState = (values, key) => values?.evaluation?.[key] || ''

const LegacyFeedback = props => {
  const { open, inProgress, onClose, onSubmit, values } = props

  const [rating, setRating] = useState(values?.rating || '')
  const [idea, setIdea] = useState(initializeEvaluationState(values, 'idea'))
  const [originality, setOriginality] = useState(initializeEvaluationState(values, 'originality'))
  const [clarity, setClarity] = useState(initializeEvaluationState(values, 'clarity'))
  const [character, setCharacter] = useState(initializeEvaluationState(values, 'character'))
  const [dialogue, setDialogue] = useState(initializeEvaluationState(values, 'dialogue'))
  const [plot, setPlot] = useState(initializeEvaluationState(values, 'plot'))
  const [comment, setComment] = useState(values?.comment || '')

  const classes = useStyles(props)

  // There’s a lot of recalculation happening here (e.g., ratingError) but it’s relatively inexpensive
  // so we’ll leave it be unless it turns out to start dragging things down, in which case we might need
  // to save these values as additional state variables and have useEffect update them when the underlying
  // rating values change. (time vs. space again)
  const canSave = () => rating && !ratingError(rating)

  return (
    <Dialog maxWidth="lg" fullWidth open={Boolean(open)} onClose={onClose} aria-labelledby="feedback-modal-title">
      <DialogTitle id="feedback-modal-title" className={classes.title} disableTypography>
        Overall Feedback
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          All rankings range from 1 to 10, with 1 being lowest and 10 being highest.
        </DialogContentText>

        <div className={classes.row}>
          <RatingField label="Overall Rating" value={rating} onChange={setRating} required />
        </div>

        <div className={clsx(classes.row, classes.ofThree)}>
          <RatingField label="Idea" value={idea} onChange={setIdea} />

          <RatingField label="Originality" value={originality} onChange={setOriginality} />

          <RatingField label="Clarity" value={clarity} onChange={setClarity} />
        </div>

        <div className={clsx(classes.row, classes.ofThree)}>
          <RatingField label="Character" value={character} onChange={setCharacter} />

          <RatingField label="Dialogue" value={dialogue} onChange={setDialogue} />

          <RatingField label="Plot" value={plot} onChange={setPlot} />
        </div>

        <div className={classes.row}>
          <TextField
            label="Overall Comments"
            minRows={5}
            fullWidth
            multiline
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            value={comment}
            onChange={event => setComment(event.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose}>Cancel</Button>

        <Button
          disabled={!canSave() || inProgress}
          onClick={() =>
            onSubmit({
              rating: +rating,
              evaluation: {
                idea: +idea,
                originality: +originality,
                clarity: +clarity,
                character: +character,
                dialogue: +dialogue,
                plot: +plot
              },
              comment
            })
          }
          color="primary"
          variant="contained"
        >
          Save
          {inProgress && <CircularProgress className={classes.feedbackProgress} size={16} />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

LegacyFeedback.propTypes = {
  open: PropTypes.bool,
  inProgress: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default LegacyFeedback

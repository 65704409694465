import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import format from 'date-fns/format'

import { getDocument, Util } from 'pdfjs-dist/webpack'

import makeStyles from '@material-ui/core/styles/makeStyles'

import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'

import {
  getScript,
  getScriptPageUrls,
  getPageResponses,
  getResponseStatuses,
  getScriptResponses,
  deleteScriptResponse,
  postPageResponse,
  postTermsAcceptance,
  user
} from './api'

import { useAppUser } from './app/hooks'
import debounce from './debounce'
import RATINGS from './models/ratings'
import { unexpectedErrorContainer } from './theme'
import { authorReview, needsSurvey, scriptIdFromProps, scriptIsDeleted, unfinished, unread } from './utility'

import ErrorResponse from './ErrorResponse'

import AuthorView from './reader-controllers/AuthorView'
import ConfirmAbandonDialog from './reader-controllers/ConfirmAbandonDialog'
import FinishedReview from './reader-controllers/FinishedReview'
import UnfinishedReview from './reader-controllers/UnfinishedReview'

const PAGE_LAYOUT_PADDING = [2, 4]

const useStyles = makeStyles(
  theme => ({
    root: {
      '& > section': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
          width: '100%'
        }
      }
    },

    authorView: {
      marginBottom: theme.spacing(1), // One less than top due to page margin.
      marginTop: theme.spacing(2)
    },

    pageLayout: {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2, 4)
      }
    },

    pageContainer: {
      margin: theme.spacing(1, 0, 2),
      padding: 0,
      position: 'relative',

      [theme.breakpoints.up('sm')]: {
        backgroundColor: theme.palette.divider,
        margin: theme.spacing(1, 0, 0.25),
        padding: theme.spacing(...PAGE_LAYOUT_PADDING)
      }
    },

    pageEmbed: {
      '& > svg': {
        fontFamily: 'Courier'
      }
    },

    watermark: {
      alignItems: 'flex-end',
      bottom: 0,
      display: 'flex',
      fontSize: theme.typography.pxToRem(6),
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,

      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(12),
        padding: theme.spacing(...PAGE_LAYOUT_PADDING)
      }
    },

    watermarkFooter: {
      color: theme.palette.text.disabled,
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      gap: theme.spacing(1),
      padding: theme.spacing(2)
    },

    userTimestamp: {},
    user: {},
    timestamp: {},

    doNotDuplicate: {
      textTransform: 'uppercase'
    },

    reviewControls: {
      marginTop: theme.spacing(1)
    },

    unexpectedError: unexpectedErrorContainer(theme)
  }),
  {
    name: 'Reader'
  }
)

const DEFAULT_LIMIT = 20
const PAGE_KEY = 'cursor'
const LIMIT_KEY = 'limit'

const getUserReviewed = listOfReviewedItems =>
  listOfReviewedItems.find(reviewedItem => reviewedItem.reviewer === user())

const getUserResponseStatus = async script => {
  // Work in progress.
  /*
     TODO This logic is an artifact of the original Riot-based implementation of the script reader. Loading the full
     list of response statuses can actually be deferred to the chosen reader controller for the current script and
     user. Instead, an API endpoint that only returns the user’s own response status for this script should be used
     here.
  */
  const params = new URLSearchParams()
  params.set(LIMIT_KEY, DEFAULT_LIMIT)

  let responseStatusesPage
  do {
    responseStatusesPage = await getResponseStatuses(script, params)

    const userResponseStatus = getUserReviewed(responseStatusesPage.statuses)
    if (userResponseStatus) {
      return userResponseStatus
    }

    if (responseStatusesPage.cursor_next) {
      params.set(PAGE_KEY, responseStatusesPage.cursor_next)
    }
  } while (responseStatusesPage.cursor_next)

  // If we get here, then the user has not started the script.
  return null
}

const getScriptData = async scriptId => {
  const script = await getScript(scriptId)

  // Software-level deleted script protection: API should do this, but for now we check on the front end.
  if (scriptIsDeleted(script) && script.author !== user()) {
    // Do a fake 404, mimicking what the API would do. The real error has a different message so this is the
    // inside tell (apart from the _actual_ lack of 404).
    const message = 'Not found: No such script'
    const simulated404 = new Error(message)
    simulated404.response = {
      status: 404,
      json: () => Promise.resolve({ message })
    }

    throw simulated404
  }

  const userResponseStatus = await getUserResponseStatus(script)
  return { script, userResponseStatus }
}

const MINIMUM_READING_TIME = 0

// Saving code is defined outside the function so that the debounced version can retain its state.
const save = async (script, pageNumber, rating, comment) => {
  const pageResponsePayload = {
    rating: RATINGS.keys.indexOf(rating),
    comment
  }

  await postPageResponse(script, pageNumber, pageResponsePayload)
}

const SAVE_DEBOUNCE_TIME = 250
const saveDebounced = debounce(save, SAVE_DEBOUNCE_TIME)

const MissingResponseStatus = props => {
  const { className } = props
  return (
    <section className={className}>
      <p>
        <strong>Missing Response Status</strong>
      </p>

      <p>Sorry—we were expecting a data thing called a “response status” for this script but didn’t get one.</p>

      <p>
        Please tell the Zoodǐker folks this specific message: “I’m supposed to have a response status for{' '}
        <em>(give them this script’s ID or title)</em>, but the API didn’t return one.”
      </p>

      <p>Unfortunately, without this response status, we don’t know how to proceed with this script.</p>
    </section>
  )
}

// Adapted from https://github.com/mozilla/pdf.js/blob/master/examples/text-only/pdf2svg.mjs
const SVG_NS = 'http://www.w3.org/2000/svg'

const buildSVG = (viewport, textContent) => {
  // Building SVG with size of the viewport (for simplicity)
  const svg = document.createElementNS(SVG_NS, 'svg:svg')
  const { width, height, transform: viewportTransform } = viewport

  svg.setAttribute('width', '100%')
  svg.setAttribute('viewBox', `0 0 ${width} ${height}`)
  // items are transformed to have 1px font size
  svg.setAttribute('font-size', 1)

  // processing all items
  textContent.items.forEach(textItem => {
    const { str, transform: textItemTransform } = textItem

    // we have to take in account viewport transform, which includes scale,
    // rotation and Y-axis flip, and not forgetting to flip text.
    const tx = Util.transform(Util.transform(viewportTransform, textItemTransform), [1, 0, 0, -1, 0, 0])

    // adding text element
    const text = document.createElementNS(SVG_NS, 'svg:text')
    text.setAttribute('transform', `matrix(${tx.join(' ')})`)
    text.textContent = str
    svg.append(text)
  })

  return svg
}

const Reader = props => {
  const appUser = useAppUser()
  const now = new Date()

  const watermarkUser = appUser?.username
  const watermarkTimestamp = format(now, 'yyyy-MM-dd h:mmaaa')

  const [script, setScript] = useState(null)
  const [userResponseStatus, setUserResponseStatus] = useState(null)
  const [replacedResponseStatus, setReplacedResponseStatus] = useState(null)
  const [userPageResponse, setUserPageResponse] = useState(null)

  const [pageInProgress, setPageInProgress] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const [pageUrl, setPageUrl] = useState()

  // Page-rendering-related state.
  const [pageRender, setPageRender] = useState()
  const [pageEmbed, setPageEmbed] = useState()

  const [readingTimeout, setReadingTimeout] = useState(null)
  const [minimumReadingTimeLapsed, setMinimumReadingTimeLapsed] = useState(false)

  const [comment, setComment] = useState('')
  const [rating, setRating] = useState(null)
  const [loading, setLoading] = useState(false)

  const [consideringAbandon, setConsideringAbandon] = useState(false)

  const [error, setError] = useState(null)

  const pageEmbedReady = useCallback(node => setPageEmbed(node), [])

  const history = useHistory()

  const scriptId = scriptIdFromProps(props)
  const onLastPage = () => pageNumber >= script.page_count

  const timerPage = replacedResponseStatus
    ? Math.max(pageInProgress, replacedResponseStatus.page_response_count + 1)
    : pageInProgress

  const navigateToSurvey = useCallback(() => history.push(`/surveys/${scriptId}`), [history, scriptId])

  const unabandon = async () => {
    setLoading(true)
    const scriptResponses = await getScriptResponses(script)
    const userScriptResponse = scriptResponses.find(scriptResponse => scriptResponse.reviewer === user())

    if (!userScriptResponse) {
      // This really shouldn't be possible, but still...
      throw new Error(`Script response not found for ${user()}`)
    }

    await deleteScriptResponse(userScriptResponse)
    history.push('/') // Return to the dashboard to show change of script state.
  }

  const handleCancelAbandon = event => setConsideringAbandon(false)

  const handleConfirmAbandon = async event => {
    await postPageResponse(script, pageNumber, { rating: RATINGS.keys.indexOf(rating), abandon: true })
    navigateToSurvey()
  }

  useEffect(() => {
    if (!userResponseStatus) {
      return
    }

    setMinimumReadingTimeLapsed(pageInProgress !== timerPage || !unfinished(userResponseStatus.status))
  }, [pageInProgress, timerPage, userResponseStatus])

  useEffect(() => {
    if (minimumReadingTimeLapsed && readingTimeout !== null) {
      clearTimeout(readingTimeout)
      setReadingTimeout(null)
    } else if (!minimumReadingTimeLapsed && readingTimeout === null) {
      setReadingTimeout(
        setTimeout(() => {
          setMinimumReadingTimeLapsed(true)
        }, MINIMUM_READING_TIME)
      )
    }
  }, [minimumReadingTimeLapsed, readingTimeout])

  useEffect(() => {
    if (!script || pageNumber < 1) {
      return
    }

    const loadPage = async () => {
      const [pageUrls, pageResponsesPage] = await Promise.all([
        getScriptPageUrls(script, pageNumber),
        getPageResponses(script, pageNumber)
      ])

      const pageResponses = pageResponsesPage.responses
      const userPageResponse = getUserReviewed(pageResponses)
      if (userPageResponse) {
        setUserPageResponse(userPageResponse)
      } else {
        setComment('')
        setRating(null)
      }

      // Adapted from https://github.com/mozilla/pdf.js/blob/master/examples/text-only/pdf2svg.mjs
      const loadingTask = getDocument(pageUrls)
      const pdfDocument = await loadingTask.promise
      const page = await pdfDocument.getPage(1)
      const viewport = page.getViewport({ scale: 1.0 })
      const textContent = await page.getTextContent()
      setPageRender(buildSVG(viewport, textContent))
      page.cleanup()

      setPageUrl(pageUrls.url)
      setLoading(false)
    }

    setLoading(true)
    loadPage()
  }, [pageNumber, script])

  useEffect(() => {
    if (!pageUrl) {
      return
    }

    document.body.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    })
  }, [pageUrl])

  useEffect(() => {
    setPageNumber(pageInProgress)
  }, [pageInProgress])

  useEffect(() => {
    const startReadingSession = async ({ script, userResponseStatus }) => {
      if (authorReview(script) || !userResponseStatus) {
        setPageNumber(1)
        return
      }

      const { status: readerStatus, page_response_count: pageResponseCount } = userResponseStatus
      if (unfinished(readerStatus)) {
        // If there are zero page responses so far, we go to page 1.
        if (pageResponseCount === 0) {
          setPageInProgress(1)
        } else {
          // Otherwise check the page response on the "count"-th page. If it has a rating already, we need to go to
          // the page _after_ that.
          let targetPage = pageResponseCount
          const pageResponsesPage = await getPageResponses(script, targetPage)
          const userPageResponse = getUserReviewed(pageResponsesPage.responses)

          if (userPageResponse && userPageResponse.rating > -1 && targetPage < script.page_count) {
            targetPage += 1
          }

          if (userPageResponse) {
            setUserPageResponse(userPageResponse)
          } else {
            setComment('')
            setRating(null)
          }

          setPageInProgress(targetPage)
        }
      } else if (needsSurvey(readerStatus)) {
        navigateToSurvey()
      } else {
        setPageNumber(1)
      }
    }

    const loadScript = async () => {
      try {
        const { script, userResponseStatus } = await getScriptData(scriptId)
        setScript(script)
        setUserResponseStatus(userResponseStatus)

        // One more step is necessary with scripts that are the replacement of another.
        if (script.replacement_of) {
          const { userResponseStatus: replacedResponseStatus } = await getScriptData(script.replacement_of)
          setReplacedResponseStatus(replacedResponseStatus)
        }

        startReadingSession({ script, userResponseStatus })
      } catch (error) {
        setError(error)
      }
    }

    loadScript()
  }, [scriptId, navigateToSurvey])

  useEffect(() => {
    setComment(userPageResponse ? userPageResponse.comment : '')
    setRating(userPageResponse ? RATINGS.keys[userPageResponse.rating] : null)
  }, [userPageResponse])

  useEffect(() => {
    if (pageRender && pageEmbed) {
      pageEmbed.innerHTML = ''
      pageEmbed.append(pageRender)
    }
  }, [pageRender, pageEmbed])

  const { status: readerStatus } = userResponseStatus ?? {}

  const readerProps = {
    script,
    responseStatus: userResponseStatus,
    pageNumber,
    setPageNumber,
    pageInProgress,
    minimumReadingTimeLapsed,
    replacedResponseStatus,
    loading,

    showTerms: Boolean(script && unread(readerStatus) && !authorReview(script)),
    agreeToTerms: async () => {
      try {
        await postTermsAcceptance(script)

        // Upon terms acceptance, reload the script response status.
        const userResponseStatus = await getUserResponseStatus(script)
        setUserResponseStatus(userResponseStatus)
      } catch (error) {
        setError(error)
      }
    },

    onLastPage,
    timerPage,

    abandon: event => setConsideringAbandon(true),

    availablePages: () => {
      const result = []

      // It's important to evaluate authorReview first because we need to short-circuit past unfinished if false.
      const lastAvailablePage = !authorReview(script) && unfinished(readerStatus) ? pageInProgress : script.page_count

      for (let i = 1; i <= lastAvailablePage; i += 1) {
        result.push(i)
      }

      return result
    },

    ratePage: async rating => {
      setLoading(true)

      try {
        await save(script, pageNumber, rating, comment)
      } catch (error) {
        setError(error)
      }

      if (onLastPage()) {
        setRating(rating)
        navigateToSurvey()
      } else {
        setPageInProgress(pageNumber + 1)
      }
    },

    comment,
    updateComment: comment => {
      setComment(comment)
      saveDebounced(script, pageNumber, rating, comment)
    },

    rating,
    updateRating: rating => {
      setRating(rating)

      try {
        save(script, pageNumber, rating, comment)
      } catch (error) {
        setError(error)
      }
    },

    unabandon
  }

  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      <section>
        {error ? (
          <div className={classes.unexpectedError}>
            <div>Sorry, but we appear to have run into a problem with this script.</div>
            <ErrorResponse error={error} />
          </div>
        ) : script && pageUrl ? (
          <>
            {authorReview(script) && <AuthorView className={classes.authorView} {...readerProps} />}

            <section className={classes.pageContainer}>
              <Paper className={classes.pageEmbed} ref={pageEmbedReady} />

              <section className={classes.watermark}>
                <footer className={classes.watermarkFooter}>
                  <span className={classes.userTimestamp}>
                    <span className={classes.user}>{watermarkUser ?? ''}</span>{' '}
                    <span className={classes.timestamp}>({watermarkTimestamp})</span>
                  </span>

                  <span className={classes.doNotDuplicate}>Do not duplicate</span>
                </footer>
              </section>
            </section>

            <div className={classes.reviewControls}>
              {!authorReview(script) &&
                (userResponseStatus ? (
                  unfinished(readerStatus) ? (
                    <UnfinishedReview {...readerProps} />
                  ) : (
                    <FinishedReview {...readerProps} />
                  )
                ) : (
                  <MissingResponseStatus className={classes.unexpectedError} />
                ))}
            </div>

            <ConfirmAbandonDialog
              open={consideringAbandon}
              onClose={handleCancelAbandon}
              onConfirm={handleConfirmAbandon}
              pageNumber={pageNumber}
            />
          </>
        ) : (
          <CircularProgress />
        )}
      </section>
    </div>
  )
}

export default Reader

import * as d3 from 'd3'

const AVERAGE_KEY = 'Average'

const reviewerClassName = index => `reviewerStyle${index % d3.schemeCategory10.length}`

const REVIEWER_STYLES = d3.schemeCategory10.reduce((styles, category, index) => {
  styles[reviewerClassName(index)] = {
    color: category,

    // Really belongs with the checkbox but we need to match the color and this won’t apply to other elements
    // anyway unless the explicitly include `Mui-checked` in the class name (which they shouldn’t).
    '&.Mui-checked': {
      color: category
    }
  }

  return styles
}, {})

const calculatePagesPerMinute = allResponses =>
  Object.keys(allResponses).forEach(reviewer => {
    const reviewerResponses = allResponses[reviewer]
    reviewerResponses.sort((left, right) => left.page - right.page)
    reviewerResponses.forEach((pageResponse, index) => {
      pageResponse.pagesPerMinute =
        index === 0 ? 0.0 : 60000 / (pageResponse.epoch - reviewerResponses[index - 1].epoch) // epoch is in milliseconds
    })
  })

const minimumPagesPerMinute = allResponses =>
  Object.keys(allResponses).reduce((current, reviewer) => {
    const reviewerResponses = allResponses[reviewer]
    return reviewerResponses.reduce((current, pageResponse) => Math.min(current, pageResponse.pagesPerMinute), current)
  }, 0.0)

const maximumPagesPerMinute = allResponses =>
  Object.keys(allResponses).reduce((current, reviewer) => {
    const reviewerResponses = allResponses[reviewer]
    return reviewerResponses.reduce((current, pageResponse) => Math.max(current, pageResponse.pagesPerMinute), current)
  }, 0.0)

const averageRatingPerPage = (allResponses, lastPageRead) => {
  const averagePerPage = []
  const dateOfAverageCalculation = new Date()
  for (let page = 0; page < lastPageRead; page += 1) {
    const responsesAtPage = Object.keys(allResponses)
      .map(reviewer => allResponses[reviewer][page])
      .filter(pageResponse => Boolean(pageResponse))

    const ratingTotal = responsesAtPage.reduce((accumulator, current) => accumulator + current.rating, 0)
    const average = ratingTotal / responsesAtPage.length

    averagePerPage[page] = {
      page,
      rating: average,
      reviewer: 'Zoodǐker Average',
      absoluteDate: dateOfAverageCalculation
    }
  }

  return averagePerPage
}

export {
  AVERAGE_KEY,
  REVIEWER_STYLES,
  reviewerClassName,
  calculatePagesPerMinute,
  minimumPagesPerMinute,
  maximumPagesPerMinute,
  averageRatingPerPage
}

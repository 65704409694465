import React, { useState } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import format from 'date-fns/format'
import isValid from 'date-fns/isValid'

import CircularProgress from '@material-ui/core/CircularProgress'
import Collapse from '@material-ui/core/Collapse'

import { getUserSnippet } from './api'

import avatar from './assets/avatar.png'

import MinimalButton from './MinimalButton'

const SNIPPET_GUTTER = 0.25

const CELL_DATE_LABEL = 'dateLabel'
const CELL_DATE = 'date'
const CELL_PAGES = 'pages'
const CELL_PAGES_LABEL = 'pagesLabel'
const CELL_SUBMISSIONS = 'submissions'
const CELL_SUBMISSIONS_LABEL = 'submissionsLabel'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: theme.spacing(10)
    },

    row: {
      display: 'flex',
      marginBottom: theme.spacing(1)
    },

    inset: {
      marginTop: theme.spacing(-0.25)
    },

    progress: {
      marginTop: theme.spacing(1)
    },

    snippet: {
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(1),
      columnGap: theme.spacing(SNIPPET_GUTTER * 3),
      display: 'grid',
      gridTemplateAreas: [
        `"${CELL_DATE_LABEL}    ${CELL_DATE}"`,
        `"${CELL_PAGES}         ${CELL_PAGES_LABEL}"`,
        `"${CELL_SUBMISSIONS}   ${CELL_SUBMISSIONS_LABEL}"`
      ].join('\n'),
      gridTemplateColumns: 'min-content 1fr',
      margin: theme.spacing(1, 0, SNIPPET_GUTTER * 2, 1),
      padding: theme.spacing(1, 1.5, 1, 0.75),

      // For some reason, px gets appended to columnGap but not yet rowGap.
      rowGap: `${theme.spacing(SNIPPET_GUTTER)}px`
    },

    snippetDateLabel: {
      alignItems: 'center',
      display: 'flex',
      fontWeight: theme.typography.fontWeightMedium,
      gridArea: CELL_DATE_LABEL,
      whiteSpace: 'nowrap',

      '& > img': {
        marginRight: theme.spacing(-0.125),
        width: theme.spacing(2.25)
      }
    },

    snippetDate: {
      gridArea: CELL_DATE
    },

    snippetNumber: {
      fontSize: '150%',
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '100%',
      textAlign: 'right'
    },

    snippetPages: {
      gridArea: CELL_PAGES
    },

    snippetPagesLabel: {
      gridArea: CELL_PAGES_LABEL
    },

    snippetSubmissions: {
      gridArea: CELL_SUBMISSIONS
    },

    snippetSubmissionsLabel: {
      gridArea: CELL_SUBMISSIONS_LABEL
    },

    groupList: {
      listStyle: 'none',
      margin: theme.spacing(0.5, 0, 0),
      paddingLeft: theme.spacing(1)
    }
  }),
  {
    name: 'ScriptAuthorItem'
  }
)

const pluralSuffix = count => (count === 1 ? '' : 's')

const ScriptAuthorItem = props => {
  const { script } = props
  const { author, group } = script || {}

  const [expanded, setExpanded] = useState(false)
  const [snippet, setSnippet] = useState()

  const { joined, pagesRead, authored } = snippet || {}

  const toggleExpanded = event => {
    setExpanded(!expanded)
    if (!expanded) {
      const loadUserSnippet = async username => {
        const userSnippet = await getUserSnippet(username)
        const { joined, pages_read: pagesRead, authored } = userSnippet || {}
        setSnippet({
          joined: new Date(joined),
          pagesRead,
          authored
        })
      }

      loadUserSnippet(author)
    }
  }

  const classes = useStyles(props)
  return (
    <article className={classes.root}>
      <section className={classes.row}>
        <section className={classes.inset}>
          <MinimalButton onClick={toggleExpanded}>{author}</MinimalButton>

          <Collapse in={expanded}>
            <section className={classes.row}>
              {snippet ? (
                <section className={classes.snippet}>
                  {isValid(joined) && (
                    <>
                      <section className={classes.snippetDateLabel}>
                        <img src={avatar} alt="Joined" />
                        date
                      </section>
                      <section className={classes.snippetDate}>{format(joined, 'yyyy-MM-dd')}</section>
                    </>
                  )}

                  <section className={clsx(classes.snippetNumber, classes.snippetPages)}>{pagesRead}</section>
                  <section className={classes.snippetPagesLabel}>page{pluralSuffix(pagesRead)} read</section>
                  <section className={clsx(classes.snippetNumber, classes.snippetSubmissions)}>{authored}</section>
                  <section className={classes.snippetSubmissionsLabel}>submission{pluralSuffix(authored)}</section>
                </section>
              ) : (
                <CircularProgress className={classes.progress} size={12} />
              )}
            </section>
          </Collapse>
        </section>
      </section>

      {group?.length > 0 && (
        <section className={classes.row}>
          <section>
            <MinimalButton onClick={toggleExpanded}>Group</MinimalButton>

            <Collapse in={expanded}>
              <ul className={classes.groupList}>
                {group.map(groupMember => (
                  <li key={groupMember}>{groupMember}</li>
                ))}
              </ul>
            </Collapse>
          </section>
        </section>
      )}
    </article>
  )
}

ScriptAuthorItem.propTypes = {
  script: PropTypes.object.isRequired
}

export default ScriptAuthorItem

import React, { useCallback } from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import SkipNextIcon from '@material-ui/icons/SkipNext'

import { getPageResponses } from '../api'
import { useInfiniteScroll } from '../app/hooks'
import RATINGS from '../models/ratings'

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '100%',
      '& > div': {
        display: 'flex'
      }
    },

    controlsContainer: {
      alignItems: 'flex-start'
    },

    controlsLabel: {
      whiteSpace: 'nowrap'
    },

    skipPreviousAdjust: {
      marginLeft: theme.spacing(-1) // To compensate for skip previous icon’s intrinsic margin.
    },

    skipNextAdjust: {
      marginRight: theme.spacing(-1) // To compensate for skip next icon’s intrinsic margin.
    },

    pageSelectContainer: {
      marginLeft: theme.spacing(1),
      '& > select': {
        paddingBottom: 9, // Meant to match height of Previous Page button exactly.
        paddingTop: 9
      }
    },

    progressContainer: {
      alignItems: 'center',
      display: 'flex',
      flexGrow: 1,
      minHeight: 70, // To minimize height-shifting while loading.
      justifyContent: 'center'
    },

    commentTable: {
      flexGrow: 1,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 'auto',
      '& > tbody td': {
        verticalAlign: 'top'
      }
    },

    comment: {
      whiteSpace: 'pre-wrap'
    },

    textCenter: {
      textAlign: 'center'
    }
  }),
  {
    name: 'AuthorView'
  }
)

const DEFAULT_LIMIT = 20
const PAGE_KEY = 'cursor'
const LIMIT_KEY = 'limit'

const loadPageResponsePage = async (script, pageNumber, pageResponsesCursor) => {
  const pageResponsesParams = new URLSearchParams()
  pageResponsesParams.set(LIMIT_KEY, DEFAULT_LIMIT)

  if (pageResponsesCursor) {
    pageResponsesParams.set(PAGE_KEY, pageResponsesCursor)
  }

  const pageResponses = await getPageResponses(script, pageNumber, pageResponsesParams)
  return {
    itemsPage: pageResponses.responses,
    cursorNext: pageResponses.cursor_next
  }
}

const AuthorView = props => {
  const { className, script, pageNumber, setPageNumber, loading, onLastPage, availablePages } = props

  const getPageResponse = reviewer => pageResponses.find(pageResponse => pageResponse.reviewer === reviewer)

  const getComment = pageResponse => {
    if (pageResponse.status === 'in progress' || pageResponse.status === 'new') {
      return '(not yet done with review)'
    } else if (pageResponse.status === 'abandoned') {
      if (pageNumber <= pageResponse.pages_reviewed) {
        const reviewerPageResponse = getPageResponse(pageResponse.reviewer)
        return reviewerPageResponse && reviewerPageResponse.comment
      } else {
        return ''
      }
    } else {
      // complete: every page must have a response; report a form of error otherwise.
      const reviewerPageResponse = getPageResponse(pageResponse.reviewer)
      return reviewerPageResponse ? reviewerPageResponse.comment : '(page response not available)'
    }
  }

  const getAddendum = pageResponse => {
    if (pageResponse.status === 'abandoned') {
      return `(abandoned from page ${pageResponse.pages_reviewed + 1} onward)`
    } else {
      // complete: nothing more to say
      return ''
    }
  }

  const getRating = pageResponse => {
    if (pageResponse.status === 'in progress' || pageResponse.status === 'new') {
      return ''
    } else {
      const reviewerPageResponse = getPageResponse(pageResponse.reviewer)
      return reviewerPageResponse ? RATINGS.keys[reviewerPageResponse.rating] : ''
    }
  }

  const pageResponseLoader = useCallback(
    async cursor => {
      if (!script) {
        return
      }

      return await loadPageResponsePage(script, pageNumber, cursor)
    },
    [script, pageNumber]
  )

  const { items: pageResponses, lastItem: lastResponse } = useInfiniteScroll(pageResponseLoader)

  const classes = useStyles(props)
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.controlsContainer}>
        <Button
          classes={{ label: classes.controlsLabel }}
          variant="contained"
          color="primary"
          disabled={loading || pageNumber <= 1}
          onClick={() => setPageNumber(pageNumber - 1)}
        >
          <SkipPreviousIcon className={classes.skipPreviousAdjust} />
          Previous Page
        </Button>

        <FormControl variant="outlined">
          <Select
            native
            className={classes.pageSelectContainer}
            disabled={loading}
            value={pageNumber}
            onChange={event => setPageNumber(+event.target.value)}
          >
            {availablePages().map(pageNumber => (
              <option key={pageNumber} value={pageNumber}>
                {pageNumber}
              </option>
            ))}
          </Select>
        </FormControl>

        {loading ? (
          <div className={classes.progressContainer}>
            <CircularProgress size={24} />
          </div>
        ) : (
          <TableContainer className={classes.commentTable} component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Reviewer</TableCell>
                  <TableCell>Comment</TableCell>
                  <TableCell>Rating</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!pageResponses || pageResponses.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan="3" className={classes.textCenter}>
                      {pageResponses && '(no reviews yet)'}
                    </TableCell>
                  </TableRow>
                ) : (
                  pageResponses.map((pageResponse, index) => (
                    <TableRow key={`pageResponse${index}`} ref={lastResponse}>
                      <TableCell>{pageResponse.reviewer}</TableCell>
                      <TableCell>
                        <div className={classes.comment}>{getComment(pageResponse)}</div>
                        <div>{getAddendum(pageResponse)}</div>
                      </TableCell>
                      <TableCell>{RATINGS[getRating(pageResponse)]}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Button
          classes={{ label: classes.controlsLabel }}
          variant="contained"
          color="primary"
          disabled={loading || onLastPage()}
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          Next Page
          <SkipNextIcon className={classes.skipNextAdjust} />
        </Button>
      </div>
    </div>
  )
}

AuthorView.propTypes = {
  className: PropTypes.string,
  script: PropTypes.object.isRequired,
  pageNumber: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onLastPage: PropTypes.func.isRequired,
  availablePages: PropTypes.func.isRequired
}

export default AuthorView

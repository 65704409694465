import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'

import { signup } from './api'

import ErrorResponse, { VARIANT_CONCISE } from './ErrorResponse'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',

      '& > *:not(:first-child)': {
        marginTop: theme.spacing(4)
      }
    },

    progress: {
      color: theme.palette.common.white
    },

    row: {
      alignItems: 'center',
      columnGap: theme.spacing(2),
      display: 'flex',
      width: '100%'
    }
  }),
  {
    name: 'SignupWithoutFrills'
  }
)

const SignupWithoutFrills = props => {
  const [email, setEmail] = useState('')

  const [error, setError] = useState()
  const [signingUp, setSigningUp] = useState(false)
  const [signupResult, setSignupResult] = useState(null)

  const handleEmailChange = event => setEmail(event.target.value)

  const submitSignup = async event => {
    event.preventDefault()
    setSigningUp(true)

    try {
      const { signup: signupResult } = await signup(email)
      setSignupResult(signupResult)
      setEmail('')
    } catch (error) {
      setError(error)
    } finally {
      setSigningUp(false)
    }
  }

  const classes = useStyles(props)
  return (
    <form className={classes.root} onSubmit={submitSignup}>
      <h1>Signup for Zoodiker</h1>

      <div>
        Follow the link in the email to complete your signup. Double check your spam folder if no email appears.
      </div>

      <div className={classes.row}>
        <TextField
          label="Email"
          type="email"
          name="email"
          disabled={signingUp}
          placeholder="your-email-here@example.com"
          required
          helperText="Please provide your email address."
          fullWidth
          value={email}
          onChange={handleEmailChange}
        />
      </div>

      <div className={classes.row}>
        <Button type="submit" color="primary" variant="contained" disabled={!email || signingUp}>
          Sign Up
          {email && ` ${email}`}
        </Button>

        {signingUp && <CircularProgress size={24} color="primary" />}
      </div>

      {signupResult && <div>The signup email should be on its way!</div>}

      <ErrorResponse variant={VARIANT_CONCISE} error={error} />
    </form>
  )
}

export default SignupWithoutFrills

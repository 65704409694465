import React from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import CssBaseline from '@material-ui/core/CssBaseline'

import { user, userRole } from './api'

import Dashboard from './Dashboard'
import Graph from './Graph'
import Join from './Join'
import Login from './Login'
import Logo from './Logo'
import Reader from './Reader'
import ResetPassword from './ResetPassword'
import ResetPasswordConfirmation from './ResetPasswordConfirmation'
import Response from './Response'
import Scaffold from './Scaffold'
import Shortcuts from './Shortcuts'
import SignupWithoutFrills from './SignupWithoutFrills'
import User from './User'
import UserHeader from './UserHeader'
import TermsOfService from './TermsOfService'
import PrivacyPolicy from './PrivacyPolicy'
import Survey from './Survey'

const protectedRoute =
  acceptableRoles =>
  ({ component: Component, hideOnly, ...rest }) => {
    const scriptId = rest.computedMatch ? rest.computedMatch.params.scriptId : ''
    return (
      <Route
        {...rest}
        render={props =>
          userRole(acceptableRoles) ? (
            <Component key={scriptId || 'non-script-specific'} {...props} />
          ) : hideOnly ? (
            <React.Fragment></React.Fragment>
          ) : (
            <Redirect
              to={{
                // A logged-in but rejected user goes home; if no user, we go to login.
                pathname: user() ? '/' : '/login',
                state: user() ? {} : { from: props.location }
              }}
            />
          )
        }
      />
    )
  }

const UserRoute = protectedRoute()

const useStyles = makeStyles(
  theme => ({
    root: {
      margin: theme.spacing(0, 2),

      '& > header': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 0),

        '& > *:first-child': {
          marginRight: theme.spacing(2)
        },

        '& > *:last-child': {
          flexGrow: 1
        }
      },

      // This does look the same as the article styles but _can_ be different so the code is only coincidentally a copy.
      '& > nav': {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: theme.spacing(150)
      },

      '& > article': {
        marginBottom: theme.spacing(4),
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: theme.spacing(150)
      }
    }
  }),
  {
    name: 'Zoodiker'
  }
)

const USER_PATHS = ['/', '/reader/:scriptId', '/response/:scriptId', '/graph/:scriptId', '/surveys/:scriptId', '/me']

const App = props => {
  const classes = useStyles(props)
  return (
    <Scaffold>
      <CssBaseline />
      <Router>
        <div className={classes.root}>
          <header>
            <Logo />

            <Switch>
              <UserRoute exact path={USER_PATHS} component={UserHeader} />
            </Switch>
          </header>

          <nav>
            <Switch>
              <UserRoute path={USER_PATHS.slice(1) /* All but dashboard. */} component={Shortcuts} />
            </Switch>
          </nav>

          <article>
            <Switch>
              <Route exact path="/signup-without-frills" component={SignupWithoutFrills} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route exact path="/confirmations/signups/:signupId" component={Join} />
              <Route exact path="/confirmations/resets/:resetId" component={ResetPasswordConfirmation} />
              <Route exact path="/terms-of-service" component={TermsOfService} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <UserRoute exact path="/" component={Dashboard} />
              <UserRoute exact path="/reader/:scriptId" component={Reader} />
              <UserRoute exact path="/response/:scriptId" component={Response} />
              <UserRoute exact path="/surveys/:scriptId" component={Survey} />
              <UserRoute exact path="/graph/:scriptId" component={Graph} />
              <UserRoute exact path="/me" component={User} />
              <Route component={Login} />
            </Switch>
          </article>
        </div>
      </Router>
    </Scaffold>
  )
}

export default App

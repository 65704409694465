import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import scimitarImage from './assets/scimitar.svg'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      '& > img': {
        marginRight: theme.spacing(2),
        width: theme.spacing(18)
      }
    },

    concise: {
      color: theme.palette.error.main
    },

    goryDetails: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      flexGrow: 1,

      '&:before': {
        backgroundColor: 'transparent'
      },

      '&.Mui-expanded': {
        margin: 0,
        '&:first-child': {
          marginTop: theme.spacing(3)
        }
      },

      '& h5': {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightRegular,
        marginBottom: 0,
        marginTop: 0
      },

      '& p': {
        fontSize: theme.typography.pxToRem(16),
        marginTop: 0
      }
    }
  }),
  {
    name: 'ErrorResponse'
  }
)

export const VARIANT_SCIMITAR = 'scimitar'
export const VARIANT_CONCISE = 'concise'

const ErrorResponse = props => {
  const [body, setBody] = useState(null)

  const { error, variant = VARIANT_SCIMITAR } = props
  const { message, response } = error ?? {}
  const { status, statusText } = response ?? {}
  const { message: responseMessage, filename } = body ?? {}

  useEffect(() => {
    if (!error || !error.response) {
      return
    }

    const loadBody = async () => setBody(await error.response.json())
    loadBody()
  }, [error])

  const classes = useStyles(props)
  if (!error) {
    return null
  }

  if (variant === VARIANT_CONCISE) {
    return (
      <span className={classes.concise}>
        {status} {statusText} {message} {body && responseMessage !== message && responseMessage}
      </span>
    )
  }

  return (
    <div className={classes.root}>
      <img src={scimitarImage} alt="Unexpected error" />

      <Accordion classes={{ root: classes.goryDetails }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="gory-details" id="gory-details-header">
          <h5>The Gory Details (Don’t Look Unless You Know a Zoodǐker Insider)</h5>
        </AccordionSummary>

        <AccordionDetails>
          <strong>
            {status} {statusText} {message}
          </strong>
          {body && (
            <>
              {` ${responseMessage}` /* NBSP in the preceding space. */}
              {filename && (
                <span>
                  {' ' /* Yep NBSP again. */}
                  <em>{filename}</em>
                </span>
              )}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

ErrorResponse.propTypes = {
  error: PropTypes.object,
  variant: PropTypes.oneOf([VARIANT_SCIMITAR, VARIANT_CONCISE])
}

export default ErrorResponse

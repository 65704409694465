import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'

import getYear from 'date-fns/getYear'
import { getByZipCode } from 'zips'

import TextField from '@material-ui/core/TextField'

import { emptyArray, emptyBool } from './dirty'
import { OPTION_NONE } from './form/constants'
import { arrayToKeyValue } from './form/utilities'

import TitledBorder from './TitledBorder'
import BooleanInput from './form/BooleanInput'
import CheckboxMultiselect from './form/CheckboxMultiselect'
import RadioSelect from './form/RadioSelect'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: theme.spacing(3),
      rowGap: `${theme.spacing(4)}px`,

      '& > *': {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '75%'
      }
    },

    field: {
      '& input:invalid': {
        color: theme.palette.error.main
      }
    },

    titledBorder: {
      marginBottom: 0,
      marginTop: 0,
      paddingTop: theme.spacing(2)
    },

    titledBorderLegend: {
      transition: theme.transitions.create('color')
    },

    // The styles here should be coordinated with the same class in User.
    incomplete: {
      color: theme.palette.error.main,
      fontStyle: 'italic',
      fontWeight: theme.typography.fontWeightBold,

      '&::after': {
        content: '"*"',
        opacity: 1.0
      },

      '&.Mui-focused': {
        color: theme.palette.error.main
      }
    },

    multiColumn: {
      alignItems: 'baseline',
      display: 'flex',
      flexWrap: 'wrap',
      gap: `${theme.spacing(2)}px`,

      [theme.breakpoints.up('md')]: {
        flexWrap: 'nowrap'
      }
    },

    column: {
      flexGrow: 1,
      minWidth: '100%',

      [theme.breakpoints.up('md')]: {
        minWidth: 'auto'
      }
    },

    location: {
      flexShrink: 1
    }
  }),
  {
    name: 'Profile'
  }
)

const THIS_YEAR = getYear(new Date())

const AGE_MAX = 120 // Lucky reader.
const AGE_MIN = 18

const BIRTH_YEAR_MAX = THIS_YEAR - AGE_MIN
const BIRTH_YEAR_MIN = THIS_YEAR - AGE_MAX

const BIRTH_YEARS = (() => {
  const years = []

  for (let i = BIRTH_YEAR_MAX; i >= BIRTH_YEAR_MIN; i -= 1) {
    years.push(i)
  }

  return years
})()

const ID_BIRTH_YEARS = 'birth-years'
const ID_COUNTRIES = 'countries'

const OPTIONS_COUNTRY = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo, Democratic Republic of the',
  'Congo, Republic of the',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic (Czechia)',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor (Timor-Leste)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Ivory Coast',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar (Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Korea',
  'North Macedonia (Macedonia)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe'
]

const OPTIONS_NEIGHBORHOOD = ['Urban', 'Rural', 'Suburban']

const OPTIONS_GENDER = ['Female', 'Male', 'Other']

const OPTIONS_RACE = [
  'American Indian or Alaska Native',
  'Asian',
  'Black or African American',
  'Hispanic or Latino',
  'Middle Eastern',
  'Native Hawaiian or Other Pacific Islander',
  'White',
  'Other'
]

const OPTIONS_EDUCATION = [
  'Less than high school',
  'High School',
  'Some College',
  'Bachelors Degree',
  'Professional Degree or Higher'
]

const OPTIONS_SEXUALITY = ['Heterosexual/Straight', 'LGBTQIA+']
const OPTIONS_RELATIONSHIP = ['Single', 'In a relationship', 'Divorced or Widowed']
const OPTIONS_PARENTAL = ['No children under 14 in the home', 'Children under 14 in the home']

const OPTIONS_INCOME = [
  '0 - $24,999',
  '$25k - $39,999',
  '$40k - $59,999',
  '$60k - $79,999',
  '$80k - $99,999',
  '$100k - $149,999',
  '$150k or more'
]

const OPTIONS_FIRST_TO_SEE = [
  'I am often the first to see a movie or show',
  'I wait for friends/family to recommend a movie or show'
]

const OPTIONS_GENRE = [
  'Action',
  'Adventure',
  'Animation',
  'Comedy',
  'Documentary',
  'Drama',
  'Fantasy',
  'Horror',
  'Romance',
  'Science Fiction',
  'Western',
  'Period',
  'Musical',
  'Sports',
  'War'
]

const OPTIONS_SCRIPT_COUNT = ['0', '1 to 10', 'More than 10']
const OPTIONS_VENUE = ['In a movie theater', 'At home/on my computer']

const OPTIONS_MEDIA_TYPE = [
  'More movies than scripted series',
  'More scripted series than movies',
  'More Docs/specials/reality than scripted shows and film',
  'More Instagram/Tiktok/Youtube than any of the above'
]

const OPTIONS_STREAMING = [
  'Movies',
  'Scripted series',
  'Sports',
  'Specials/Docs/Reality Shows',
  'Youtube/Mobile content'
]

const OPTIONS_HOURS = ['Less than 5 hours', '6 to 10 hours', '10 to 20 hours', '20+hours']

const OPTIONS_SERVICES = [
  'Netflix',
  'Amazon Prime',
  'Hulu',
  'HBOmax/Max',
  'Apple TV+',
  'Paramount+',
  'Disney+',
  'Showtime',
  'Starz',
  'Criterion Channel',
  'ESPN',
  'Peacock',
  'Youtube+',
  OPTION_NONE
]

const OPTIONS_ATTENDANCE = [
  'Once a week',
  '2+ times a month',
  'Once a month',
  'Once every couple of months',
  'A couple times a year',
  'I tend not to watch movies in theaters'
]

const CHECKBOX_GROUP_RACE = arrayToKeyValue(OPTIONS_RACE)
const CHECKBOX_GROUP_GENRE = arrayToKeyValue(OPTIONS_GENRE)
const CHECKBOX_GROUP_SERVICES = arrayToKeyValue(OPTIONS_SERVICES)

const RADIO_GROUP_NEIGHBORHOOD = arrayToKeyValue(OPTIONS_NEIGHBORHOOD)
const RADIO_GROUP_GENDER = arrayToKeyValue(OPTIONS_GENDER)
const RADIO_GROUP_EDUCATION = arrayToKeyValue(OPTIONS_EDUCATION)
const RADIO_GROUP_SEXUALITY = arrayToKeyValue(OPTIONS_SEXUALITY)
const RADIO_GROUP_RELATIONSHIP = arrayToKeyValue(OPTIONS_RELATIONSHIP)
const RADIO_GROUP_PARENTAL = arrayToKeyValue(OPTIONS_PARENTAL)
const RADIO_GROUP_INCOME = arrayToKeyValue(OPTIONS_INCOME)
const RADIO_GROUP_FIRST_TO_SEE = arrayToKeyValue(OPTIONS_FIRST_TO_SEE)
const RADIO_GROUP_SCRIPT_COUNT = arrayToKeyValue(OPTIONS_SCRIPT_COUNT)
const RADIO_GROUP_VENUE = arrayToKeyValue(OPTIONS_VENUE)
const RADIO_GROUP_MEDIA_TYPE = arrayToKeyValue(OPTIONS_MEDIA_TYPE)
const RADIO_GROUP_STREAMING = arrayToKeyValue(OPTIONS_STREAMING)
const RADIO_GROUP_HOURS = arrayToKeyValue(OPTIONS_HOURS)
const RADIO_GROUP_ATTENDANCE = arrayToKeyValue(OPTIONS_ATTENDANCE)

const SingleSelect = props => {
  const { label, name, all, value, setValue } = props

  const classes = useStyles(props)
  return (
    <TitledBorder
      classes={{
        fieldset: classes.titledBorder,
        legend: clsx(classes.titledBorderLegend, !value && classes.incomplete)
      }}
      title={label}
    >
      <RadioSelect
        className={classes.field}
        column
        aria-label="select"
        all={all}
        name={name}
        value={value}
        setValue={setValue}
      />
    </TitledBorder>
  )
}

const Profile = props => {
  const {
    birthYear,
    setBirthYear,
    postalCode,
    setPostalCode,
    country,
    setCountry,
    urbanicity,
    setUrbanicity,
    gender,
    setGender,
    race,
    setRace,
    education,
    setEducation,
    sexuality,
    setSexuality,
    relationshipStatus,
    setRelationshipStatus,
    occupation,
    setOccupation,
    filmCareerAspiration,
    setFilmCareerAspiration,
    filmCareerCurrent,
    setFilmCareerCurrent,
    parentalStatus,
    setParentalStatus,
    householdIncomeRange,
    setHouseholdIncomeRange,
    firstToSee,
    setFirstToSee,
    reliesOnReviews,
    setReliesOnReviews,
    genrePreferences,
    setGenrePreferences,
    outsideScriptReading,
    setOutsideScriptReading,
    venuePreference,
    setVenuePreference,
    mediaTypeRanking,
    setMediaTypeRanking,
    streamingPreference,
    setStreamingPreference,
    tvOrMovieWeeklyHours,
    setTvOrMovieWeeklyHours,
    streamingServices,
    setStreamingServices,
    nonPandemicTheaterAttendance,
    setNonPandemicTheaterAttendance,
    textMessage,
    setTextMessage
  } = props

  const handleBirthYearChange = event => setBirthYear(Number(event.target.value))
  const handlePostalCodeChange = event => setPostalCode(event.target.value)
  const handleCountryChange = event => setCountry(event.target.value)
  const handleOccupationChange = event => setOccupation(event.target.value)
  const handleTextMessageChange = event => setTextMessage(event.target.value)

  // This one is a bit of an outlier because the UI is for a select but the value is boolean.
  const setFirstToSeeValue = firstToSeeSelection =>
    setFirstToSee(firstToSeeSelection === '' ? null : firstToSeeSelection === OPTIONS_FIRST_TO_SEE[0])

  const location = getByZipCode(postalCode)
  const { city, state } = location ?? {}

  const classes = useStyles(props)
  return (
    <section className={classes.root}>
      <datalist id={ID_BIRTH_YEARS}>
        {BIRTH_YEARS.map(year => (
          <option key={year} value={year} label={year} />
        ))}
      </datalist>

      <datalist id={ID_COUNTRIES}>
        {OPTIONS_COUNTRY.map(country => (
          <option key={country} value={country} label={country} />
        ))}
      </datalist>

      <TextField
        className={classes.field}
        variant="outlined"
        label="What year were you born?"
        type="number"
        name="birth_year"
        inputProps={{ list: ID_BIRTH_YEARS, min: BIRTH_YEAR_MIN, max: BIRTH_YEAR_MAX }}
        InputLabelProps={{ error: !birthYear, classes: { error: classes.incomplete } }}
        helperText={[
          `Please enter a year from ${BIRTH_YEAR_MIN} to ${BIRTH_YEAR_MAX}.`,
          'Must be 18 years or older to read.'
        ].join(' ')}
        value={birthYear || ''}
        onChange={handleBirthYearChange}
      />

      <section className={classes.multiColumn}>
        <TextField
          className={clsx(classes.field, classes.column)}
          variant="outlined"
          label="What is your postal code?"
          name="postal_code"
          InputLabelProps={{ error: !postalCode, classes: { error: classes.incomplete } }}
          value={postalCode}
          onChange={handlePostalCodeChange}
        />

        <TextField
          className={clsx(classes.field, classes.column)}
          variant="outlined"
          label="What country do you live in?"
          name="country"
          inputProps={{ list: ID_COUNTRIES }}
          InputLabelProps={{ error: !country, classes: { error: classes.incomplete } }}
          value={country || ''}
          onChange={handleCountryChange}
        />

        {country === 'United States' && location && (
          <span className={classes.location}>
            {city}, {state}
          </span>
        )}
      </section>

      <SingleSelect
        label="Which best describes your neighborhood?"
        name="urbanicity"
        all={RADIO_GROUP_NEIGHBORHOOD}
        value={urbanicity}
        setValue={setUrbanicity}
      />

      <SingleSelect
        label="What is your Gender identity?"
        name="gender"
        all={RADIO_GROUP_GENDER}
        value={gender}
        setValue={setGender}
      />

      <TitledBorder
        classes={{
          fieldset: classes.titledBorder,
          legend: clsx(classes.titledBorderLegend, emptyArray(race) && classes.incomplete)
        }}
        title="What is your race/ethnicity?"
        help="Check all that apply, and you must check at least one."
      >
        <CheckboxMultiselect all={CHECKBOX_GROUP_RACE} current={race} setCurrent={setRace} ariaLabel="race/ethnicity" />
      </TitledBorder>

      <SingleSelect
        label="What is your highest education level?"
        name="education"
        all={RADIO_GROUP_EDUCATION}
        value={education}
        setValue={setEducation}
      />

      <SingleSelect
        label="How do you identify your sexual orientation?"
        name="sexuality"
        all={RADIO_GROUP_SEXUALITY}
        value={sexuality}
        setValue={setSexuality}
      />

      <SingleSelect
        label="What is your relationship status?"
        name="relationship_status"
        all={RADIO_GROUP_RELATIONSHIP}
        value={relationshipStatus}
        setValue={setRelationshipStatus}
      />

      <TextField
        className={classes.field}
        variant="outlined"
        label="What occupation provides your primary income?"
        name="occupation"
        inputProps={{ maxLength: 25 }}
        InputLabelProps={{ error: !occupation, classes: { error: classes.incomplete } }}
        value={occupation}
        onChange={handleOccupationChange}
      />

      <TitledBorder
        classes={{
          fieldset: classes.titledBorder,
          legend: clsx(classes.titledBorderLegend, emptyBool(filmCareerAspiration) && classes.incomplete)
        }}
        title="I aspire to have a career in the film business?"
      >
        <BooleanInput value={filmCareerAspiration} setValue={setFilmCareerAspiration} />
      </TitledBorder>

      <TitledBorder
        classes={{
          fieldset: classes.titledBorder,
          legend: clsx(classes.titledBorderLegend, emptyBool(filmCareerCurrent) && classes.incomplete)
        }}
        title="I currently work in the film business:"
      >
        <BooleanInput value={filmCareerCurrent} setValue={setFilmCareerCurrent} />
      </TitledBorder>

      <SingleSelect
        label="Which best describes your parental status?"
        name="parental_status"
        all={RADIO_GROUP_PARENTAL}
        value={parentalStatus}
        setValue={setParentalStatus}
      />

      <SingleSelect
        label="What is your household income?"
        name="household_income_range"
        all={RADIO_GROUP_INCOME}
        value={householdIncomeRange}
        setValue={setHouseholdIncomeRange}
      />

      <SingleSelect
        label="Which answer best describes you?"
        name="first_to_see"
        all={RADIO_GROUP_FIRST_TO_SEE}
        value={
          firstToSee === null || firstToSee === undefined
            ? ''
            : firstToSee
            ? OPTIONS_FIRST_TO_SEE[0]
            : OPTIONS_FIRST_TO_SEE[1]
        }
        setValue={setFirstToSeeValue}
      />

      <TitledBorder
        classes={{
          fieldset: classes.titledBorder,
          legend: clsx(classes.titledBorderLegend, emptyBool(reliesOnReviews) && classes.incomplete)
        }}
        title="I rely on reviews or ratings to decide if I'll watch something"
      >
        <BooleanInput value={reliesOnReviews} setValue={setReliesOnReviews} />
      </TitledBorder>

      <TitledBorder
        classes={{
          fieldset: classes.titledBorder,
          legend: clsx(classes.titledBorderLegend, emptyArray(genrePreferences) && classes.incomplete)
        }}
        title="What are your favorite movie genres?"
        help="Check all that apply, and you must check at least one."
      >
        <CheckboxMultiselect
          all={CHECKBOX_GROUP_GENRE}
          current={genrePreferences}
          setCurrent={setGenrePreferences}
          ariaLabel="genres"
        />
      </TitledBorder>

      <SingleSelect
        label="How many screenplays have you read?"
        name="outside_script_reading"
        all={RADIO_GROUP_SCRIPT_COUNT}
        value={outsideScriptReading}
        setValue={setOutsideScriptReading}
      />

      <SingleSelect
        label="I prefer to see films:"
        name="venue_preference"
        all={RADIO_GROUP_VENUE}
        value={venuePreference}
        setValue={setVenuePreference}
      />

      <SingleSelect
        label="I watch:"
        name="media_type_ranking"
        all={RADIO_GROUP_MEDIA_TYPE}
        value={mediaTypeRanking}
        setValue={setMediaTypeRanking}
      />

      <SingleSelect
        label="When streaming, which do you watch most often:"
        name="streaming_preference"
        all={RADIO_GROUP_STREAMING}
        value={streamingPreference}
        setValue={setStreamingPreference}
      />

      <SingleSelect
        label="Approximately how many hours per week do you watch movies or TV?"
        name="tv_or_movie_weekly_hours"
        all={RADIO_GROUP_HOURS}
        value={tvOrMovieWeeklyHours}
        setValue={setTvOrMovieWeeklyHours}
      />

      <TitledBorder
        classes={{
          fieldset: classes.titledBorder,
          legend: clsx(classes.titledBorderLegend, emptyArray(streamingServices) && classes.incomplete)
        }}
        title="Which streaming servies do you subscribe to?"
        help="Check all that apply."
      >
        <CheckboxMultiselect
          all={CHECKBOX_GROUP_SERVICES}
          current={streamingServices}
          setCurrent={setStreamingServices}
          ariaLabel="streaming services"
        />
      </TitledBorder>

      <SingleSelect
        label="In non-pandemic times, how many times did you attend movies in theaters, on average?"
        name="pre_covid_theater_attendance"
        all={RADIO_GROUP_ATTENDANCE}
        value={nonPandemicTheaterAttendance}
        setValue={setNonPandemicTheaterAttendance}
      />

      <TextField
        className={classes.field}
        variant="outlined"
        label="Text Messaging"
        type="tel"
        name="text-message"
        placeholder="(555) 555-5555"
        helperText="Please provide your text-messaging number."
        value={textMessage}
        onChange={handleTextMessageChange}
      />
    </section>
  )
}

Profile.propTypes = {
  birthYear: PropTypes.number,
  setBirthYear: PropTypes.func,
  postalCode: PropTypes.string,
  setPostalCode: PropTypes.func,
  country: PropTypes.string,
  setCountry: PropTypes.func,
  urbanicity: PropTypes.string,
  setUrbanicity: PropTypes.func,
  gender: PropTypes.string,
  setGender: PropTypes.func,
  race: PropTypes.arrayOf(PropTypes.string),
  setRace: PropTypes.func,
  education: PropTypes.string,
  setEducation: PropTypes.func,
  sexuality: PropTypes.string,
  setSexuality: PropTypes.func,
  relationshipStatus: PropTypes.string,
  setRelationshipStatus: PropTypes.func,
  occupation: PropTypes.string,
  setOccupation: PropTypes.func,
  filmCareerAspiration: PropTypes.bool,
  setFilmCareerAspiration: PropTypes.func,
  filmCareerCurrent: PropTypes.bool,
  setFilmCareerCurrent: PropTypes.func,
  parentalStatus: PropTypes.string,
  setParentalStatus: PropTypes.func,
  householdIncomeRange: PropTypes.string,
  setHouseholdIncomeRange: PropTypes.func,
  firstToSee: PropTypes.bool,
  setFirstToSee: PropTypes.func,
  reliesOnReviews: PropTypes.bool,
  setReliesOnReviews: PropTypes.func,
  genrePreferences: PropTypes.arrayOf(PropTypes.string),
  setGenrePreferences: PropTypes.func,
  outsideScriptReading: PropTypes.string,
  setOutsideScriptReading: PropTypes.func,
  venuePreference: PropTypes.string,
  setVenuePreference: PropTypes.func,
  mediaTypeRanking: PropTypes.string,
  setMediaTypeRanking: PropTypes.func,
  streamingPreference: PropTypes.string,
  setStreamingPreference: PropTypes.func,
  tvOrMovieWeeklyHours: PropTypes.string,
  setTvOrMovieWeeklyHours: PropTypes.func,
  streamingServices: PropTypes.arrayOf(PropTypes.string),
  setStreamingServices: PropTypes.func,
  nonPandemicTheaterAttendance: PropTypes.string,
  setNonPandemicTheaterAttendance: PropTypes.func,
  textMessage: PropTypes.string,
  setTextMessage: PropTypes.func
}

export default Profile

/**
 * MultiselectInput captures information for the multiselect question type. The specific UI choice
 * made here is to render them as a list of checkbox items.
 */
import React, { useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'

import CheckboxMultiselect from './CheckboxMultiselect'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'block',
      margin: theme.spacing(1, 0)
    }
  }),
  {
    name: 'MultiselectInput'
  }
)

const MultiselectInput = props => {
  const { options, column, value, setValue } = props
  const { choices } = options ?? {}

  const items = useMemo(() => Object.fromEntries(choices?.map(choice => [choice, choice]) ?? []), [choices])

  const [selected, setSelected] = useState(value ?? [])

  useEffect(() => {
    // Avoid replacing an empty array with a “different” empty array since the value is essentially unchanged
    // in that case. (thus preventing an infinite effect loop)
    setSelected(selected => value ?? (selected.length === 0 ? selected : []))
  }, [value])

  useEffect(() => {
    if (setValue) {
      setValue(selected)
    }
  }, [setValue, selected])

  const classes = useStyles(props)
  return (
    <span className={classes.root}>
      <CheckboxMultiselect all={items} column={column} current={selected} setCurrent={setSelected} />
    </span>
  )
}

MultiselectInput.propTypes = {
  options: PropTypes.shape({
    choices: PropTypes.arrayOf(PropTypes.string)
  }),

  column: PropTypes.bool, // Whether to display the checkboxes as a column.
  value: PropTypes.arrayOf(PropTypes.string), // The answer to display.
  setValue: PropTypes.func // Function to call if an answer changes.
}

export default MultiselectInput

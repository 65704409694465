/*
  The settings object is the centralized place for deployment- or environment-specific values (i.e., settings, duh).
  Until we find a better way, the values in this file may be edited prior to deployment. Webpack "bakes" its values into
  the resulting build product.
*/
import constProperty from './constProperty'

let settings = {}

constProperty(settings, 'apiHost', process.env.REACT_APP_API)

export default settings

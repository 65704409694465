import constProperty from '../constProperty'

const GENRES = {}

// key = internal value; value = display value
constProperty(GENRES, 'action', 'Action', { enumerable: true })
constProperty(GENRES, 'biopic', 'Biopic', { enumerable: true })
constProperty(GENRES, 'comedy', 'Comedy', { enumerable: true })
constProperty(GENRES, 'drama', 'Drama', { enumerable: true })
constProperty(GENRES, 'fantasy/scifi', 'Fantasy/Scifi', { enumerable: true })
constProperty(GENRES, 'horror', 'Horror', { enumerable: true })
constProperty(GENRES, 'musical', 'Musical', { enumerable: true })
constProperty(GENRES, 'romance', 'Romance', { enumerable: true })
constProperty(GENRES, 'thriller', 'Thriller', { enumerable: true })

constProperty(GENRES, 'keys', Object.keys(GENRES))

export default GENRES

import React, { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputAdornment from '@material-ui/core/InputAdornment'
import LinearProgress from '@material-ui/core/LinearProgress'
import TextField from '@material-ui/core/TextField'

import WarningIcon from '@material-ui/icons/Warning'

import { confirmResetPassword } from './api'

import TitledBorder from './TitledBorder'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(1, 4)
    },

    row: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
      width: '100%',

      '& > *:not(:first-child)': {
        marginLeft: theme.spacing(4)
      }
    },

    credentials: {
      borderRadius: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(4),
      paddingBottom: theme.spacing(6),
      paddingTop: theme.spacing(3),
      width: '100%',

      '& > *': {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '66.6%' // Baphometian Easter egg.
      },

      '& > *:not(:first-child)': {
        marginTop: theme.spacing(2)
      }
    },

    fieldValidity: {
      alignItems: 'center',
      display: 'inline-flex',
      fontSize: theme.typography.pxToRem(14),
      padding: theme.spacing(0.25, 1.25),

      '& svg': {
        marginRight: theme.spacing(0.75)
      }
    },

    invalidField: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white
    },

    validField: {
      backgroundColor: theme.palette.success.main
    },

    interstitial: {
      alignItems: 'center',
      display: 'flex',
      '& > *:not(:first-child)': {
        marginLeft: theme.spacing(1)
      }
    },

    resetPasswordProgress: {
      flexGrow: 1,
      marginLeft: theme.spacing(1)
    },

    resetPasswordErrorMessage: {
      fontSize: theme.typography.h5.fontSize,
      textAlign: 'center'
    }
  }),
  {
    name: 'ResetPasswordConfirmation'
  }
)

const PASSWORD_PATTERN = '.{12,}'

const ResetPasswordConfirmation = props => {
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const [resetPasswordInProgress, setResetPasswordInProgress] = useState(false)
  const [resetPasswordErrorMessage, setResetPasswordErrorMessage] = useState('')

  const history = useHistory()
  const match = useRouteMatch()
  const resetId = match && match.params.resetId
  const passwordIsLongEnough = password.length >= 12
  const passwordIsConfirmed = password === passwordConfirmation
  const passwordError = Boolean(password && !passwordIsLongEnough)
  const passwordConfirmationError = Boolean(password && passwordConfirmation && !passwordIsConfirmed)
  const canConfirm = passwordIsLongEnough && passwordIsConfirmed

  const handlePasswordChange = event => setPassword(event.target.value)
  const handlePasswordConfirmationChange = event => setPasswordConfirmation(event.target.value)
  const handleResetPasswordErrorDialogClose = event => setResetPasswordErrorMessage('')

  const handleResetPasswordConfirmation = async event => {
    event.preventDefault()

    setResetPasswordInProgress(true)
    try {
      await confirmResetPassword(resetId, { password })
      setResetPasswordInProgress(false)
      history.push('/')
    } catch (error) {
      setResetPasswordInProgress(false)
      if (error.response) {
        const errorResult = await error.response.json()
        setResetPasswordErrorMessage(errorResult.message)
      } else {
        setResetPasswordErrorMessage(error.message)
      }
    }
  }

  const classes = useStyles(props)
  return (
    <form className={classes.root} onSubmit={handleResetPasswordConfirmation}>
      <div className={classes.row}>
        <strong>Let’s get you back in there! Please supply a new password for your account.</strong>
        {resetPasswordInProgress && <LinearProgress className={classes.resetPasswordProgress} />}
      </div>

      <Dialog
        open={Boolean(resetPasswordErrorMessage)}
        onClose={handleResetPasswordErrorDialogClose}
        aria-labelledby="reset-password-error-dialog-title"
        area-describedby="reset-password-error-dialog-description"
      >
        <DialogTitle id="reset-password-error-dialog-title">Password Reset Confirmation Problem</DialogTitle>

        <DialogContent>
          <DialogContentText id="reset-password-error-dialog-description">
            Sorry, but a problem was encountered upon password reset:
          </DialogContentText>

          <DialogContentText className={classes.resetPasswordErrorMessage}>
            <em>{resetPasswordErrorMessage}</em>
          </DialogContentText>

          <DialogContentText>
            Please make another request to reset your password, then try again with that new link.
          </DialogContentText>

          <DialogActions>
            <Button variant="contained" color="primary" autoFocus onClick={handleResetPasswordErrorDialogClose}>
              Acknowledge
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <TitledBorder className={classes.credentials}>
        <TextField
          label="Password"
          type="password"
          name="password"
          required
          inputProps={{
            pattern: PASSWORD_PATTERN
          }}
          autoComplete="new-password"
          error={passwordError}
          helperText="Please supply a password (12-character minimum)."
          InputProps={{
            endAdornment: passwordError && (
              <InputAdornment position="end">
                <span className={clsx(classes.fieldValidity, classes.invalidField)}>
                  <WarningIcon fontSize="inherit" />
                  <span>You must supply a password of twelve (12) or more characters.</span>
                </span>
              </InputAdornment>
            )
          }}
          value={password}
          onChange={handlePasswordChange}
        />

        <TextField
          label="Password Confirmation"
          type="password"
          name="password-confirmation"
          required
          inputProps={{
            pattern: PASSWORD_PATTERN
          }}
          autoComplete="new-password"
          error={passwordConfirmationError}
          helperText="Please confirm your password."
          InputProps={{
            endAdornment: passwordConfirmationError && (
              <InputAdornment position="end">
                <span className={clsx(classes.fieldValidity, classes.invalidField)}>
                  <WarningIcon fontSize="inherit" />
                  <span>Passwords must match.</span>
                </span>
              </InputAdornment>
            )
          }}
          value={passwordConfirmation}
          onChange={handlePasswordConfirmationChange}
        />
      </TitledBorder>

      <div className={classes.row}>
        <div>
          <Button type="submit" variant="contained" color="primary" disabled={!canConfirm}>
            Confirm Password Reset
            {resetPasswordInProgress && (
              <CircularProgress className={classes.resetPasswordProgress} color="inherit" size={14} />
            )}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default ResetPasswordConfirmation

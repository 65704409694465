import constProperty from './constProperty'

import settings from './settings'

const constants = {}
constProperty(constants, 'USER_KEY', 'zoodiker-username')
constProperty(constants, 'TOKEN_KEY', 'zoodiker-token')
constProperty(constants, 'TYPE_PDF', 'application/pdf')
constProperty(constants, 'ROLE_SUPERUSER', 'SUPERUSER')

let currentUsername = null
let currentUser = null
let currentToken = null
let tokenTimeout = null

const urlFor = service => `${settings.apiHost}/${service}`

const HTTP_OK = 200
const HTTP_CREATED = 201
const HTTP_BAD_REQUEST = 400
const HTTP_UNAUTHORIZED = 401
const HTTP_NOT_FOUND = 404
const HTTP_CONFLICT = 409

const ROLE_SUPERUSER = constants.ROLE_SUPERUSER
const TYPE_PDF = constants.TYPE_PDF

// These are somewhat arbitrary so are certainly subject to tuning, as needed.
const RETRY_COUNT = 4
const RETRY_MINIMUM = 1024
const RETRY_RANGE = 8192

const TOKEN_TIMEOUT = 28 * 60 * 1000 // Just under the underlying 30-minute timeout

const throwResponseError = response => {
  const error = new Error(response.statusText)
  error.response = response
  throw error
}

const emitNativeError = error => {
  // For native errors, we simulate an embedded response object to give all errors a consistent format.
  error.response = {
    statusText: error.name,
    json: () =>
      Promise.resolve({
        message: error.message
      })
  }

  throw error
}

const statusCheck = successStatuses => response => {
  if (successStatuses.includes(response.status)) {
    return response
  } else {
    throwResponseError(response)
  }
}

const okCheck = statusCheck([HTTP_OK])
const createdCheck = statusCheck([HTTP_CREATED])
const upsertCheck = statusCheck([HTTP_OK, HTTP_CREATED])

const setCurrentToken = token => {
  currentToken = token
  tokenTimeout = setTimeout(refreshToken, TOKEN_TIMEOUT)
}

const tokenHeader = () => ({
  Authorization: `Bearer ${currentToken}`
})

const contentHeader = () => ({
  'Content-Type': 'application/json'
})

const apiHeader = () => ({
  ...tokenHeader(),
  ...contentHeader()
})

const apiFetch = async (url, customHeader, options = {}) => {
  const requestOptions = retryCount => {
    const requiredHeaders = {
      ...(customHeader || apiHeader() || {}),
      ...(retryCount === undefined
        ? {}
        : {
            'X-Zoodiker-Retry-Count': RETRY_COUNT - retryCount + 1
          })
    }

    // Funky logic is meant to make sure that our necessary headers always take priority over headers that might have
    // been included in the settings.
    const { headers: settingsHeaders } = options || {}
    return {
      ...options,
      headers: {
        ...(settingsHeaders || {}),
        ...requiredHeaders
      }
    }
  }

  const authorizedResponse = async retryCount => {
    try {
      const response = await fetch(url, requestOptions(retryCount))

      if (response.status === HTTP_UNAUTHORIZED) {
        /* TODO Token reauthentication has not been implemented yet.
        if (!forbiddenErrorPromise && forbiddenErrorPromiseProvider) {
          forbiddenErrorPromise = forbiddenErrorPromiseProvider()
        }

        if (forbiddenErrorPromise) {
          await forbiddenErrorPromise
          forbiddenErrorPromise = null
          return authorizedResponse()
        }
        */
      }

      return response
    } catch (error) {
      // 5xx “responses” throw errors and do not actually produce a response, so we can only retry
      // and cannot tell the difference between 500, 502, 504, etc.
      if (retryCount !== 0) {
        // Thank you https://javascript.info/task/delay-promise
        await new Promise(resolve => setTimeout(resolve, RETRY_RANGE * Math.random() + RETRY_MINIMUM))
        return authorizedResponse(retryCount === undefined ? RETRY_COUNT : retryCount - 1)
      } else {
        // TODO Send a log message instead of (or in addition to?) throwing, so that these errors can
        //      stored for further triaging.
        throw error
      }
    }
  }

  return await authorizedResponse()
}

const refreshToken = () =>
  apiFetch(urlFor('tokens/refresh'), tokenHeader(), {
    method: 'POST'
  })
    .then(okCheck, emitNativeError)
    .then(response => response.json())
    .then(tokenResponse => setCurrentToken(tokenResponse.token))

const checkForPersistentToken = () => {
  const username = localStorage.getItem(constants.USER_KEY)
  const token = localStorage.getItem(constants.TOKEN_KEY)

  if (username && token) {
    currentToken = token
    refreshToken().then(() => {
      currentUsername = username
    })
  }

  localStorage.removeItem(constants.USER_KEY)
  localStorage.removeItem(constants.TOKEN_KEY)
}

const user = () => currentUsername
const userRole = () => Boolean(currentUsername) // For now, there is only one role: being a user.

const trackUserLogin = () => {
  const identify = window.analytics && window.analytics.identify
  const currentUser = user()
  if (identify && currentUser) {
    identify(currentUser)
  }
}

const login = async ({ username, password }) => {
  const loginResponse = await apiFetch(urlFor('tokens'), contentHeader(), {
    method: 'POST',
    body: JSON.stringify({
      username,
      password
    })
  })

  // Custom handler because we don't want to emit an "unexpected error" with a login failure.
  if (loginResponse.status === HTTP_OK) {
    const tokenResponse = await loginResponse.json()
    currentUsername = tokenResponse.username
    setCurrentToken(tokenResponse.token)
    trackUserLogin()
    return true
  } else {
    throwResponseError(loginResponse)
  }
}

const logout = async () => {
  try {
    await apiFetch(urlFor('tokens'), tokenHeader(), {
      method: 'DELETE'
    })
  } catch (error) {
    // We don't have to care if logout triggers an error...do we?
  }

  currentUser = null
  currentUsername = null
  currentToken = null
  if (tokenTimeout) {
    clearInterval(tokenTimeout)
  }
}

const lookupUsername = username =>
  fetch(urlFor(`usernameavailability/${username}`))
    .then(okCheck, emitNativeError)
    .then(response => response.json())

const findUsers = (query, exceptions) =>
  apiFetch(`${urlFor('users')}?q=${query}`)
    .then(okCheck, emitNativeError)
    .then(response => response.json())
    .then(users => users.users.filter(user => !exceptions.includes(user.username) && currentUsername !== user.username))

const findGroups = (query, exceptions) =>
  apiFetch(`${urlFor('groups')}?q=${query}`)
    .then(okCheck, emitNativeError)
    .then(response => response.json())
    .then(groups => groups.groups.filter(group => !exceptions.includes(group)))

const confirmSignup = (key, payload) =>
  apiFetch(urlFor(`signups/${key}`), contentHeader(), {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then(createdCheck, emitNativeError)
    .then(response => response.json())
    .catch(error => {
      if (error.response.status === HTTP_BAD_REQUEST || error.response.status === HTTP_CONFLICT) {
        return error.response.json()
      } else {
        throwResponseError(error.response)
      }
    })
    .then(finalResult => {
      finalResult.ok = Boolean(finalResult.user)
      return finalResult
    })

const signup = email =>
  apiFetch(urlFor('signups'), contentHeader(), {
    method: 'POST',
    body: JSON.stringify({ email })
  })
    .then(createdCheck, emitNativeError)
    .then(response => response.json())

const confirmResetPassword = (key, payload) =>
  apiFetch(urlFor(`passwordresetters/${key}`), contentHeader(), {
    method: 'POST',
    body: JSON.stringify(payload)
  })
    .then(okCheck, emitNativeError)
    .then(response => response.json())

const resetPassword = email =>
  apiFetch(urlFor('passwordresetters'), contentHeader(), {
    method: 'POST',
    body: JSON.stringify({ email })
  })
    .then(createdCheck, emitNativeError)
    .then(response => response.json())

const getMeUnconditionally = () =>
  apiFetch(urlFor('me'))
    .then(okCheck, emitNativeError)
    .then(response => response.json())

const getMe = async () => {
  if (!currentUser) {
    currentUser = await getMeUnconditionally()
  }

  return currentUser
}

const patchMe = newMe =>
  apiFetch(urlFor('me'), null, {
    method: 'PATCH',
    body: JSON.stringify(newMe)
  })
    .then(okCheck, emitNativeError)
    .then(response => {
      // A PATCH invalidates the current user object so that the next request will reload it.
      currentUser = null
      return response.json()
    })

const getDemographics = () =>
  apiFetch(urlFor('demographics'))
    .then(okCheck, emitNativeError)
    .then(response => response.json())
    .then(container => container.form)
    .catch(error => {
      if (error.response.status === HTTP_NOT_FOUND) {
        // Missing demographics is treated like empty demographics.
        return {}
      } else {
        throwResponseError(error.response)
      }
    })

const putDemographics = demographics =>
  apiFetch(urlFor('demographics'), null, {
    method: 'PUT',
    body: JSON.stringify(demographics)
  })
    .then(okCheck, emitNativeError)
    .then(response => response.json())
    .then(container => container.form)

const getUserSnippet = username =>
  apiFetch(urlFor(`users/${username}/snippet`))
    .then(okCheck, emitNativeError)
    .then(response => response.json())
    .then(userContainer => userContainer.user)

const getScriptBucket = (bucket, params) => {
  params.set('bucket', bucket)
  return apiFetch(`${urlFor('scripts')}?${params}`)
    .then(okCheck, emitNativeError)
    .then(response => response.json())
}

const getAuthoredScripts = params => getScriptBucket('authored', params)
const getNewScripts = params => getScriptBucket('new', params)
const getPublicScripts = params => getScriptBucket('public', params)
const getScriptsInProgress = params => getScriptBucket('in_progress', params)
const getFinishedScripts = params => getScriptBucket('finished', params)

const getScript = id =>
  apiFetch(`${urlFor('scripts')}/${id}`)
    .then(okCheck, emitNativeError)
    .then(response => response.json())

const getScriptResponseAverages = script =>
  apiFetch(urlFor(`scripts/${script.id}/average_script_rating`))
    .then(okCheck, emitNativeError)
    .then(response => response.json())
    .then(scriptResponseAverages => scriptResponseAverages.averages)

const getScriptResponses = (script, params) =>
  apiFetch(urlFor(`scripts/${script.id}/responses?${params}`))
    .then(okCheck, emitNativeError)
    .then(response => response.json())

const getScriptSurveyQuestions = scriptId =>
  apiFetch(urlFor(`scripts/${scriptId}/survey/questions`))
    .then(okCheck, emitNativeError)
    .then(response => response.json())
    .then(json => json.questions)

const getScriptSurveyAnswers = scriptId =>
  apiFetch(urlFor(`scripts/${scriptId}/survey/answers`))
    .then(okCheck, emitNativeError)
    .then(response => response.json())

const putScriptSurveyAnswers = (scriptId, { final, answers }) =>
  apiFetch(urlFor(`scripts/${scriptId}/survey/answers`), null, {
    method: 'PUT',
    body: JSON.stringify({ final, answers })
  })
    .then(upsertCheck, emitNativeError)
    .then(response => response.json())

const postScriptResponse = (script, scriptResponse) =>
  apiFetch(`${urlFor('scripts')}/${script.id}/responses`, null, {
    method: 'POST',
    body: JSON.stringify(scriptResponse)
  })
    .then(upsertCheck, emitNativeError)
    .then(response => response.json())

const deleteScriptResponse = scriptResponse =>
  apiFetch(`${urlFor('script_responses')}/${scriptResponse.id}`, null, {
    method: 'DELETE'
  })
    .then(okCheck, emitNativeError)
    .then(response => response.json())

const submitScript = scriptForm =>
  apiFetch(urlFor('scripts'), tokenHeader(), {
    method: 'POST',
    body: scriptForm
  })
    .then(createdCheck, emitNativeError)
    .then(response => response.json())

const editScript = script =>
  apiFetch(`${urlFor('scripts')}/${script.id}`, null, {
    method: 'PATCH',
    body: JSON.stringify(script)
  })
    .then(okCheck, emitNativeError)
    .then(response => response.json())

const deleteScript = script =>
  apiFetch(`${urlFor('scripts')}/${script.id}`, null, {
    method: 'DELETE'
  })
    .then(okCheck, emitNativeError)
    .then(response => response.json())

const getScriptPageUrls = (script, page) =>
  apiFetch(`${urlFor('scripts')}/${script.id}/pages/${page - 1}`)
    .then(okCheck, emitNativeError)
    .then(response => response.json())

const getResponseStatuses = (script, params) =>
  apiFetch(`${urlFor('scripts')}/${script.id}/response_statuses${params ? `?${params}` : ''}`)
    .then(okCheck, emitNativeError)
    .then(response => response.json())

const getPageResponses = (script, page, params) =>
  apiFetch(`${urlFor('scripts')}/${script.id}/pages/${page - 1}/responses${params ? `?${params}` : ''}`)
    .then(okCheck, emitNativeError)
    .then(response => response.json())

const postPageResponse = (script, page, pageResponse) =>
  apiFetch(`${urlFor('scripts')}/${script.id}/pages/${page - 1}/responses`, null, {
    method: 'POST',
    body: JSON.stringify(pageResponse)
  })
    .then(upsertCheck, emitNativeError)
    .then(response => response.json())

const getAllResponses = script =>
  getResponseStatuses(script)
    .then(statusPage => {
      // Page responses are returned together so we have to find the status with the most page responses.
      const pageResponseCount = statusPage.statuses.reduce(
        (current, next) => Math.max(current, next.page_response_count),
        0
      )

      // Thank you S.O. questions/3895478/does-javascript-have-a-method-like-range-to-generate-a-range-within-the-supp
      // TODO Huge limit is artificial; eventually, getPageResponses needs to be paged for real.
      const params = new URLSearchParams()
      params.set('limit', 1000)

      return Promise.all(
        Array.from(Array(pageResponseCount).keys()).map(page => getPageResponses(script, page + 1, params))
      )
    })
    .then(allPageResponses =>
      allPageResponses.reduce((current, next) => {
        next.responses.forEach(pageResponse => {
          // Re-aggregate the responses by reviewer.
          if (!current[pageResponse.reviewer]) {
            current[pageResponse.reviewer] = []
          }

          current[pageResponse.reviewer].push(pageResponse)
        })

        return current
      }, {})
    )

const postTermsAcceptance = script =>
  apiFetch(`${urlFor('scripts')}/${script.id}/terms_acceptances`, null, {
    method: 'POST'
  })
    .then(upsertCheck, emitNativeError)
    .then(response => response.json())

const postPauseDetection = (script, params) =>
  apiFetch(`${urlFor('scripts')}/${script.id}/pause_detections`, null, {
    method: 'POST',
    body: JSON.stringify(params)
  })
    .then(upsertCheck, emitNativeError)
    .then(response => response.json())

const postPauseReasons = (script, params) =>
  apiFetch(`${urlFor('scripts')}/${script.id}/pause_returns`, null, {
    method: 'POST',
    body: JSON.stringify(params)
  })
    .then(upsertCheck, emitNativeError)
    .then(response => response.json())

const postAbandonClick = (script, params) =>
  apiFetch(`${urlFor('scripts')}/${script.id}/abandon_considerations`, null, {
    method: 'POST',
    body: JSON.stringify(params)
  })
    .then(upsertCheck, emitNativeError)
    .then(response => response.json())

checkForPersistentToken()

export {
  ROLE_SUPERUSER,
  TYPE_PDF,
  signup,
  confirmSignup,
  resetPassword,
  confirmResetPassword,
  user,
  userRole,
  login,
  logout,
  lookupUsername,
  findUsers,
  findGroups,
  getMe,
  patchMe,
  getDemographics,
  putDemographics,
  getUserSnippet,
  getAuthoredScripts,
  getNewScripts,
  getPublicScripts,
  getScript,
  editScript,
  submitScript,
  deleteScript,
  getScriptPageUrls,
  getScriptsInProgress,
  getFinishedScripts,
  getScriptResponseAverages,
  getPageResponses,
  postPageResponse,
  getAllResponses,
  getResponseStatuses,
  getScriptResponses,
  getScriptSurveyQuestions,
  getScriptSurveyAnswers,
  putScriptSurveyAnswers,
  postScriptResponse,
  deleteScriptResponse,
  postTermsAcceptance,
  postPauseDetection,
  postPauseReasons,
  postAbandonClick
}

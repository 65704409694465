import React, { useState, useRef, useContext } from 'react'

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'

import CloseIcon from '@material-ui/icons/Close'

import { UpdateContext } from './app/contexts'
import { useSuperuserCheck } from './app/hooks'
import { submitScript } from './api'
import { unexpectedErrorContainer } from './theme'

import ErrorResponse from './ErrorResponse'
import ScriptProperties from './ScriptProperties'

const useStyles = makeStyles(
  theme => ({
    submitTitle: {
      fontSize: theme.typography.pxToRem(48),
      fontWeight: theme.typography.fontWeightRegular,
      paddingBottom: 0
    },

    submitContent: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 0
    },

    submitActions: {
      margin: theme.spacing(0, 2, 1, 2),
      '& .MuiButton-label': {
        // Material UI class names are stable as long as we use the same components.
        fontSize: theme.typography.pxToRem(20),
        padding: theme.spacing(0, 1.5)
      }
    },

    submitProgress: {
      marginLeft: theme.spacing(1)
    },

    closeButton: {
      color: theme.palette.grey[500],
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1)
    },

    uploadPdfInput: {
      display: 'none'
    },

    unexpectedErrorContent: unexpectedErrorContainer(theme)
  }),
  {
    name: 'Submit'
  }
)

const PDF_INPUT_ID = 'choose-pdf-input'

// Yes, C:\fakepath\ is to-spec: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
const FAKEPATH = 'C:\\\\fakepath\\\\'
const FAKEPATH_REGEX = new RegExp(`^${FAKEPATH}`)
const EXTENSION_REGEX = /.[^.]*$/

const scriptTitle = fileInput => fileInput.value.replace(FAKEPATH_REGEX, '').replace(EXTENSION_REGEX, '')

const Submit = props => {
  const [logline, setLogline] = useState('')
  const [genres, setGenres] = useState([])
  const [medium, setMedium] = useState('')
  const [warnings, setWarnings] = useState([])
  const [group, setGroup] = useState([]) // TODO Better name = readers/readerUsers?
  const [groups, setGroups] = useState([]) // TODO Better name = readerGroups? (preserving for now to match API)
  const [eulaChecked, setEulaChecked] = useState(false)
  const [submitScriptInProgress, setSubmitScriptInProgress] = useState(false)
  const [unexpectedError, setUnexpectedError] = useState(null)

  const showGroups = useSuperuserCheck()

  const { open, onClose, onSuccess, script: scriptToReplace } = props
  const { updateScripts } = useContext(UpdateContext)
  const pdfInput = useRef(null)

  const handleEulaChange = event => setEulaChecked(event.target.checked)

  const handleSubmitScript = async event => {
    if (!pdfInput.current || !pdfInput.current.files[0]) {
      return
    }

    setSubmitScriptInProgress(true)

    const fileInput = pdfInput.current
    const scriptForm = new FormData()
    scriptForm.append('content', fileInput.files[0])
    scriptForm.append('title', scriptTitle(fileInput))
    scriptForm.append('logline', logline)
    scriptForm.append('medium', medium)
    group.forEach(groupMember => scriptForm.append('group', groupMember.username))
    groups.forEach(group => scriptForm.append('groups', group))
    genres.forEach(genre => scriptForm.append('genres', genre))
    warnings.forEach(warning => scriptForm.append('warnings', warning))

    if (scriptToReplace) {
      scriptForm.append('replacement_of', scriptToReplace.id)
    }

    try {
      await submitScript(scriptForm)
      fileInput.value = ''

      setSubmitScriptInProgress(false)

      if (onSuccess) {
        onSuccess()
      }

      if (updateScripts) {
        updateScripts()
      }
    } catch (error) {
      setUnexpectedError(error)
      setSubmitScriptInProgress(false)
    }
  }

  const classes = useStyles(props)
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" area-labelledby="submit-dialog-title">
      <form>
        <DialogTitle id="submit-dialog-title" className={classes.submitTitle} disableTypography>
          {scriptToReplace ? 'Replace' : 'Submit'} Your Writing
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {unexpectedError && (
          <DialogContent className={classes.unexpectedErrorContent}>
            <div>
              A problem was encountered with the uploaded file. Please verify that you selected a valid PDF document and
              try again when ready.
            </div>

            <ErrorResponse error={unexpectedError} />
          </DialogContent>
        )}

        <DialogContent className={classes.submitContent}>
          {scriptToReplace && (
            <DialogContentText>
              <em>
                Replacing a script is almost identical to submitting a new script, except that the newly uploaded script
                internally maintains a record of which script it replaced. The previous script (and all of its comments)
                remains available unless you delete it explicitly.
              </em>
            </DialogContentText>
          )}

          <ScriptProperties
            logline={logline}
            setLogline={setLogline}
            genres={genres}
            setGenres={setGenres}
            medium={medium}
            setMedium={setMedium}
            warnings={warnings}
            setWarnings={setWarnings}
            group={group}
            setGroup={setGroup}
            groups={groups}
            setGroups={setGroups}
            showGroups={showGroups}
          />

          <FormControlLabel
            control={<Checkbox name="eula" checked={eulaChecked} onChange={handleEulaChange} />}
            label={
              <span>
                I agree with the{' '}
                <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">
                  Zoodiker terms and conditions
                </a>{' '}
                (opens in new tab).
              </span>
            }
          />
        </DialogContent>

        <DialogActions className={classes.submitActions}>
          <Button disabled={submitScriptInProgress} onClick={onClose}>
            Cancel
          </Button>

          <input
            type="file"
            id={PDF_INPUT_ID}
            className={classes.uploadPdfInput}
            accept="application/pdf"
            disabled={!eulaChecked || submitScriptInProgress}
            ref={pdfInput}
            onChange={handleSubmitScript}
          />

          <label htmlFor={PDF_INPUT_ID}>
            {/* component="span" is needed so click triggers the input type="file" */}
            <Button
              variant="contained"
              color="primary"
              component="span"
              disabled={!eulaChecked || submitScriptInProgress}
            >
              Upload PDF…
              {submitScriptInProgress && (
                <CircularProgress className={classes.submitProgress} color="inherit" size={18} />
              )}
            </Button>
          </label>
        </DialogActions>
      </form>
    </Dialog>
  )
}

Submit.propTypes = {
  script: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
}

export default Submit

import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3'

import PropTypes from 'prop-types'

import { AVERAGE_KEY } from './visualizationHelpers'

const LINE_THICKNESS = 2
const RADIUS = 4
const RING_THICKNESS = RADIUS * 0.75

const TimeToPage = props => {
  const { script, allResponses, reviewerKeys, selectedReviewers } = props
  const graphContainerRef = useRef(null)

  useEffect(() => {
    if (!allResponses) {
      return
    }

    const baseDate = Date.now()
    const graphDate = d => new Date(baseDate + d.epoch)

    const responsesAsOneArray = () => {
      let responsesAsOneArray = []
      Object.keys(allResponses).forEach(reviewer => {
        const reviewerResponses = allResponses[reviewer]
        responsesAsOneArray = responsesAsOneArray.concat(reviewerResponses)
      })

      return responsesAsOneArray
    }

    const dateDomain = () => {
      const dateDomain = d3.extent(responsesAsOneArray(), graphDate)

      // Expand the range by 1% to provide some space on the eventual graph.
      const duration = dateDomain[1] - dateDomain[0]
      dateDomain[1] = new Date(dateDomain[1].valueOf() + duration * 0.01)
      return dateDomain
    }

    const graphContainer = graphContainerRef.current
    if (!graphContainer) {
      return
    }

    const width = graphContainer.offsetWidth
    const height = width // Default to a square graph
    const margin = { top: 20, right: 0, bottom: 30, left: 40 }

    const x = d3
      .scaleTime()
      .domain(dateDomain())
      .range([margin.left, width - margin.right])

    const y = d3
      .scaleLinear()
      .domain([0, script.page_count])
      .nice()
      .range([height - margin.bottom, margin.top])

    const xAxis = g =>
      g.attr('transform', `translate(0,${height - margin.bottom})`).call(d3.axisBottom(x).tickSizeOuter(0))

    const yAxis = g =>
      g
        .attr('transform', `translate(${margin.left},0)`)
        .call(d3.axisLeft(y))
        .call(g => g.select('.domain').remove())

    const line = d3
      .line()
      .x(d => x(graphDate(d)))
      .y(d => y(d.page))

    graphContainer.innerHTML = ''
    const svg = d3.select(graphContainer).append('svg').attr('width', width).attr('height', height)

    svg.append('g').call(xAxis)
    svg.append('g').call(yAxis)

    selectedReviewers
      .filter(reviewer => reviewer !== AVERAGE_KEY) // This graph doesn’t show an average.
      .forEach(reviewer => {
        const data = allResponses[reviewer]
        const colorIndex = reviewerKeys.indexOf(reviewer) % d3.schemeCategory10.length

        svg
          .append('path')
          .datum(data)
          .attr('fill', 'none')
          .attr('stroke', d3.schemeCategory10[colorIndex])
          .attr('stroke-width', LINE_THICKNESS)
          .attr('stroke-linejoin', 'round')
          .attr('stroke-linecap', 'round')
          .attr('d', line)

        data.forEach(
          response =>
            svg
              .append('circle')
              .datum(response)
              .attr('class', 'dot')
              .attr('fill', d3.schemeCategory10[colorIndex])
              .attr('stroke', d3.schemeCategory10[colorIndex])
              .attr('stroke-width', RING_THICKNESS)
              .attr('cx', line.x())
              .attr('cy', line.y())
              .attr('r', d => (d.rating + 1) * RADIUS) // Ratings start at zero, so bump for a minimum circle.
        )
      })
  }, [script, allResponses, graphContainerRef, reviewerKeys, selectedReviewers])

  return <div ref={graphContainerRef}></div>
}

TimeToPage.propTypes = {
  script: PropTypes.object.isRequired,
  allResponses: PropTypes.object.isRequired,
  reviewerKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedReviewers: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default TimeToPage

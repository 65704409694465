import React from 'react'
import * as d3 from 'd3'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { AVERAGE_KEY, REVIEWER_STYLES, reviewerClassName } from './visualizationHelpers'

const LEGEND_GUTTER = 0.25

const useStyles = makeStyles(
  theme => ({
    legend: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: theme.spacing(0, -LEGEND_GUTTER)
    },

    buttonBar: {
      marginBottom: theme.spacing(1)
    },

    showAll: {
      color: theme.palette.success.dark,
      marginLeft: theme.spacing(0.5)
    },

    showNone: {
      color: theme.palette.error.main,
      marginLeft: theme.spacing(0.5)
    },

    graphReader: {
      flexBasis: theme.spacing(20),
      margin: theme.spacing(0, LEGEND_GUTTER)
    },

    graphReaderCheckbox: {
      padding: theme.spacing(0, 0.5, 0, 0)
    },

    graphReaderLabel: {
      fontSize: theme.typography.pxToRem(14)
    },

    ...REVIEWER_STYLES
  }),
  {
    name: 'ReviewerFilter'
  }
)

const ReviewerFilter = props => {
  const { allResponses, reviewerVisible, setReviewerVisible } = props
  const reviewerKeys = [AVERAGE_KEY, ...Object.keys(allResponses).sort()]
  const selectedKeys = reviewerKeys.filter(reviewer => Boolean(reviewerVisible[reviewer]))

  const handleReaderVisibleChange = event =>
    setReviewerVisible({
      ...reviewerVisible,
      [event.target.name]: event.target.checked
    })

  const showsAverageOnly = selectedKeys.length === 1 && selectedKeys[0] === AVERAGE_KEY
  const showsAllReviewers = selectedKeys.length === reviewerKeys.length
  const showsNoReviewers = selectedKeys.length === 0

  const handleShowAverageOnly = event => setReviewerVisible({ [AVERAGE_KEY]: true })
  const handleShowNone = event => setReviewerVisible({})

  const handleShowAll = event => {
    const showAll = {}
    reviewerKeys.forEach(reviewer => (showAll[reviewer] = true))
    setReviewerVisible(showAll)
  }

  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      <div className={classes.buttonBar}>
        <Button
          className={classes[reviewerClassName(reviewerKeys.indexOf(AVERAGE_KEY))]}
          size="small"
          variant="outlined"
          onClick={handleShowAverageOnly}
          disabled={showsAverageOnly}
        >
          Show Average Only
        </Button>

        <Button
          className={classes.showAll}
          size="small"
          variant="outlined"
          onClick={handleShowAll}
          disabled={showsAllReviewers}
        >
          Show All Reviewers
        </Button>

        <Button
          className={classes.showNone}
          size="small"
          variant="outlined"
          onClick={handleShowNone}
          disabled={showsNoReviewers}
        >
          Show No Reviewers
        </Button>
      </div>

      <div className={classes.legend}>
        {reviewerKeys.map((response, index) => (
          <FormControlLabel
            key={response}
            classes={{
              root: classes.graphReader,
              label: classes.graphReaderLabel
            }}
            control={
              <Checkbox
                classes={{
                  colorSecondary: clsx(
                    classes[reviewerClassName(index % d3.schemeCategory10.length)],
                    classes.graphReaderCheckbox
                  )
                }}
                checked={Boolean(reviewerVisible[response])}
                onChange={handleReaderVisibleChange}
                name={response}
              />
            }
            label={response}
          />
        ))}
      </div>
    </div>
  )
}

ReviewerFilter.propTypes = {
  allResponses: PropTypes.object.isRequired,
  reviewerVisible: PropTypes.object.isRequired,
  setReviewerVisible: PropTypes.func.isRequired
}

export default ReviewerFilter

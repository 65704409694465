/**
 * Question represents a single survey question. It encapsulates possible variations in question
 * type and controls the logic for handling these variations.
 */
import React from 'react'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { TYPE_BOOLEAN, TYPE_FREEFORM, TYPE_MULTISELECT, TYPE_SELECT, TYPE_RATING } from './constants'

import BooleanInput from './BooleanInput'
import FreeformInput from './FreeformInput'
import MultiselectInput from './MultiselectInput'
import RadioSelectInput from './RadioSelectInput'
import RatingInput from './RatingInput'

const useStyles = makeStyles(
  theme => ({
    root: {}
  }),
  {
    name: 'Question'
  }
)

const TYPE_TO_COMPONENT = {
  [TYPE_BOOLEAN]: BooleanInput,
  [TYPE_RATING]: RatingInput,
  [TYPE_FREEFORM]: FreeformInput,
  [TYPE_SELECT]: RadioSelectInput,
  [TYPE_MULTISELECT]: MultiselectInput
}

const COLUMN_MULTISELECTS = ['Descriptive Phrases']

const Question = props => {
  const { className, code, prompt, type, options, value, setValue } = props

  const Input = TYPE_TO_COMPONENT[type]
  const column = (type === TYPE_MULTISELECT && COLUMN_MULTISELECTS.includes(code)) || type === TYPE_SELECT || undefined

  const classes = useStyles(props)
  return (
    <span className={clsx(classes.root, className)}>
      <span className={classes.prompt}>{prompt}</span>

      {Input && <Input options={options} column={column} value={value} setValue={setValue} />}
    </span>
  )
}

Question.propTypes = {
  className: PropTypes.string,
  code: PropTypes.string, // The question’s internal identifier.
  prompt: PropTypes.string, // The question’s text.
  type: PropTypes.oneOf([TYPE_BOOLEAN, TYPE_RATING, TYPE_FREEFORM, TYPE_SELECT, TYPE_MULTISELECT]),
  options: PropTypes.object, // The question’s list of choices, if any.
  value: PropTypes.any, // The current answer to the question.
  setValue: PropTypes.func // Function to call if an answer changes.
}

export default Question

import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import { submitButton } from './theme'

import Submit from './Submit'

import { logout } from './api'

import avatar from './assets/avatar.png'

const BUTTON_GUTTER = 0.25

const useStyles = makeStyles(
  theme => ({
    root: {
      margin: theme.spacing(0, -BUTTON_GUTTER),
      overflowX: 'scroll',
      textAlign: 'right',
      whiteSpace: 'nowrap'
    },

    avatar: {
      height: theme.spacing(3),
      width: theme.spacing(3)
    },

    progress: {
      marginLeft: theme.spacing(1)
    },

    contribute: submitButton(theme),

    headerButton: {
      margin: theme.spacing(BUTTON_GUTTER),
      whiteSpace: 'nowrap'
    },

    submit: submitButton(theme)
  }),
  {
    name: 'UserHeader'
  }
)

const UserHeader = props => {
  const [loggingOut, setLoggingOut] = useState(false)
  const [submitOpen, setSubmitOpen] = useState(false)

  const handleLogout = () => {
    setLoggingOut(true)

    const performLogout = async () => {
      await logout()
      props.history.push('/')
    }

    performLogout()
  }

  const handleUpload = event => {
    event.stopPropagation()
    setSubmitOpen(true)
  }

  const handleUploadClose = event => setSubmitOpen(false)

  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Avatar className={classes.avatar} alt="zoovatar" src={avatar} />}
        component={NavLink}
        to="/me"
        className={classes.headerButton}
      >
        My Zoodiker
      </Button>

      <Button className={clsx(classes.submit, classes.headerButton)} variant="contained" onClick={handleUpload}>
        Submit Your Writing
      </Button>

      {submitOpen && <Submit open={submitOpen} onClose={handleUploadClose} onSuccess={handleUploadClose} />}

      <Button
        variant="contained"
        color="primary"
        onClick={handleLogout}
        disabled={loggingOut}
        className={classes.headerButton}
      >
        Logout
        {loggingOut && <CircularProgress className={classes.progress} size={16} />}
      </Button>
    </div>
  )
}

export default UserHeader
